<template>
<div>
  <div class="desatque my-4" v-for="(card, i) in data" :key="i">
        <div class="row px-0 mx-0">
          <div class="col px-0 mx-0">
            <div class="card mb-3 border-0">
              <div  :class="(i % 2) === 0 ?'row px-0 mx-0 d-flex flex-md-row align-items-center' : 'row px-0 mx-0 d-flex flex-md-row-reverse align-items-center' "
              >
                <div class="col-md-7 mx-0 px-0 d-flex ">
                  <img :src="card.imagem" class="img-fluid" />
                </div>
                <div
                  class="col-md-5 d-flex flex-column justify-content-center py-1"
                >
                  <div class="text-left card-body">
                    <div class="d-flex justify-content-between flex-column">
                      <div>
                        <h1 class="">{{ card.titulo }}</h1>
                        <div class="bar mb-4"></div>
                        <h4 class="mb-4"> {{ card.subtitulo }}</h4>
                        <p class="mb-3"> {{ card.texto }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
</template>

<script>
export default {
  name: 'AlternatingHcard',
  props: {
    data: {
      type: Array,
      required: true
      // default: () => {
      //  return { image: "", titulo: "", subtitulo: "", text: "" };
      // },
    },
    inverted: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    buttonless: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>

<style scoped>

</style>
