<template>
  <div id="collapseOnesf1" class="collapse" aria-labelledby="headingOnesf1" data-parent="#collapsemObsf1">
    <div class="mob-card-body mob-menu-card-body black-bg">
      <div class="mob-card rounded-0 border-0 card-menu-servicos">
     <div class="text-left col-auto mr-5 py-4">
                <FooterEmpresas />
              </div>

      </div>
    </div>
  </div>

  </template>

<script type="text/javascript">
import FooterEmpresas from '@/components/Footer/FooterEmpresas.vue'

export default {
  name: 'MobileFooterEmpresas',
  components: {
    FooterEmpresas
  }
}
</script>

  <style scoped>

  .cool-link {
    display: inline-block;
    color: #ffc22b;
    text-decoration: none;
  }

  .cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #ffc22b;
    transition: width 0.3s;
  }

  li.show > .cool-link::after {
    width: 100%;
  }

  .cool-link:hover::after {
    width: 100%;
    /*transition: width .3s;*/
  }

  .navbar-sotreq {
    height: 80px;
    z-index: 99;
  }

  .header-logo {
    width: 166px;
  }

  .dropdown-toggle.active-dropdown::after {
    transform: rotate(-90deg);
  }

  .dropdown-menu {
    margin: 0px;
  }

  .dropdown-menu.show {
    display: flex;
    /*top: unset !important;*/
    left: 0 !important;
    width: 100%;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-radius: 0px !important;
  }

  a.dropdown-item.dropdown-toggle {
    /*padding: .5rem 1rem;*/
  }

  .img-menu-box {
    padding-right: 2em;
    border-right: 1px solid #d6d6d6;
  }

  a.dropdown-item.dropdown-toggle[aria-expanded='true'] {
    /*padding: .5rem 1rem;*/
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  #navbarNavDropdown > ul > li.nav-item.dropdown.show > ul > div > li.show > a {
    /*padding: .5rem 1rem;*/
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  .dropdown-item {
    color: #ffffff;
    padding: 0.5rem 1rem;
  }

  .dropdown-item:hover {
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  .dropdown-item-in {
    color: #000000;
    padding: 0.5rem 1rem;
  }

  .dropdown-item-in:hover {
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  .dropdown,
  di .dropleft,
  .dropright,
  .dropup {
    position: revert !important;
  }

  .navbar-expand-md .navbar-collapse {
    justify-content: center;
  }

  .navbar.navbar-expand-md.navbar-dark.navbar-sotreq {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.5202970095593544) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .navbar.navbar-expand-md.navbar-dark.navbar-sotreq.over {
    background: #ffffff !important;
    color: #000 !important;
  }

  .navbar-sotreq.over > .container > #navbarNavDropdown > ul > li > a {
    color: #000000;
  }

  .navbar-sotreq.over
    > .container
    > #navbarNavDropdown
    > ul
    > li.nav-item.dropdown.show
    > ul
    > div
    > li.show
    > ul
    > div
    > div
    > div
    > div
    > div
    > ul
    > li
    > a {
    color: #000000;
  }

  .navbar-sotreq.over
    > .container
    > #navbarNavDropdown
    > ul
    > li.nav-item.dropdown.show
    > ul
    > div
    > li.show
    > ul
    > div
    > div
    > div
    > div
    > div.pl-4.mx-0
    > ul
    > li
    > a {
    color: #000000;
  }

  .navbar-sotreq.over > .container > #navbarNavDropdown > ul > li.nav-item.dropdown.show > ul > div > li > a {
    color: #000000;
  }

  .navbar-sotreq.front-link {
    color: #ffffff;
  }

  .internal-link {
    color: #000000;
  }

  .navbar.navbar-expand-md.navbar-dark.navbar-sotreq:hover {
    background: #ffffff !important;
    color: #000000;
  }

  .navbar-sotreq:hover > .container > #navbarNavDropdown > ul > li > a {
    color: #000000;
  }

  .navbar-sotreq:hover > .container > #navbarNavDropdown > ul > li > a.nav-link:hover {
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #ffc22b !important;
    background-color: unset;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #ffc22b;
    background-color: unset;
  }

  #navbarNavDropdown > ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  li.nav-item.dropdown.show > ul.dropdown-menu {
    border: 1px solid #d6d6d6;
    border-radius: 0px;
    padding-bottom: 1em;
    padding-top: 1em;
    /*margin: 1rem 0 0;*/
  }

  li.show > ul.dropdown-menu.show {
    border-top: 1px solid #d6d6d6;
    border-bottom: none;
    /*margin: 1rem 0 0;*/
  }

  a.internal-link > h6 {
    margin-bottom: 1.5em;
  }

  h6.internal-link {
    margin-bottom: 1.5em;
  }

  .upcaret {
    width: 3em;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
  }

  a.internal-link.text-muted > h6 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;
  }

  .dropdown-toggle::after {
    display: block;
    margin-left: unset;
    vertical-align: unset;
    content: '';
    border-top: unset;
    border-right: unset;
    border-bottom: unset;
    border-left: unset;
  }

  .lupa-busca {
    color: #ffc22b !important;
  }

  .termos-buscados {
    color: #ffc22b !important;
    text-transform: uppercase;
  }
  .w-sidebar {
    width: 200px;
    max-width: 200px;
    margin-top: 3em;
  }

  .vh-100 {
    min-height: 100vh;
  }

  .row.collapse {
    margin-left: -200px;
    left: 0;
    transition: margin-left 0.15s linear;
  }

  .row.collapse.show {
    margin-left: 0 !important;
  }

  .row.collapsing {
    margin-left: -200px;
    left: -0.05%;
    transition: all 0.15s linear;
  }

  .menu-spacer {
    position: absolute;
  }

  @media (max-width: 1000px) {
    .copy-text {
      font-size: 14px;
    }
    .blue-button {
      width: 100%;
      justify-content: center;
    }
    .download-button {
      max-width: unset;
    }
    .hero-logo {
      width: 65%;
    }
    .map-box {
      min-height: 10em;
      min-width: 22em;
    }
    .row.collapse,
    .row.collapsing {
      margin-left: 0 !important;
      left: 0 !important;
      overflow: visible;
    }

    .row > .sidebar.collapse {
      display: flex !important;
      margin-left: -100% !important;
      transition: all 0.3s linear;
      position: fixed;
      z-index: 1050;
      max-width: 0;
      min-width: 0;
      flex-basis: auto;
    }

    .row > .sidebar.collapse.show {
      margin-left: 0 !important;
      width: 100%;
      max-width: 100%;
      min-width: initial;
    }

    .row > .sidebar.collapsing {
      display: flex !important;
      margin-left: -10% !important;
      transition: all 0.3s linear !important;
      position: fixed;
      z-index: 1050;
      min-width: initial;
    }
    .hamburger {
      position: fixed;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
      background-color: #000;
    }
    .mob-brand {
      height: 56px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
    .main-menu {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .fa-bars {
      align-self: center;
      font-size: 2.333em !important;
      color: #fff !important;
    }
    .header-logo {
      width: 150px;
    }
    .mob-search {
      color: #fff;
      margin-right: 1em;
    }
    .w-sidebar {
      margin-top: 2em;
    }
    .lblue-bg {
      background-color: #00000;
    }

    .mob-card {
      background-color: #00000;
      color: #fff;
    }
    .mob-card-header {
      background-color: #00000;
    }
    .btn-outline-warning {
      color: #ffc107 !important;
    }
    .btn-outline-warning:hover {
      color: #fffblz per !important;
    }
    .mob-internal-link {
      color: #000;
    }
    .btn.btn-link.btn-block.text-left.collapsed {
      color: #000000;
      font-size: 24px;
    }
    .btn.not(:disabled):not(.disabled) {
      color: #4a4a4a;
      background-color: #000c;
      font-size: 24px;
    }
    .btn.btn-link.btn-block.text-left.collapsed:focus {
      box-shadow: none;
      text-decoration: none;
    }
    .btn.btn-link.btn-block.text-left.collapsed:hover {
      box-shadow: none;
      text-decoration: none;
    }
    btn.focus,
    .btn:focus {
      outline: 0;
      box-shadow: none;
      text-decoration: none;
    }
    .btn.btn-link.btn-block.text-left {
      color: #4a4a4a;
      font-size: 24px;
    }

    .btn.btn-link.btn-block.text-left:hover {
      color: #4a4a4a;
      text-decoration: none;
      font-size: 24px;
    }
    .mob-menu-card-body {
      text-align: left;
    }
    .mob-menu-list {
      list-style: none;
      padding-inline-start: 15px !important;
    }
    .row.vh-100.no-gutters.d-flex.h-100.position-absolute.collapse.show {
      top: 0;
    }
  }

  @media (max-width: 538px) {
    .internal-link h6 {
      color: #ffffff;
    }
    li.nav-item {
      margin-bottom: 1em;
    }
  }
  .mainNav-collapsible .mobileNavOpen .mainNav--itemTitle {
    opacity: 1;
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1;
    display: block;
    position: relative;
  }
  .mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    position: relative;
    display: inline-block;
    font-size: 0.45rem;
    margin-left: 5px;
  }
  .mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    position: relative;
    display: inline-block;
    font-size: 0.45rem;
    margin-left: 5px;
  }
  i.fa.fa-angle-right {
    margin-left: 1em;
  }
  #collapsemObs1 {
    padding-left: 0.7em;
  }

  .btn.btn-link.btn-block.text-left.cool-link:not(.collapsed) > i.fa.fa-angle-right {
    transform: rotate(90deg);
  }
  .lblue-bg {
    overflow: scroll;
  }


  button.btn.d-flex.w-100.text-white.justify-content-between > i.fa.fa-angle-right {
        transform: rotate(90deg);

    color: red !important;
  }

    button.btn.d-flex.w-100.text-white.justify-content-between.collapsed > i.fa.fa-angle-right:before {
    color: magenta !important;
    transition: all 0.3s linear;

  }



  </style>
