<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="row">
        <div class="row">
          <div class="col-12 mt-4 text-left">
            <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
              {{ this.destaqueTitulo }}
            </h1>
            <div class="bar"></div>
            <p style="white-space: pre-line" class="mt-3">
              {{ this.destaqueTexto }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="row">
          <div class="col mt-4 text-left">
            <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
              {{ this.textoBaseTitulo }}
            </h1>
            <div class="bar"></div>
            <div class="card-deck row row-cols-1 row-cols-md-2">
              <div class="mb-4" v-for="(destaque, i) in cards" :key="i">
                <Novidade :data="destaque" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #f2f2f2" class="row mt-5 mb-0 pb-0">
      <div class="col py-5 mb-0 pb-0">
        <h2>{{ heroBottomLink }}</h2>
        <h5>{{ bottomherobuttontext }}</h5>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Novidade from "@/components/Novidade.vue";
import HeroMercados from "@/components/Mercados/HeroMercados.vue";
import Footer from "@/components/Footer.vue";
import ApiContent from "@/services/api.js";

export default {
  name: "Home",
  mounted() {
    window.scrollTo(0, 0);

    this.loadContent();
  },
  components: {
    Header,
    HeroMercados,
    Novidade,
    Footer,
  },
  methods: {
    async redirect(param) {
      this.$router.push(param);
    },
    async loadContent() {
      const resp = await ApiContent.get("paginas/108.json");
      console.log(resp.data);
      this.heroTopTitulo = resp.data.heroTopTitulo;
      this.heroTopTexto = resp.data.heroTopTexto;
      this.heroTopLink = resp.data.heroTopLink;
      this.destaqueTitulo = resp.data.destaqueTitulo;
      this.destaqueTexto = resp.data.destaqueTexto;
      this.destaqueChamada = resp.data.destaqueChamada;
      this.textoBaseTitulo = resp.data.textoBaseTitulo;
      this.textoBaseTexto = resp.data.textoBaseTexto;
      this.heroBottomTitulo = resp.data.heroBottomTitulo;
      this.heroBottomTexto = resp.data.heroBottomTexto;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.bottomherobuttontext = resp.data.bottomherobuttontext;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.destaques = resp.data.destaques;
      this.url = resp.data.url;
      this.topHeroImage = resp.data.topHeroImage;
      this.logo = resp.data.logo;
      this.bottomHeroImage = resp.data.bottomHeroImage;
      this.cards = resp.data.cards;
          this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
};
      this.cards = this.cards.map((s) => {
        s.image = s.imagem;
        return s;
      });
    },
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
    };
  },
};
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
