<template>
  <div class="home">
    <Header />
    <HeroHome :data="banners" />
    <div class="container">
      <div class="row justify-content-center">
        <div class="font-weight-bold text-uppercase mb-3 col-auto">
          <h4 class="font-weight-bold">Ofertas</h4>
        </div>
      </div>
    </div>
    <div class="containter-fluid">
      <Ofertas :data="ofertas" />
    </div>
    <div class="container">
      <Destaque :data="destaques[0]" />
      <Destaque :data="destaques[1]" :inverted="true" />
       <Destaque :data="destaques[2]" />
    </div>
    <div class="d-block d-sm-block d-md-block d-lg-none">
      <div style="" class="py-5 equipamento_nvo">
        <div class="class container">
          <div class="row justify-content-center text-center">
            <div class="col-8">
              <div class="row mb-2">
                <div class="col">
                  <h2 class="text-uppercase font-weight-bold text-white">
                    PRECISA DE UM EQUIPAMENTO <br />
                    E NÃO QUER INVESTIR em NOVO?
                  </h2>
                </div>
              </div>
              <div class="mt-5 row">
                <div class="col">
                  <a href="https://sotreqseminovos.com.br">
                    <button type="button" class="btndiferente px-5 mx-2 btn">
                      Seminovos Sotreq
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-none d-lg-block d-xl-block">
      <div
        class="hero-img d-flex flex-column justify-content-center align-items-center active"
      >
        <div class="row mb-2">
          <div class="col">
            <h2 class="text-uppercase font-weight-bold text-white">
              PRECISA DE UM EQUIPAMENTO <br />
              E NÃO QUER INVESTIR em NOVO?
            </h2>
          </div>
        </div>
        <div class="mt-5 row">
          <div class="col">
            <a href="https://sotreqseminovos.com.br">
              <button type="button" class="btndiferente px-5 mx-2 btn">
                Seminovos Sotreq
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 container">
      <div class="row mb-4 justify-content-center">
        <div class="col-auto">
          <h4 class="text-uppercase font-weight-bold">
            Fique por dentro das novidades
          </h4>
        </div>
      </div>
      <div class="card-deck row row-cols-1 row-cols-md-2">
        <div class="mb-4" v-for="(destaque, i) in novidades" :key="i">
          <Novidade :data="destaque" />
        </div>
      </div>
    </div>
    <div style="background-color: black" class="row px-0 mx-0 my-5">
      <div class="col px-0 mx-0">
        <EloNews :data="elos" />
      </div>
    </div>
    <Mercados />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroHome from '@/components/HeroHome.vue'
import Destaque from '@/components/Destaque.vue'
import Ofertas from '@/components/Ofertas.vue'
import Novidade from '@/components/Novidade.vue'
import EloNews from '@/components/EloNews.vue'
import Mercados from '@/components/Mercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
export default {
  name: 'Home',
  mounted () {
    this.loadContent();
  },
  components: {
    Header,
    HeroHome,
    Destaque,
    Ofertas,
    Novidade,
    EloNews,
    Mercados,
    Footer
  },
  methods: {
    async loadContent () {
      // const headers = {
      //   'Content-Type': 'application/json'
      // }
      const resp = await ApiContent.get('homes/1.json')

      this.destaques = resp.data.destaques.slice(0, 1)
        ? [
          {
            titulo: resp.data.destaques[0].titulo1,
            subtitulo: resp.data.destaques[0].subtitulo1,
            texto: resp.data.destaques[0].texto1,
            link: resp.data.destaques[0].link1,
            image: resp.data.destaques[0].image1
          },
          {
            titulo: resp.data.destaques[0].titulo2,
            subtitulo: resp.data.destaques[0].subtitulo2,
            texto: resp.data.destaques[0].texto2,
            link: resp.data.destaques[0].link2,
            image: resp.data.destaques[0].image2
          },
          {
            titulo: resp.data.destaques[1].titulo1,
            subtitulo: resp.data.destaques[1].subtitulo1,
            texto: resp.data.destaques[1].texto1,
            link: resp.data.destaques[1].link1,
            image: resp.data.destaques[1].image1
          }
        ]
        : []
      this.banners = resp.data.banners ? resp.data.banners : []
      this.elos = resp.data.elos ? resp.data.elos : []
      this.ofertas = resp.data.ofertas ? resp.data.ofertas : []
      this.novidades = resp.data.novidades[0]
        ? [
          {
            titulo: resp.data.novidades[0].titulo1,
            subtitulo: resp.data.novidades[0].subtitulo1,
            texto: resp.data.novidades[0].texto1,
            link: resp.data.novidades[0].link1,
            image: resp.data.novidades[0].image1
          },
          {
            titulo: resp.data.novidades[0].titulo2,
            subtitulo: resp.data.novidades[0].subtitulo2,
            texto: resp.data.novidades[0].texto2,
            link: resp.data.novidades[0].link2,
            image: resp.data.novidades[0].image2
          }
        ]
        : []
    }
  },
  data () {
    return {
      destaques: [],
      banners: [],
      elos: [],
      ofertas: [],
      novidades: []
    }
  }
}
</script>
<style scoped>
.equipamento_nvo {
  background-color: #ffcd11;
  background-image: url("https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaElGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d01530daca72af2b17e808562411ec275881927c/bg_mobile_seminovos_site_sotreq.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 /*
 background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
  */
}
.hero-img {  
  height: 555px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/faixaseminovas3.png");
}
.btndiferente {
  border: 1px solid white;
  background-color: black;
  color: white;
}

.btndiferente:hover {
  border: 1px solid black;
  background-color: white;
  color: black;
}

button {
  border: 2px solid white;
  background-color: white;
  color: white;
}
.modal-content {
  background-color: transparent !important;
}
.popbut {
  background-color: transparent !important;
  color: white !important;
}
.popbut:hover {
  color: #fafafa !important;
}

.d-none {
    display: none!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: -ms-flexbox!important;
    display: flex!important
}

.d-inline-flex {
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
}

@media (min-width: 576px) {
    .d-sm-none {
        display:none!important
    }

    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display:none!important
    }

    .d-md-inline {
        display: inline!important
    }

    .d-md-inline-block {
        display: inline-block!important
    }

    .d-md-block {
        display: block!important
    }

    .d-md-table {
        display: table!important
    }

    .d-md-table-row {
        display: table-row!important
    }

    .d-md-table-cell {
        display: table-cell!important
    }

    .d-md-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display:none!important
    }

    .d-lg-inline {
        display: inline!important
    }

    .d-lg-inline-block {
        display: inline-block!important
    }

    .d-lg-block {
        display: block!important
    }

    .d-lg-table {
        display: table!important
    }

    .d-lg-table-row {
        display: table-row!important
    }

    .d-lg-table-cell {
        display: table-cell!important
    }

    .d-lg-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display:none!important
    }

    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}
@media(max-width: 570px) {
  .d-sm-block {
    display: block !important;
  }
}
</style>
