import { render, staticRenderFns } from "./ColeteAmostra.vue?vue&type=template&id=307a109e&scoped=true"
import script from "./ColeteAmostra.vue?vue&type=script&lang=js"
export * from "./ColeteAmostra.vue?vue&type=script&lang=js"
import style0 from "./ColeteAmostra.vue?vue&type=style&index=0&id=307a109e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "307a109e",
  null
  
)

export default component.exports