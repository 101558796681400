<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div v-if="cards.length > 0" class="d-flex flex-wrap row">
        <div v-for="(item, i) in cards" :key="i" class="mt-4 col-md-3">
          <a :href="item.link">
            <div class="square h-100 border">
              <div class="d-flex p-3 align-items-center flex-column">
                <img
                  class="img-fluid mx-5 mt-1"
                  :src="item.imagem"
                  :alt="item.titulo"
                />
                <div class="mt-4 bar"></div>
                <p class="font-weight-bold">{{ item.titulo }}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
// import CardsMercado from '@/components/Mercados/CardsMercado.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Home',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,

    Footer
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/11.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.created_at = resp.data.created_at
      this.updated_at = resp.data.updated_at
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
};
    },
  },
  data () {
    return {
      bannermercado: [],
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.square {
  cursor: pointer;
}
a{
  text-decoration: none;
  color:black
}
img {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 80%;
  max-width: 80%;
}
.square:hover img {
  overflow: hidden;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
.bar {
  margin-top: 5px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.square:hover .bar {
  background-color: black;
}
button {
  border: 2px solid white;
  background-color: transparent;
  color: white;
}
</style>
