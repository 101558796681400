import { render, staticRenderFns } from "./SotreqLinkTec.vue?vue&type=template&id=3aadf753&scoped=true"
import script from "./SotreqLinkTec.vue?vue&type=script&lang=js"
export * from "./SotreqLinkTec.vue?vue&type=script&lang=js"
import style0 from "./SotreqLinkTec.vue?vue&type=style&index=0&id=3aadf753&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aadf753",
  null
  
)

export default component.exports