<template>
<div class="mt-3 mt-md-4 col-md-3 minsqcard">
          <a :href="link">
            <div class="square h-100 border">
              <div class="d-flex p-0 align-items-center flex-column">
                <img
                  class="img-fluid mx-5 mt-1"
                  :src="image"
                  :alt="titulo"
                />
                <div class="mt-4 bar"></div>
                <p class="fw-700 notalink">{{ titulo }}</p>
              </div>
            </div>
          </a>
        </div>
</template>

<script>
export default {
  props: {
    link: String,
    image: String,
    titulo: String
  }
}
</script>

<style scoped>
.notalink {
  color: #000 !important;
}
@media screen and (max-width: 768px) {
  .minsqcard {
  width: 270px;
    height: 241px;
    margin: auto;
  }
  
}
</style>
