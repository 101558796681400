<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
       
    <div class="container">

      <div class="row">
        <div class="col mt-0 mt-lg-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 main-heading">
            {{ this.destaqueTitulo }}
          </h1>
               <p style="white-space: pre-line" class="mt-3  mb-0 txtlnorm">
            {{ this.destaqueTexto }}
          </p>
        </div>
      </div>
          <h4 class="ml-0 pl-0 mt-2 text-left font-weight-bold">
{{ this.destaqueChamada }} </h4>
    <div v-if="this.conteudo.length > 1">
        <div class="resetforcontent">
        <div  v-html="this.conteudo"></div>
        </div>
        </div>
            <h4 class="ml-0 pl-0 mt-1 mt-md-2 text-left fw-700">
{{ this.textoBaseTitulo }} </h4>
    <div class="mt-3">

                          <div class="row">
                            <div class="col-6 col-md-2">
                              <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVVHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1c4ad01499dc56944a43ed4918c07bc324b9cabf/rectangle-17.png" class="img-fluid" />	
                            </div>
                                <div class="col-6 col-md-2">
                              <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcVlHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b1117e44fdd1c8f960342703a6a9256c57eb2c1b/rectangle-18.png" class="img-fluid" />	
                            </div>
                             <div class="col-6 col-md-2">
                              <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcmNHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--14ad3d115ee4dc325422ddab9091c00207c4662e/rectangle-19.png" class="img-fluid" />	
                            </div>
                                <div class="col-6 col-md-2">
                              <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcmdHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7ee33a6cf954bdf467f07cfc08dd178446ae3904/rectangle-20.png" class="img-fluid" />	
                            </div>
                               <div class="col-6 col-md-2">
                              <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcmtHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ca70946ef21de9ca5753a3941814749c371be7e2/rectangle-21.png" class="img-fluid" />	
                            </div>
                                <div class="col-6 col-md-2">
                            &nbsp;
                            </div>
                          </div>
    </div>
    <div class="mt-3">
      
        <h2 class="text-left fw-700">Produtos</h2>
                          <div class="bar mb-3"></div>
                          <h4 class="text-left fw-700 mb-4">
                            Linha de equipamentos para veios estreitos (linha exclusiva)
                          </h4>
    </div>
    <DestaqueMineracao
      v-for="(item, index) in estreitos"
      :key="index"
      :image="item.image"
      :text="item.text"
      :hasBar="false"
      :inverted="false"
    />

     

<div class="separator">&nbsp;</div>
            <h4 class="text-left fw-700 mb-4">
Jumbos de sustentação e atirantamento                          </h4>

    <DestaqueMineracao
      v-for="(item, index) in jumbos"
      :key="index"
      :image="item.image"
      :titulo="item.titulo"
      :subtitulo="item.subtitulo"
      :text="item.text"
      :hasBar="false"
        :inverted="index % 2 === 0"
      />

        

<div class="separator">&nbsp;</div>
            <h4 class="text-left fw-700 mb-4">
Jumbos de perfuração frontal                        </h4>


    <DestaqueMineracao
      v-for="(item, index) in frontais"
      :key="index"
      :image="item.image"
      :titulo="item.titulo"
      :subtitulo="item.subtitulo"
      :text="item.text"
      :hasBar="false"
        :inverted="index % 2 === 0"
      />
     

<div class="separator">&nbsp;</div>

            <h4 class="text-left fw-700 mb-4">
Jumbos de produção tipo fandrill                        </h4>


    <DestaqueMineracao
      v-for="(item, index) in fandrills"
      :key="index"
      :image="item.image"
      :titulo="item.titulo"
      :subtitulo="item.subtitulo"
      :text="item.text"
      :hasBar="false"
        :inverted="index % 2 === 0"
      />

    

<div class="separator">&nbsp;</div>


            <h4 class="text-left fw-700 mb-4">
Equipamento de saneamento                      </h4>


    <DestaqueMineracao
      v-for="(item, index) in saneamentos"
      :key="index"
      :image="item.image"
      :titulo="item.titulo"
      :subtitulo="item.subtitulo"
      :text="item.text"
      :hasBar="false"
        :inverted="index % 2 === 0"
      />

<div class="separator">&nbsp;</div>


            <h4 class="text-left fw-700 mb-4">
Utilitários                    </h4>


    <DestaqueMineracao
      v-for="(item, index) in utilitarios"
      :key="index"
      :image="item.image"
      :titulo="item.titulo"
      :subtitulo="item.subtitulo"
      :text="item.text"
      :hasBar="false"
        :inverted="index % 2 === 0"
      />

    </div>

    <Footer  :buttonless="false" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import CardSobrenos from '@/components/CardSobrenos.vue'
import Destaque from '@/components/Destaque.vue'
import HeroMercados from '@/components/HeroMineracaoPaginas.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Novidade from "@/components/Novidade.vue"
import DestaqueMineracao from '../../components/DestaqueMineracao.vue'


export default {
  name: 'TecnologiaSolucoesSotreqlink',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    CardSobrenos,
    Destaque,
    HeroMercados,
    Footer,
    Novidade,
    DestaqueMineracao
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/200.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaques = resp.data.destaques
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.conteudo = resp.data.content
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
            this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem;
        return s;
      })
       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
       }
         this.estreitos = [
        {
         image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTBHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--32ecf565010fe55c9a2bf4cb7489190d55bd8b47/image-4.png',
        text: 'A Resemin é a grande idealizadora da linha de equipamentos ultracompactos denominada de MUKI, que inclui o menor jumbo do mundo.\n\nCom quase 60% do mercado global, a Resemin é líder absoluta na linha de equipamentos para veio estreitos, que estão disponíveis na versão mini Jumbo frontal (MUKI FF) e mini Jumbo fandrill (MUKI LHBP).\n\nO MUKI FF, por exemplo, é um equipamento eletro-hidráulico para perfuração frontal possuindo apenas 1,05 m de largura, recomendado para pequenas seções de 1,5 m x 2,0 m, até 3,5 m x 3,5 m. Além disso, graças ao seu tamanho, também pode ser utilizado para a atividade de atirantamento, perfurando e instalando tirantes com um único equipamento.\n\nPossui braço hidráulico de seção quadrada e viga dianteira com opções para trabalhar com hastes de perfuração de 8 a 10 pés, e opção de viga retrátil de 6-10 e 8-10 pés. É equipado com Perfuratriz Montabert HC 50E de 13 kW e sistema antitravamento.'
    },
         ]
      this.jumbos = [
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcXdHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b13a4a406f25b3ade9c14952087369130de6662a/smallbolter-9901.png',
          titulo: 'Small bolter 99',
          subtitulo: 'Versatilidade para todos os tamanhos de minas.',
          text: ' O Small Bolter 99 permite o atirantamento eficiente e seguro em minas subterrâneas de pequeno porte, ideal para seções de 3,5 m a 5,3 m de altura.\n\n Equipado com uma torre T99 com duas perfuratrizes Montabert, uma HC 50 para perfuração e uma HC 28 para perfuração e instalação de tirantes e cavilhas, consegue instalar parafusos helicoidais, Split Set, Mechanical, Swellex, Hydrabolt, Rockbolts e Python. Possui também canhão para lançamento de cartuchos de resina ou cimento. A maior versatilidade do mercado.',
        },
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTRHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--09aab3b40415c9d4862b1dae8dfe2b8dc0bb81a3/smallbolter-9901.png',
          titulo: 'Bolter 99',
          text: 'O Bolter 99 é um jumbo eletro-hidráulico desenvolvido para perfuração de rochas e instalação de tirantes e cavilhas em minas de médias seções. O equipamento ideal para fortificação mecanizada em seções médias de 3,5 m até 8,2 m de altura.\n\nEquipado com uma torre T99 com duas perfuratrizes Montabert, uma HC 50 para perfuração e uma HC 28 para perfuração e instalação de tirantes e cavilhas, consegue instalar parafusos helicoidais, Split Set, Mechanical, Swellex, Hydrabolt, Rockbolts e Python. Possui também canhão para lançamento de cartuchos de resina ou cimento.\n\nPossui a opção de braço manipulador de telas, que permite a manipulação e instalação de telas eletrossoldadas e equivalentes de modo mecanizado.\n\nEquipamento com cabine fechada e ar-condicionado, chassis para aplicações pesadas, 4WD articulado e autopropelido a diesel. Toda a operação é combinada de forma segura para o operador.'
        }
      ]
        this.frontais = [
        {
            image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcThHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cc2e56d054287dc06f4bff110721e131669756f1/smallbolter-9901.png',
            titulo: 'Troidon 44 XP',
            subtitulo: 'Compacto e produtivo.',
            text: 'Com um design simples mas eficaz, o TROIDON 44 é um jumbo de perfuração frontal com 1,3 m de largura, recomendado para pequenas seções de um mínimo de 2,5 m x 2,5 m, até 4,5 x 4,5 m.\n\nVem equipado com uma perfuratriz Montabert HC 95LM de 21 kW, a mais rápida e de menor custo de manutenção do mercado, possuindo também um sistema antitravamento de hastes.\n\nSeu braço com paralelismo automático e seu design simples, sem complicações técnicas, fazem deste equipamento uma máquina confiável, com o melhor custo-benefício do mercado.'
        },
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBckFHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--32dba5a65e089847419711dac6589c97a6d48d2e/smallbolter-9901.png',
          titulo: 'Troidon 55 XP',
          subtitulo: 'Rapidez e baixo custo de manutenção.',
          text: 'Esse Jumbo eletro-hidráulico de 1,85 m de largura está preparado para trabalhos de desenvolvimento de túneis e galerias em mineração subterrânea e obras civis de seções médias de 3,5 x 3,5 m a 5,3 x 5,3 m.\n\nVem equipado com uma perfuratriz Montabert HC 95LQ de última geração de 24 kW, a mais rápida do mercado, possuindo também um sistema antitravamento de hastes.\n\nChassi robusto para serviço pesado, 4WD articulado e autopropelido, com motor CAT C4.4 Acert de 106 kW.'
        },
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBckVHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b6420c8e5302784aac0af7b2c8fa6cdba07a4387/smallbolter-9901.png',
          titulo: 'Troidon 66 XP',
          subtitulo: 'Pronto para trabalhos pesados.',
          text: 'O TROIDON 66 é um jumbo eletro-hidráulico de dois braços com paralelismo automático que possui uma cobertura de perfuração de 46 m2.\nEle vem equipado com equipamentos de perfuração Montabert modelo HC 95LM 21 kW, com sistema antitravamento de hastes.\n\nSeu design permite que seus principais componentes estejam localizados no interior do equipamento: o enrolador de cabo, o enrolador de mangueira de água, a bomba\-d\'água e o compressor.\n\nChassi robusto para serviço pesado, 4WD articulado e autopropelido com motor CAT C4.4 Acert de 106 kW.'
        }
        ]
      this.fandrills = [
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcklHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--82e9014631ec419d2032c13bb971f4c98b6e8c45/smallbolter-9901.png',
          titulo: 'Raptor 44-2R',
          subtitulo: 'Compacto e produtivo.',
          text: 'O RAPTOR 44 2R é um jumbo eletro-hidráulico do tipo Fandrill para furos longos, que possui braço hidráulico modelo Boom 4AP com paralelismo automático. Além disso, possui sistema de centro de gravidade para sincronizar automaticamente os eixos de furação e rotação, facilitando assim a furação de eixos radiais com menores tempos de posicionamento.\n\nEquipado com a mais moderna perfuratriz do mercado, modelo Montabert HC 95LQ de 24 kW, com sistema antitravamento de hastes.\n\nSeu design permite que seus principais componentes estejam localizados no interior do equipamento: o enrolador de cabo, o enrolador de mangueira de água, a bomba\-d\'água e o compressor.\n\nPossui 2 unidades de rotação para maior funcionalidade nos movimentos.'
        },
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBck1HIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ad207553dc3d7207cc022ebfa5f46af47f9db621/smallbolter-9901.png',
          titulo: 'Raptor 55-2R',
          subtitulo: 'Versatilidade e produtividade',
          text: 'O RAPTOR 55 2R da Resemin é um jumbo eletro-hidráulico do tipo Fandrill para furos longos. Possui um braço hidráulico modelo Boom 5APC com paralelismo automático e usa uma perfuratriz Montabert modelo HC 95LQ de 24 kW, com sistema antitravamento de hastes.\n\nPossui grande estabilidade com capacidade máxima de perfuração de até 35 m, e diâmetros de até 102 mm (alargando até 127 mm).\n\nChassi robusto para serviço pesado, 4WD articulado e autopropelido com motor CAT C4.4 Acert de 106 kW.\n\nSeu design permite que seus principais componentes estejam localizados no interior do equipamento: o enrolador de cabo, o enrolador de mangueira de água, a bomba\-d\'água e o compressor.\n\nPossui 2 unidades de rotação para maior funcionalidade nos movimentos.'
        },
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBclFHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e5a8ebdefd91f6df65e5a86f1237864c89557d42/smallbolter-9901.png',
          titulo: 'Raptor 7X',
          subtitulo: 'Pronto para trabalhos pesados.',
          text: 'O RAPTOR 7X é um jumbo eletro-hidráulico do tipo Fandrill para furos longos para aplicações em seções médias a grandes de 4,5 x 4,5 a 6 x 6 m.\n\nUm equipamento robusto e que possui a quantidade certa de automação para uma operação confiável e segura.\n\nNovo modelo de lança reforçada com paralelismo automático, equipado com 4 Stingers para uma melhor estabilidade.\n\nPossui magazine de 27 hastes disponíveis na configuração T51 e ST58, com opção de automatização de furos.\n\nEquipada com a perfuratriz Montabert HC 112, de 30kW e sistema antitravamento de hastes. Com capacidade de furar até 48 m de diâmetros até 102mm (Reabrindo até 152mm).\n\nChassi robusto para serviço pesado, 4WD articulado, autopropelido com motor CAT C4.4 Acert de 106Kw.\n\nSeu design permite que seus principais componentes estejam localizados no interior do equipamento: o Enrolador de Cabo, o Enrolador de Mangueira de Água, a bomba d\'água e o compressor.\n\nPossui 2 unidades de rotação para maior funcionalidade nos movimentos.'
        }
      ]
    this.saneamentos =  [
        {
        image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBclVHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--44342450e86d1e3977ecf5efe9e09ca8aa792525/smallbolter-9901.png',
        titulo: 'Scalemin HT',
        text: 'Scalemin HT é um scaler a diesel para mineração subterrânea, com uma altura máxima de 7,9 m. Possui braço telescópico, equipado com rompedor hidráulico SB202T.\n\nRobusto, ágil e de fácil manutenção.\n\nCabine fechada com ar-condicionado e sistema de inclinação, promovendo uma maior visibilidade.\n\nInclui câmeras de vídeo para uma visão de 360° e reduzir os pontos cegos.\n\nProjetado para atender aos padrões ergonômicos para reduzir a fadiga do operador e melhorar a produtividade. Certificado FOPS/ROPS, com amortecedor de quadro para aliviar vibrações e proporcionar uma operação confortável.\n\nChassi para serviço pesado 4x4, com articulação central e eixo traseiro oscilante.'
        }
    ]
      this.utilitarios = [
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcllHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2062e527ea6be1324deae6494f98684dd932b5ec/smallbolter-9901.png',
          titulo: 'Plataforma SL11 Scissor Lift',
          subtitulo: 'Versatilidade e segurança',
          text: 'Nossa plataforma elevatória tipo tesoura SL11 é um veículo utilitário de serviço pesado para mineração subterrânea. Possui uma capacidade de carga de 2.000 kg, elevando sua plataforma a até 3,5 m do nível do solo, de forma ágil e segura.\n\nO SL11 é perfeito tanto para transporte de materiais ou para atividades de apoio, instalação de tubulações ou instalação de ventiladores.'
        }
        ]
    
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaques: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      conteudo: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
.separator {
  margin-top:24px;
  margin-bottom:30px;
}
@media screen and (max-width: 768px) {
  h2.ml-0.pl-0.mt-1.mt-md-2.text-left.fw-700.main-heading {
    font-size: 24px;
}
}
</style>
