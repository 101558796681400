<template>
  <div class="home">
    <Header />

    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.heroTopTitulo }}
          </h1>
          <div class="bar w-100"></div>
        </div>
      </div>
      <div class="text-left">
        <p
          data-toggle="modal"
          data-target="#staticBackdrop"
          class="modalignition p-0 m-0 text-muted"
        >
          {{ bottomherobuttontext }}
        </p>
      </div>
      <div class="row">
        <div class="col">
          <div
            style="background-color: #eeeeee"
            class="borders p-3 mt-4 d-flex flex-column text-left"
            v-for="(card, index) in cards"
            :key="index"
          >
            <h4>{{ card.titulo }}</h4>
            <h3>{{ card.subtitulo }}</h3>
            <h5 style="white-space: pre-line">{{ card.texto }}</h5>
          </div>
        </div>
      </div>
      <div class="my-5 row d-flex justify-content-between">
        <div @click="redirect('oleo-diesel')" class="col-4">
          <button class="w-100 py-4 px-4">
            <p class="p-0 m-0">Análise de óleo diesel</p>
          </button>
        </div>
        <div @click="redirect('liquido-lubrificante')" class="col-4">
          <button class="w-100 py-4 px-4">
            <p class="p-0 m-0">Analise de óleo de lubrificante</p>
          </button>
        </div>
        <div @click="redirect('liquido-arrefecimento')" class="col-4">
          <button class="w-100 py-4 px-4">
            <p class="p-0 m-0">Análise de líquido de arrefecimento</p>
          </button>
        </div>
      </div>
    </div>
    <div style="background-color: #ffcb11" class="row mt-5 mb-0 pb-0">
      <div class="col py-5 mb-0 pb-0">
        <a
          class="text-dark"
          href="https://soswebmc.cat.com/cat-sos/dashboard?locale=pt_br"
        >
          <h2>FAÇA AQUI A GESTÃO DE SUAS AMOSTRAS</h2>
        </a>
      </div>
    </div>
    <div style="background-color: #f2f2f2" class="row mt-5 m-0 p-0">
      <div class="col py-5 mb-0 pb-0">
        <h2>Precisa de outros ensaios? Fale conosco!</h2>
        <h5>vendas.sos@sotreq.com.br | 0800 940 1920</h5>
      </div>
    </div>

    <Footer />
    <div
      class="modal fade"
      id="staticBackdrop"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Análise de diesel
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-left">
            <p style="white-space: pre-line">
              {{ heroBottomTexto }}
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Home',
  mounted () {
    window.scrollTo(0, 0)

    this.loadContent()
  },
  components: {
    Header,
    Footer
  },
  methods: {
    async redirect (param) {
      this.$router.push(param)
    },
    async loadContent () {
      const resp = await ApiContent.get('paginas/113.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.destaques = resp.data.destaques
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
      this.destaque = {
        titulo: this.destaqueTitulo,
        texto: this.destaqueTexto,
        image: this.topHeroImage
      }
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      destaque: {},
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.modalignition {
  text-decoration: underline;
  cursor: pointer;
}
.modalignition:active {
  text-decoration: unset;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
.borders {
  border: 2px solid #ffcb11;
}
button {
  border: 2px solid #ffcb11;
  background-color: transparent;
  color: black;
}

button:hover {
  border: 2px solid #ffcb11;
  background-color: #d0d0d0;
  color: black;
}
</style>
