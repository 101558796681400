import { render, staticRenderFns } from "./Termosbuscados.vue?vue&type=template&id=4a4a5f2e&scoped=true"
import script from "./Termosbuscados.vue?vue&type=script&lang=js"
export * from "./Termosbuscados.vue?vue&type=script&lang=js"
import style0 from "./Termosbuscados.vue?vue&type=style&index=0&id=4a4a5f2e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a4a5f2e",
  null
  
)

export default component.exports