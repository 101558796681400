<template>
    <div>
                  <p class="mt-2">Inscreva-se para receber novidades</p>

      <form >

                          <input
                            v-model="EMAIL"
                            placeholder="E-MAIL *"
                            type="text"
                            class="itextinput form-control"
                          />

                        <div v-if="sended">
                          <div class="d-flex justify-contend-end">
                            <div class="alert alert-success" role="alert">
                              Enviado!
                            </div>
                          </div>
                        </div>
                        <div v-if="!valido">
                          <div class="d-flex">
                            <div class="alert alert-danger" role="alert">
                              Exitem informações obrigatórias não preenchidas
                            </div>
                          </div>
                        </div>
                        <button
                          @click="sendcontact"
                          type="button"
                          :disabled="sended"
                          class="btn btn-outline-warning sotreqlink-btn mt-3"
                        >
                          Enviar
                        </button>

                      </form>
    </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import ApiContato from '@/api/apiContato.ts'

export default {
  name: 'Newsletter',

  methods: {

    async sendcontact () {
      let bool = true
      if (this.EMAIL === '') {
        console.log(this.EMAIL, 'EMAIL')
        bool = false
      }
      if (bool) {
        const obj = {
          nome: 'Inscrever Elo News',
          email: this.EMAIL,
          mensagem: 'Inscrever Elo News'
        }
        console.log(obj)
        this.sended = true
        this.valido = true
        const resp = await ApiContato.post('contatos', { ...obj })
        console.log(resp)
        this.$router.push({ name: 'NewsletterEnviado' })
      } else {
        this.valido = false
      }
    }

  },
  data () {
    return {
      destaques: [],
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      estados: [],
      cidades: [],
      estadochosen: [],
      cidadechosen: [],
      MENSAGEM: '',
      valido: true,
      whats: 0,
      tel: 0,
      verified: false,
      emm: 0,
      NOME: '',
      sended: false,
      EMAIL: '',
      TELEFONE: '',
      EMPRESA: ''
    }
  }
}
</script>
<style scoped>

</style>
