<template>
  <div class="mt-4 cardsmercado">

          <div class="row">
      <div v-if="!titleless" class="col mt-5 text-left">
      <h2  class="">Nossos mercados</h2>
      <div class="bar mb-4"></div>
      </div>
      </div>
      <div class="card-deck row row-cols-1 row-cols-md-3">
        <div
          style=""
          v-for="(card, i) in data"
          :key="i"
          class="mb-4"
        >

          <div class="h-100 card border">
            <img class="card-img-top img-fluid" :src="card.imagem" alt="Sotreq revendedora Caterpillar" />
            <div class="card-body text-left">
              <h5 class="card-title">{{ card.titulo }}<br>{{ card.subtitulo }}</h5>
              <p
                style="white-space: pre-line"
                :class="limit ? 'card-text limit' : 'card-text'"
              >
                {{ card.texto }}
              </p>
            </div>
            <div class="bg-white card-footer text-left">
              <a :href="card.link" >
                <button type="button" class="btn">
                  {{ card.buttontext ? card.buttontext : "Saiba mais" }}
                </button>
              </a>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardsfiltragem',
  props: {
    data: {
      type: Array,
      required: true
    },
    onlytitle: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    titleless: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>

<style scoped>
/* The hero image */
/* .maquina{
  cursor: pointer;
} */

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

img {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

}

button {
  background-color: black;
  color: white;
  text-transform: uppercase;
}
.card-footer {
  border-top: none;
}

</style>
