<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container pt-5">
      <div class="row">
        <div class="col text-left">
          <h2 class="font-weight-bold">
            {{ this.destaqueTitulo }}<br />{{ this.destaqueChamada }}
          </h2>
          <div class="bar mb-3"></div>
          <p style="white-space: pre-line" class="mt-3">
            {{ this.destaqueTexto }}
          </p>
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <div class="card-deck row row-cols-1 row-cols-md-3">
        <div class="mb-4" v-for="(destaque, i) in cards" :key="i">
          <Novidade :data="destaque" />
        </div>
      </div>
    </div>
    <div class="container mt-3">
      <div class="row justify-content-center">
        <div class="font-weight-bold text-uppercase mb-3 col-auto">
          <h4 class="font-weight-bold">Ofertas</h4>
        </div>
      </div>
    </div>
    <div class="containter-fluid">
      <Ofertas :data="ofertas" />
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HeroMercados from "@/components/Mercados/HeroMercados.vue";
import Ofertas from "@/components/Ofertas.vue";
import Footer from "@/components/Footer.vue";
import ApiContent from "@/services/api.js";
import Novidade from "@/components/Novidade.vue";

export default {
  name: "Promocoes",
  mounted() {
    this.loadContent();
    this.loadOfertas();
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    Ofertas,
    Novidade,
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get("paginas/163.json");
      console.log(resp.data);
      this.heroTopTitulo = resp.data.heroTopTitulo;
      this.heroTopTexto = resp.data.heroTopTexto;
      this.heroTopLink = resp.data.heroTopLink;
      this.destaqueTitulo = resp.data.destaqueTitulo;
      this.destaqueTexto = resp.data.destaqueTexto;
      this.destaqueChamada = resp.data.destaqueChamada;
      this.textoBaseTitulo = resp.data.textoBaseTitulo;
      this.textoBaseTexto = resp.data.textoBaseTexto;
      this.heroBottomTitulo = resp.data.heroBottomTitulo;
      this.heroBottomTexto = resp.data.heroBottomTexto;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.bottomherobuttontext = resp.data.bottomherobuttontext;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.url = resp.data.url;
      this.topHeroImage = resp.data.topHeroImage;
      this.logo = resp.data.logo;
      this.bottomHeroImage = resp.data.bottomHeroImage;
      this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem;
        return s;
      });
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent,
      };
    },
    async loadOfertas() {
      const respo = await ApiContent.get("homes/1.json");
      this.ofertas = respo.data.ofertas ? respo.data.ofertas : [];
    },
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      ofertas: [],
    };
  },
};
</script>

<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}

a {
  text-decoration: none;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
