import { render, staticRenderFns } from "./AreiaEBrita.vue?vue&type=template&id=1f83a678&scoped=true"
import script from "./AreiaEBrita.vue?vue&type=script&lang=js"
export * from "./AreiaEBrita.vue?vue&type=script&lang=js"
import style0 from "./AreiaEBrita.vue?vue&type=style&index=0&id=1f83a678&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f83a678",
  null
  
)

export default component.exports