<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="row px-0 mx-0">
      <div class="bg-light mx-0 px-0 text-center text-md-right col-md">
        <div class="pr-0 mr-md-5 mt-5 mr-4">
          <h1 class="mr-md--5">{{ this.destaqueTitulo }}</h1>
          <p class="mr-md--5">{{ this.destaqueTexto }}</p>
        </div>
      </div>
      <div class="px-0 mx-0 col-md d-flex flex-column">
        <div
          class="px-0 mx-0 d-flex align-items-center"
          style="background-color: #ffcb11"
        >
          <div class="row px-0 mx-0 h-100">
            <div class="ml-5 my-5 text-left text-white col-md-6">
              <h1 class="px-0 mx-0">
                {{ this.destaqueChamada }}
              </h1>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center" style="background-color: black">

        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.textoBaseTitulo }}
          </h1>
          <p style="white-space: pre-line" class="mt-3">
            {{ this.textoBaseTexto }}
          </p>
        </div>
      </div>


      <CardsMercado :data="this.cards" />
       <div class="py-4 text-center">
<h3 class="text-center font-weight-bold text-uppercase">
A SOLUÇÃO FLORESTAL QUE VOCÊ PRECISA, ESTÁ NA SOTREQ
</h3>
<div>
       <a type="button" href="https://hub.sotreq.com.br/sotreq-florestal" class="btn mt-4 btn-lg btn-preto">Conheça nossas soluções</a>
       </div>
       </div>

       <Destaque :data="this.destaques[0]" />


<widecard />
      </div>

    <Footer  :buttonless="true" />
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import CardsMercado from '@/components/Mercados/CardsMercado.vue'
import Destaque from '@/components/DestaqueFlorestal.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Widecard from '../../components/Mercados/widecard.vue'

export default {
  name: 'Home',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    CardsMercado,
    Destaque,
    Footer,
    Widecard
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/1.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.heroBottomButtonText = resp.data.bottomherobuttontext
      this.created_at = resp.data.created_at
      this.updated_at = resp.data.updated_at
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
      this.destaques = resp.data.destaques
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      destaques: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.btn-preto {
    border: 2px solid #fff;
    background-color: #252525;
    color: #fff;
}
.btn-preto:hover {
    color: #fff;
    background-color: #595959;
    border-color: #595959;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}

</style>
