<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />

    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.destaqueTitulo }}
          </h1>

        </div>
      </div>

                  <div class="row mt-4">
      <div class="col  text-left">
        <h2 class="font-weight-bold">            {{ this.destaqueTexto }}
</h2>
        <div class="bar mb-3"></div>
      </div>
    </div>

        <div v-if="this.conteudo.length > 1">
        <div class="resetforcontent">
        <div  v-html="this.conteudo"></div>
        </div>
        </div>

                  <div class="row pt-4">
      <div class="col  text-left">
        <h2 class="font-weight-bold">            {{ this.heroBottomLink }}
</h2>
        <div class="bar mb-2"></div>
      </div>
    </div>
      <div class="row mb-3">
        <div class="col-12 mt-4 text-left">
          <h4 class="ml-0 pl-0 mt-2 font-weight-bold">
           {{ this.textoBaseTitulo }}
          </h4>
        </div>
      </div>
        <div v-if="this.textoBaseTexto.length > 1">
        <div class="resetforcontent">
        <div  v-html="this.textoBaseTexto"></div>
        </div>
        </div>

      <div class="row mt-4">
        <div class="col-12 mt-4 text-left">
          <h2 class="ml-0 pl-0 mt-2 font-weight-bold">
           {{ this.heroBottomTitulo }}
          </h2>
        </div>
      </div>

            <div class="row mt-4">
      <div class="col  text-left">
        <h2 class="font-weight-bold"> {{ this.bottomherobuttontext }}</h2>
        <div class="bar mb-3"> </div>
       <p>
        {{ this.heroBottomTexto }}
       </p>
      </div>
    </div>

      <div class="card-deck row row-cols-1 row-cols-md-2 mt-3">
        <div class="mb-4" v-for="(destaque, i) in destaques" :key="i">
          <GarantiaDestaque :data="destaque" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import GarantiaDestaque from '../components/GarantiaDestaque.vue'

export default {
  name: 'Garantia',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    GarantiaDestaque,
    Footer
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/183.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
      this.destaques = resp.data.destaques
      this.conteudo = resp.data.content

      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
  data () {
    return {
      destaques: [],
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      conteudo: []

    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
