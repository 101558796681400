import { render, staticRenderFns } from "./OleoLubrificante.vue?vue&type=template&id=94dc4110&scoped=true"
import script from "./OleoLubrificante.vue?vue&type=script&lang=js"
export * from "./OleoLubrificante.vue?vue&type=script&lang=js"
import style0 from "./OleoLubrificante.vue?vue&type=style&index=0&id=94dc4110&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94dc4110",
  null
  
)

export default component.exports