<template>
  <div class="ofertas">
    <div id="carouselofertas" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner container">
        <div class="carousel-item text-left active">
          <div class="d-flex flex-column">
            <img :src="data[0].image" class="img-fluid" alt="" />
            <h1 class="mt-4 font-weight-bold">{{ data[0].titulo }}</h1>

            <h4 >{{ data[0].subtitulo }}</h4>
            <div class="row">
              <div class="col">
                <p>{{ data[0].texto }}</p>
              </div>
              <div class="col-md-auto">
                <div class="row">
                  <div class="col">
                    <a :href="data[0].link">
                    <button type="button" class="px-5 btn btn-secondary">
                      Faça sua cotação
                    </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-for="(data, i) in data.filter((s, ind) => {
            return ind > 0;
          })"
          :key="i"
          class="carousel-item text-left"
        >
          <div class="d-flex flex-column">
            <img :src="data.image" class="img-fluid" alt="" />
            <h1 class="mt-4 font-weight-bold">{{ data.titulo }}</h1>

            <h4>{{ data.subtitulo }}</h4>
            <div class="row">
              <div class="col">
                <p>{{ data.texto }}</p>
              </div>
              <div class="col-md-auto">
                <div class="row">
                  <div class="col">
                  <a :href="data.link">
                    <button type="button" class="px-5 btn btn-secondary">
                      Faça sua cotação
                    </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-md-none d-lg-block d-xl-block">
      <a
        class="carousel-control-prev"
        href="#carouselofertas"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
      </a>

      <a
        class="carousel-control-next"
        href="#carouselofertas"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Próximo</span>
      </a>
      </div>

      <ol style="position: relative" class="carousel-indicators">
        <li data-target="#carouselofertas" data-slide-to="0" class="active"></li>

        <li
          v-for="(data, i) in data.filter((s, ind) => {
            return ind > 0;
          })"
          :key="i"
          data-target="#carouselofertas"
          :data-slide-to="i + 1"
        >
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ofertas',
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators > li {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #222;
}
.bar {
  margin-top: -9px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
button {
  border: 2px solid black;
  background-color: transparent;
  color: black;
  border-radius: none;
}

@media only screen and (min-width: 600px) {
 .carousel-control-prev-icon {
margin-right: 120px;}

.carousel-control-next-icon {
margin-left: 120px;}
}

</style>
