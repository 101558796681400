<template>
  <div class="home">
    <Header />

    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.nome }}
          </h1>
          <div class="bar"></div>

          <h4 class="  font-weight-bold mt-4 mb-3">Produto:</h4>

        </div>
      </div>

      <div class="card-deck row row-cols-1 row-cols-md-2">
        <div class="mb-4" v-for="(sub, index) in subcategorias" :key="index">
          <Novidade :data="sub" />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Novidade from '@/components/NovidadeMaquinas.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Home',
  async mounted () {
    window.scrollTo(0, 0)
    this.loadCategoria()
  },
  components: {
    Header,
    Footer,
    Novidade
  },
  methods: {
    async loadCategoria () {
      const resp = await ApiContent.get(
        `categoriamaquinas/${this.$route.params.id}.json`
      )
      console.log(resp.data)
      this.nome = resp.data.nome
      this.subcategorias = resp.data.subcategorias.map((s, index) => {
        s.titulo = s.nome
        s.subtitulo = ''
        s.texto = s.descricao
        s.link = `#/nossasmaquinas/subcatg/${s.id}`
        s.buttontext = 'Saiba mais'
        return s
      })
    }
  },
  data () {
    return {
      nome: '',
      subcategorias: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
