import { render, staticRenderFns } from "./Ofertas.vue?vue&type=template&id=9db2c3fa&scoped=true"
import script from "./Ofertas.vue?vue&type=script&lang=js"
export * from "./Ofertas.vue?vue&type=script&lang=js"
import style0 from "./Ofertas.vue?vue&type=style&index=0&id=9db2c3fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9db2c3fa",
  null
  
)

export default component.exports