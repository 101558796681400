<template>
    <div class="home">
        <meta name="description" content="Encontre equipamentos SEM, fabricados pela Caterpillar, na Sotreq. Carregadeiras e motoniveladoras SEM para sua obra de construção. O melhor custo-benefício.">
        <Header />
        <HeroMercados :data="bannermercado" />
        <div class="container">
            <div class="row">
                <div class="col mt-4 text-left">
                    <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
                        {{ this.destaqueTitulo }}
                    </h1>
                    <p style="white-space: pre-line" class="mt-3">
                        {{ this.destaqueTexto }}
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col  text-left">
                    <h2 class="ml-0 pl-0 mt-2 font-weight-bold">
                        Encontre máquinas SEM na Sotreq para a sua operação
                    </h2>
                    <p style="white-space: pre-line" class="mt-3">
                       A SEM é uma marca Caterpillar presente em mais de 90 países, que desenvolve equipamentos como carregadeiras de rodas e motoniveladoras da mais alta qualidade e durabilidade, e contam com garantia de baixa manutenção e um ótimo custo-benefício.
                    </p>
                    <p>Hoje, as máquinas SEM estão em 80% do território nacional, nas regiões Sudeste, Centro-Oeste, Norte e Nordeste, oferecendo todo o suporte técnico da Sotreq para a Carregadeiras de Rodas SEM 618D, 636D e 656D, e a Motoniveladora SEM919.
                    </p>
                    <p>Eleve a produtividade do seu negócio com as máquinas SEM.
                    </p>
                    <h2 class="ml-0 pl-0 mt-2 font-weight-bold">Conheça os equipamentos SEM:</h2>
                </div>
            </div>
        </div>
        <div class="w-100 py-2 mt-3  bg-yellow">
            <div class="container">
                <h2 class="ml-0 pl-0 mt-2 font-weight-bold text-left"> Carregadeiras de Rodas SEM</h2>
            </div>
        </div>
        <div class='container'>
            <h5 class="ml-0 pl-0 mt-4 font-weight-bold text-left">Robustez, confiabilidade e economia para aplicações diversas com as Carregadeiras SEM</h5>
    
            <div class="desatque my-4">
                <!-- inicio h card -->
                        <div class="card mb-3 border-0">
                            <div class="row d-flex flex-md-row-reverse">
                                <div class="col-md-7 d-flex align-items-center">
                                    <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdDRGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--13fe838cb0febe7c7bce9d64c4974b50d67a5875/SEM618D.png" class="img-fluid" alt="Carregadeira de Rodas SEM618D em operação no campo"
                                    />
                                </div>
                                <div class="col-md-5 d-flex flex-column justify-content-center py-1">
                                    <div class="text-left card-body d-flex flex-column justify-content-center">
                                        <div class="d-flex justify-content-center flex-column">
                                            <div>
                                                <h2 class="font-weight-bold">Carregadeira de Rodas SEM618D</h2>
                                                <div class="bar"></div>
                                                <h5 class="mt-3">
                                                    <strong>Potência Nominal</strong>: 62.5 kW / 84 hp </br>
                                                    <strong>Peso Operacional</strong>: 5.938 kg </br>
                                                    <strong>Capac. de Carga</strong>: 1.800 kg </br>
                                                    <strong>Capac. da Caçamba</strong>: 1,0 m³
                                                </h5>
                                            </div>
                                            <a href="images/sem618d_spec_port_ago2023.pdf" class="mb-3 isalink" download>
                      Download das especificações da Carregadeira SEM618D
                      </a>
                                                <a href="#formulario">
                          <button
                            type="button"
                            class="px-3 py-1  button-yellow"
                          >
    Solicite uma cotação                      </button>
                        </a>
                                                                    <div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- fim h card -->

                       <!-- inicio h card -->
                        <div class="card mb-3 border-0">
                            <div class="row d-flex flex-md-row">
                                <div class="col-md-7 d-flex align-items-center">
                                    <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdGtGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4d19773e3e09061159075a50def3cbbb7b843631/SEM636D.png " class="img-fluid" alt="Carregadeira de Rodas SEM636D em operação na neve"
                                    />
                                </div>
                                <div class="col-md-5 d-flex flex-column justify-content-center py-1">
                                    <div class="text-left card-body d-flex flex-column justify-content-center">
                                        <div class="d-flex justify-content-center flex-column">
                                            <div>
                                                <h2 class="font-weight-bold">Carregadeira de Rodas SEM636D</h2>
                                                <div class="bar"></div>
                                                <h5 class="mt-3">
                                                    <strong>Potência Nominal</strong>:  92 kW / 125 hp</br>
                                                    <strong>Peso Operacional</strong>:   10.280 até 10.630  kg  </br>
                                                    <strong>Capac. de Carga</strong>: 3.000 kg  </br>
                                                    <strong>Capac. da Caçamba</strong>: 1.5 a 2.5 m³
                                                </h5>
                                            </div>
                                            <a href="images/sem636d_spec_port_ago2023.pdf" class="mb-3 isalink" download>
                       Download das especificações da Carregadeira SEM636D
                      </a>
                                            <div>
                                                <a href="#formulario">
                          <button
                            type="button"
                            class="px-3 py-1  button-yellow"
                          >
    Solicite uma cotação                      </button>
                        </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- fim h card -->

   <!-- inicio h card -->
                        <div class="card mb-3 border-0">
                            <div class="row d-flex flex-md-row-reverse">
                                <div class="col-md-7 d-flex align-items-center">
                                    <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdG9GIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e267149d16222a98cba124fd8e18ec43796a29bc/SEM656D.png " class="img-fluid" alt="Carregadeira de Rodas SEM656D em operação no pátio"
                                    />
                                </div>
                                <div class="col-md-5 d-flex flex-column justify-content-center py-1">
                                    <div class="text-left card-body d-flex flex-column justify-content-center">
                                        <div class="d-flex justify-content-center flex-column">
                                            <div>
                                                <h2 class="font-weight-bold">Carregadeira de Rodas SEM656D</h2>
                                                <div class="bar"></div>
                                                <h5 class="mt-3">
                                                    <strong>Potência Nominal</strong>:  162 kW / 217 hp </br>
                                                    <strong>Peso Operacional</strong>:17.302 kg </br>
                                                    <strong>Capac. de Carga</strong>: 5.938 kg  </br>
                                                    <strong>Capac. da Caçamba</strong>: 2.7 a 4.5 m³
                                                </h5>
                                            </div>
                                            <a href="images/sem656d_spec_port_ago2023.pdf" class="mb-3 isalink" download>
                      Download das especificações da Carregadeira SEM656D
                      </a>
                                            <div>
                                                <a href="#formulario">
                          <button
                            type="button"
                            class="px-3 py-1  button-yellow"
                          >
    Solicite uma cotação                      </button>
                        </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- fim h card -->
</div>
        </div>
        <div class="w-100 py-2 mt-3  bg-yellow">
            <div class="container">
                <h2 class="ml-0 pl-0 mt-2 font-weight-bold text-left"> Motoniveladora SEM</h2>
            </div>
        </div>
        <div class='container'>
            <h4 class="ml-0 pl-0 mt-4 font-weight-bold text-left">O melhor custo-benefício para atender às suas necessidades em qualquer aplicação com a motoniveladora SEM.</h4>
    
            <div class="desatque my-4">

                       <!-- inicio h card -->
                        <div class="card mb-3 border-0">
                            <div class="row d-flex flex-md-row">
                                <div class="col-md-7 d-flex align-items-center">
                                    <img src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdDhGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--03632203b3deaf8bfbe11be59c09c083ec27ba39/919d.png " class="img-fluid" alt="Motoniveladora SEM919 em operação no campo"
                                    />
                                </div>
                                <div class="col-md-5 d-flex flex-column justify-content-center py-1">
                                    <div class="text-left card-body d-flex flex-column justify-content-center">
                                        <div class="d-flex justify-content-center flex-column">
                                            <div>
                                                <h2 class="font-weight-bold">Motoniveladora SEM919</h2>
                                                <div class="bar"></div>
                                                <h5 class="mt-3">
                                                   <strong>Potência Nominal:</strong> 150,9 kW / 202 hp </br>
<strong>Peso Operacional:</strong> 15070 kg </br>
<strong>Profundidade de corte máx.:</strong> 715 mm </br>
<strong>Altura de elevação máx. (Lâmina):</strong> 475 mm </br>
<strong>Comprimento lâmina:</strong> disponibilidade para 12, 13 e 14 pés.
                                                </h5>
                                            </div>
                                            <div>
    
                                            </div>
                                        <a href="images/sem919_spec_port_ago2023.pdf" class="mb-3 isalink" download>
                        Download das especificações da Motoniveladora SEM919
                      </a>

                                            <a href="#formulario" class="notalink">
   <h5 class="font-weight-bold">
    Solicite uma cotação abaixo    
    </h5>          
                        </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <!-- fim h card -->
                    </div>


        <div class="border-2 border-top pt-5 mt-5"  id="formulario">
            <h2 class="font-weight-bold text-center">
            Peça uma cotação do seu equipamento SEM
            </h2>
            <h5 class="font-weight-normal text-center mt-4">
            Preencha os formulário abaixo e a Sotreq entrará em contato com você.
            </h5>
        </div>
        </div>
    <Footer  :hasform="true"  :buttonless="true" gtmterm="maquinas_sem"/>
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import CardsTransmissao from '../../components/CardsTransmissao.vue'

export default {
    name: 'Sem',
    props: {
        inverted: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        buttonless: {
            type: Boolean,
            default: () => {
                return false
            }
        },
    },

    mounted() {
        this.loadContent()
    },
    components: {
        Header,
        HeroMercados,
        Footer,
        CardsTransmissao
    },
    methods: {
        async loadContent() {
            const resp = await ApiContent.get('paginas/187.json')
            console.log(resp.data)
            this.heroTopTitulo = resp.data.heroTopTitulo
            this.heroTopTexto = resp.data.heroTopTexto
            this.heroTopLink = resp.data.heroTopLink
            this.destaqueTitulo = resp.data.destaqueTitulo
            this.destaqueTexto = resp.data.destaqueTexto
            this.destaqueChamada = resp.data.destaqueChamada
            this.textoBaseTitulo = resp.data.textoBaseTitulo
            this.textoBaseTexto = resp.data.textoBaseTexto
            this.heroBottomTitulo = resp.data.heroBottomTitulo
            this.heroBottomTexto = resp.data.heroBottomTexto
            this.heroBottomLink = resp.data.heroBottomLink
            this.bottomherobuttontext = resp.data.bottomherobuttontext
            this.heroBottomLink = resp.data.heroBottomLink
            this.url = resp.data.url
            this.topHeroImage = resp.data.topHeroImage
            this.logo = resp.data.logo
            this.bottomHeroImage = resp.data.bottomHeroImage
            this.cards = resp.data.cards
            this.bannermercado = {
                titulo: resp.data.heroTopTitulo,
                image: resp.data.topHeroImage,
                subtitulo: resp.data.subtitulo,
                texto: resp.data.texto,
                link: resp.data.link,
                buttontext: resp.data.buttontext,
                content: resp.data.content
            }
            // this.cards.map(card => {
            //  card.link = '#/acessorio-inner/' + card.link
            // })
        }
    },
    data() {
        return {
            heroTopTitulo: [],
            heroTopTexto: [],
            heroTopLink: [],
            destaqueTitulo: [],
            destaqueTexto: [],
            destaqueChamada: [],
            textoBaseTitulo: [],
            textoBaseTexto: [],
            heroBottomTitulo: [],
            heroBottomTexto: [],
            heroBottomLink: [],
            created_at: [],
            updated_at: [],
            url: [],
            topHeroImage: [],
            logo: [],
            bottomHeroImage: [],
            cards: []
        }
    }
}
</script>

<style scoped>
.quadrado {
    border: 5px solid #ffcb11;
}

.equipamento_nvo {
    background: rgb(2, 0, 36);
    background: radial-gradient( circle, rgba(2, 0, 36, 1) 0%, rgba(214, 155, 31, 1) 50%);
}

a {
    text-decoration: none;
}

.button-yellow {
    border: 2px solid #FFCD00;
    background-color: #FFCD00;
    color: #000000;
    border-radius: 5px;
}

.button-yellow:hover {
    border: 2px solid black;
    background-color: black;
    color: white;
}
.isalink{
text-decoration: underline;
color: #0000FF;
}
.mx-auto.mt-5.container {
  margin-top: -0.8em !important;
}
</style>
