<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="row px-0 mx-0">
      <div class="bg-light mx-0 px-0 text-center text-md-right col-md">
        <div class="pr-0 mr-md-5 mt-5 mr-4">
          <h1 class="mr-md--5">{{ this.destaqueTitulo }}</h1>
          <p class="mr-md--5">{{ this.destaqueTexto }}</p>
        </div>
      </div>
      <div class="px-0 mx-0 col-md d-flex flex-column">
        <div
          class="px-0 mx-0 d-flex align-items-center"
          style="background-color: #ffcb11"
        >
          <div class="row px-0 mx-0 h-100">
            <div class="ml-5 my-5 text-left text-white col-md-6">
              <h1 class="px-0 mx-0">{{ this.destaqueChamada }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.textoBaseTitulo }}
          </h1>
          <p style="white-space: pre-line" class="mt-3">
            {{ this.textoBaseTexto }}
          </p>
          <p style="white-space: pre-line" class="mt-3">
            Veja as principais respostas.
            <a target="_blank" href="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBalVGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2219ba05c694844e2db85ea709b54a74398fded6/FAQ%20Mari%CC%81timo%20de%20Lazer.pdf">Acessar o FAQ.</a>
          </p>
        </div>
      </div>
      <div class="px-0 mx-0 row mt-4">
        <div class="px-0 mx-0 col">
          <img
            :src="this.bottomHeroImage"
            alt="Banner marítimo" class="img-fluid"
          />
        </div>
      </div>
      <div class="px-0 mx-0 row my-4">
        <div class="col px-0 mx-0">
          <h1 class="text-dark font-weight-bold"></h1>
        </div>
      </div>
      <div class="mt-4 card-deck row row-cols-1 row-cols-md-2">
        <div class="mb-4" v-for="(destaque, i) in destaques" :key="i">
          <Novidade :data="destaque" />
        </div>
      </div>
      <div class="px-0 mx-0 row my-4">
        <div class="col px-0 mx-0">
          <h1 class="text-dark font-weight-bold">
            Conheça as soluções da Sotreq para o mercado Marítimo:
          </h1>
        </div>
      </div>

       <div
          class="d-flex flex-column flex-md-row justify-content-between mt-5"
        >
    
           <a
            type="button"
            class="btn btn-secondary btn-lg btn-black btn-block notalinkwhite"
            href="images/ledm345729.pdf"   target="_blank"
            >Conheça nosso catálogo</a
          >
          <a
            type="button"
            class="btn btn-secondary btn-lg btn-black btn-block notalinkwhite"
            href="/#/servicos/maritimo-comercial"
            >Serviços</a
          >
</div>
<br><br>
<widecard />
      <!-- <CardsMercado :data="this.cards" /> -->
    </div>
    <Footer  :buttonless="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Novidade from '@/components/Novidade.vue'
import Widecard from '../../components/Mercados/widecard.vue'

export default {
  name: 'Home',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    Novidade,
    HeroMercados,
    Footer, 
    Widecard
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/7.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.created_at = resp.data.created_at
      this.updated_at = resp.data.updated_at
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards

      this.destaques = resp.data.destaques

       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
};
    },
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
button {
  background-color: black;
  color: white;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
.btn-block+.btn-block {
    margin-top: 0rem !important;
}
</style>
