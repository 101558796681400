<template>
  <div class="home">
    <Header />      
      <div   class="herohome">
        <div   class="d-block d-sm-block d-md-block d-lg-none">
          <img   src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbWdGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--95a3e6542feca7c566b84a336e06a7e1fb77bf2f/Grupo%201101.png" alt="HeroMercados" class="img-fluid">
          <div   class="container my-5 my-md-0 d-flex align-items-center text-left h-100">
            <div   class="text-black text-left">
              <h1   class="font-weight-bold display-4">
                Serviço Marítimo Lazer
              </h1>
              <div   class="bar"></div>
            </div>
          </div>
        </div>
        <div   class="d-none d-sm-none d-md-none d-lg-block">
 
          <div   class="hero-img active" style='background-image: url("https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbWdGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--95a3e6542feca7c566b84a336e06a7e1fb77bf2f/Grupo%201101.png");'>
            <div   class="container d-flex align-items-center text-left h-100">
              <div   class="col-5 text-white text-left">
                <h1   class="font-weight-bold display-3">
                  Serviço Marítimo Lazer
                </h1>
                <div   class="bar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div   class="container">
        <div   class="row">
          <div   class="col mt-4">
            <h1   class="ml-0 pl-0 mt-2 text-left font-weight-bold">
              Suporte Produto Marítimo
            </h1>
            <div  >
              <div  >
                <h2   class="text-left underline-title">
                  Serviço de campo
                </h2>
                <div   class="bar mb-1"></div>
                <p   class="body-text pt-4 mt-2">
                  A Sotreq vai onde você estiver. Conte com uma equipe de técnicos experientes que realizam os serviços de manutenção corretiva e preventiva. Alguns técnicos ficam em locais estratégicos, como no interior dos estados cobertos pela Sotreq, visando prestar o melhor e mais ágil atendimento. Para realização de todos os serviços, nossos técnicos de campo contam com veículos equipados com recursos de mobilidade, ferramentas especializadas, aparelhos de diagnóstico, literatura técnica e sistemas de informação computadorizados, para manter custos econômicos e assegurar qualidade e produtividade segundo padrões estabelecidos pela fábrica.
                </p>
              </div>
              <div  >
                <h2   class="text-left underline-title">
                  Reparo de componentes
                </h2>
                <div   class="bar mb-1"></div>
                <p   class="body-text pt-4 mt-2"></p>
              </div>
            </div>
            <div   class="render-content" style="margin-top: -21px;">
              <div>
                Em todas estas filiais, os Equipamentos CAT contam com técnicos constantemente atualizados com as últimas inovações da Caterpillar, preparados para lidar com qualquer serviço, seja em pequenos reparos ou grandes reformas. <br>
                <br>
                Para realização de todos os serviços, nossos técnicos contam com uma estrutura bem dimensionada apoiada por ferramentas especializadas, aparelhos de diagnóstico, literatura técnica e sistemas de informação computadorizados, para manter custos econômicos e assegurar qualidade e produtividade segundo padrões estabelecidos pela fábrica. Além disso, nossas oficinas são certificadas constantemente e algumas possuem as certificações ISO 9001, 14001 e OHSAS.
              </div>
            </div>
            <div  >
              <div  >
                <h2   class="text-left underline-title pt-3">
                  Contratos
                </h2>
                <div   class="bar mb-1"></div>
                <p   class="body-text pt-4 mt-2" style="margin-bottom: -10px;">
                  A Sotreq oferece contratos de manutenção customizados, de acordo com a necessidade de cada cliente. Entre em contato com a gente e saiba quais contratos estão disponíveis para o mercado Marítimo.
                </p>
              </div>
              <div  >
                <h2   class="text-left underline-title pt-3">
                  Contato para serviços
                </h2>
                <div   class="bar mb-1"></div>
                <p   class="body-text pt-4 mt-2" style="margin-bottom: -10px;"></p>
              </div>
            </div>
            <p   class="medium-text-bold" style="margin-bottom: -2px;">
              Entre em contato com a Sotreq!
            </p>
            <p   class="medium-text-light">
              Serviços Marítimo: +55 21 99547-1787
            </p>
          </div>
        </div>
      </div>
      <div   class="w-100 my-1 pt-1 pb-2 bg-black">
        <div   class="container">
          <h2   class="megatitle-yellow pt-4 text-center">
            TESTE DE MAR - GRATUITO
          </h2>
          <h2   class="text-left underline-title text-white">
            Quando se trata do seu iate, você não deixa nada ao acaso.
          </h2>
          <div   class="bar mb-1"></div>
          <p   class="body-text text-white pt-4 mt-2">
            Deixe-nos mostrar por que os motores Cat® e o serviço Sotreq, tornam cada experiência com seu novo iate um prazer.
          </p>
          <div   class="row px-0 mx-0">
            <div   class="col px-0 mx-0">
              <div   class="card mb-3 bg-transparent">
                <div   class="row p-0 m-0 d-flex">
                  <div   class="col-md-6 m-0 p-0 d-flex align-items-start">
                    <img   src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbW9GIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a55c552a038919a2b683e88b9213eeb0aa9f8b42/Grupo%20de%20ma%CC%81scara%202.png" class="img-fluid mt-3">
                  </div>
                  <div   class="col-md-6 d-flex flex-column justify-content-start">
                    <div   class="text-left card-body p-0">
                      <div   class="d-flex justify-content-start flex-column">
                        <div  >
                          <h2   class="text-left underline-title text-white">
                            Orientação
                          </h2>
                          <div   class="bar mb-3"></div>
                          <p   class="text-white mb-2">
                            Você e seu revendedor Sotreq farão um teste de mar básico gratuitamente, para garantir a boa aquisição do seu novo iate.<br  >
                            Status Report do motor.
                          </p>
                          <p   class="text-white mb-2">
                            Demonstração dos controles e exibições e recursos do motor.
                          </p>
                          <p   class="text-white mb-2">
                            Obtenha respostas para todas as suas dúvidas quanto à motorização de seu iate.
                          </p>
                        </div>
                        <p   class="body-text text-yellow mt-3">
                          Aproveite um suporte que vai muito além da entrega
                        </p>
                        <div   class="w-100 pt-3 d-flex justify-content-start">
             
                                                  <button  class="btn btn-ouline-light px-3"><a href="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbXNGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9b219caa102486ff58563c0daf257b7086d3cb42/TESTE%20DE%20MAR_Sotreq.pdf" download="download" target="_blank" class="notalink">Faça o download do material e saiba mais</a></button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
              </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HeroMercados from "@/components/Mercados/HeroMercados.vue";
import Footer from "@/components/Footer.vue";
import ApiContent from "@/services/api.js";
import CardsUnderline from "../components/CardsUnderline.vue";
import DestaquesUnderline from "../components/DestaquesUnderline.vue";

export default {
  name: "MaritimoLazer",
  mounted() {
    this.loadContent();
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    CardsUnderline,
    DestaquesUnderline
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get("paginas/182.json");
      console.log(resp.data);
      this.heroTopTitulo = resp.data.heroTopTitulo;
      this.heroTopTexto = resp.data.heroTopTexto;
      this.heroTopLink = resp.data.heroTopLink;
      this.content = resp.data.content;
      this.descricao = resp.data.descricao;
      this.destaqueTitulo = resp.data.destaqueTitulo;
      this.destaqueTexto = resp.data.destaqueTexto;
      this.destaqueChamada = resp.data.destaqueChamada;
      this.textoBaseTitulo = resp.data.textoBaseTitulo;
      this.textoBaseTexto = resp.data.textoBaseTexto;
      this.heroBottomTitulo = resp.data.heroBottomTitulo;
      this.heroBottomTexto = resp.data.heroBottomTexto;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.bottomherobuttontext = resp.data.bottomherobuttontext;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.url = resp.data.url;
      this.topHeroImage = resp.data.topHeroImage;
      this.logo = resp.data.logo;
      this.bottomHeroImage = resp.data.bottomHeroImage;
      this.cards = resp.data.cards;
      this.destaques = resp.data.destaques;
          this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      //this.cards.map(card => {
      //  card.link = '#/acessorio-inner/' + card.link
      // })
      };
    },
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      content: '',
      descricao: '',
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      destaques: []
    };
  },
};
</script>
<style scoped>
.card {
  border: none;
}
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}

div.card:nth-child(6) > img:nth-child(1) {
  min-height: 450px;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.card-body {
  align-items: center;
  display: flex;
}
.card-img-overlay {
  position: relative !important;
}
button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img {
  height: 70vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 500px;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.herohome {
  margin-top: -80px;
  position: relative;
}
.hero-overlay {
  height: 100%;
  background-color: #000;
  position: absolute;
  width: 100%;
  opacity: 0.3;
}
.oneliner {
  white-space: nowrap !important;
}
.lh-sm {
  line-height: 62px !important;
}
@media (min-width: 648px) {
  .btn-block + .btn-block {
    margin-top: unset;
  }
}
</style>
