<template>
  <div class="mb-5 herohome">
    <div class="d-block d-sm-block d-md-block d-lg-none">
      <div
        id="carouselExampleIndicatorsmob"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicatorsmob"
            data-slide-to="0"
            class="nottraco active"
          ></li>
          <li
            class="nottraco"
            v-for="(banner, i) in data.filter((s, ind) => {
              return ind > 0;
            })"
            :key="i"
            data-target="#carouselExampleIndicatorsmob"
            :data-slide-to="i + 1"
          ></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row mt-5">
              <div class="col text-left pb-5">
                <img class="img-fluid" :src="data[0].image" alt="slide" />
                <div class="container">
                  <h3 class="text-uppercase yellowtext mt-5">
                    {{ data[0].titulo }}
                  </h3>
                  <h2 class="text-white font-weight-bold text-uppercase w-75 mt-5">
                    {{ data[0].subtitulo }}
                  </h2>
                  <p>
                    {{data[0].texto}}
                  </p>
                  <button
                    type="button"
                    class="py-2 px-5 btn mt-2 mb-3 btn-secondary"
                  >
                   {{data[0].buttontext}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(banner, i) in data.filter((s, ind) => {
              return ind > 0;
            })"
            :key="i"
            class="carousel-item"
          >
            <div class="row mt-5">
              <div class="col text-left pb-5">
                <img class="img-fluid" :src="banner.image" alt="slide" />
                <div class="container">
                  <h4 class="font-weight-bold mt-5">
                    {{ banner.titulo }}
                  </h4>
                  <h2 class="text-white font-weight-bold text-uppercase w-75 mt-5">
                    {{ banner.subtitulo }}
                  </h2>
                  <p>
                    {{banner.texto}}
                  </p>
                  <button
                    type="button"
                    class=" py-2 px-5 btn mt-2 mb-3 btn-secondary"
                  >
                   {{banner.buttontext}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-md-none d-lg-block d-xl-block">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="traco active"
            :key="0"
          ></li>
          <li
            class="traco"
            v-for="(banner, i) in data.filter((s, ind) => {
              return ind > 0;
            })"
            :key="i"
            data-target="#carouselExampleIndicators"
            :data-slide-to="i + 1"
          ></li>
        </ol>
        <div class="carousel-inner">
          <div
            :style="'background-image: url(' + data[0].image + ');'"
            class="carousel-item hero-img active"
          >
            <div class="h-100 d-flex container align-items-center">
              <div class="text-white row mt-5">
                <div class="col text-left">
                  <h2 class="text-uppercase yellowtext">
                    {{ data[0].titulo }}
                  </h2>
                  <h1 class="text-white font-weight-bold text-uppercase w-75 mt-5">
                    {{ data[0].subtitulo }}
                  </h1>
                  <p>
                    {{data[0].texto}}
                  </p>
                  <button type="button" class="py-2 px-5 btn mt-2 btn-secondary">
                   {{data[0].buttontext}}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(banner, i) in data.filter((s, ind) => {
              return ind > 0;
            })"
            :key="i"
            :style="'background-image: url(' + banner.image + ');'"
            class="carousel-item hero-img"
          >
            <div class="h-100 d-flex container align-items-center">
              <div class="text-white row mt-5">
                <div class="col text-left">
                  <h2 class="text-uppercase yellowtext">
                    {{ banner.titulo }}
                  </h2>
                  <h1 class="text-white font-weight-bold text-uppercase w-75 mt-5">
                    {{ banner.subtitulo }}
                  </h1>
                   <p>
                    {{banner.texto}}
                  </p>
                  <button type="button" class="py-2 px-5 btn mt-2 btn-secondary">
                   {{banner.buttontext}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a> -->
      </div>
    </div>

    <div class="py-1" style="background-color: #ffbb11">

    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
/* The hero image */
/* .carousel-indicators > li {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #222;
} */
.yellowtext {
  color: #ffcb11;
}
button {
  border-radius: 0;
  border: none;
  background-color: rgba(255,205, 17);
  color: black;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.nottraco {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #222;
}

.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img { max-height: 70vh; 
  height: 700px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.herohome {
  margin-top: -80px;
}
</style>
