<template>
  <div class="desatque my-4">
      <div
          style=""
          v-for="(destaque, i) in data"
          :key="i"
          class=""
        >
    <div class="row">
      <div class="col  text-left">
        <h2 class="font-weight-bold"> {{ destaque.titulo }}</h2>
        <div class="bar mb-3"></div>
        <p class="preline"> {{ destaque.texto }}   </p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'LineTextBlock',
  props: {
    data: {
      type: Array,
      required: true
      // default: () => {
      //  return { image: "", titulo: "", subtitulo: "", text: "" };
      // },
    },
    inverted: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    buttonless: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>

<style scoped>
button {
  border-radius: none;
  border: 3px solid black;
  background-color: transparent;
  color: black;
}

.card {
  border: none;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
