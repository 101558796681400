<template>
  <div class="home">
    <Header />

    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ treinamento.nome }}
          </h1>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <div class="card mb-3">
            <div class="row d-flex flex-md-row">
              <div class="col-md-7 d-flex align-items-center">
                <img :src="treinamento.avatar" class="img-fluid" alt="Sotreq revendedora Caterpillar" />
              </div>
              <div
                class="col-md-5 d-flex flex-column justify-content-center py-1"
              >
                <div class="text-left card-body">
                  <div class="d-flex justify-content-between flex-column">
                    <div>
                      <p class="my-0 py-0 font-weight-bold">
                        Número de participantes:
                      </p>
                      <div class="d-flex">
                        <p class="my-0 py-0">Mínimo:</p>
                        <p class="ml-1 my-0 py-0">{{ treinamento.numMin }}</p>
                      </div>
                      <div class="d-flex">
                        <p class="my-0 py-0">Máximo:</p>
                        <p class="ml-1">{{ treinamento.numMax }}</p>
                      </div>

                      <p class="my-0 py-0 font-weight-bold">Carga horária:</p>
                      <div class="d-flex">
                        <p class="my-0 py-0">{{ treinamento.cargaHoraria }}</p>
                        <p class="ml-1">horas</p>
                      </div>

                      <p class="my-0 py-0 font-weight-bold">Pré-requisitos:</p>
                      <p style="white-space: pre-line">
                        {{ treinamento.preRequisitos }}
                      </p>

                      <p class="font-weight-bold my-0 py-0">Público-alvo:</p>
                      <p style="white-space: pre-line">
                        {{ treinamento.publicoAlvo }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col text-left">
          <h5 style="line-height: 10px" class="font-weight-bold">Detalhes:</h5>
          <div class="bar"></div>

          <p style="white-space: pre-line" class="mt-3">
            {{ treinamento.detalhes }}
          </p>
        </div>
        <div class="col text-left">
          <h5 style="line-height: 10px" class="font-weight-bold">
            Conteúdo programático:
          </h5>
          <div class="bar w-50"></div>

          <p style="white-space: pre-line" class="mt-3">
            {{ treinamento.conteudoProgramatico }}
          </p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Home',
  async mounted () {
    await this.loadTreinamento()
  },
  components: {
    Header,
    Footer
  },
  methods: {
    async loadTreinamento () {
      const resp = await ApiContent.get(
        `treinamentos/${this.$route.params.id}.json`
      )
      this.treinamento = resp.data
      this.treinamento.detalhes = this.treinamento.detalhes.replace(
        '\r\n\r\n',
        ''
      )
      console.log(resp.data)
    }
  },
  data () {
    return {
      bannermercado: '',
      categoria: '',
      treinamento: [],
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
.bar {
  margin-top: 5px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
