var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('Header'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mt-4 text-left"},[_c('h1',{staticClass:"ml-0 pl-0 mt-2 font-weight-bold"},[_vm._v(" "+_vm._s(this.heroTopTitulo)+" ")]),_c('div',{staticClass:"bar w-100"})])]),_c('Destaque',{attrs:{"data":_vm.destaque,"buttonless":""}}),_c('div',{staticClass:"text-left"},[_c('p',{staticClass:"modalignition p-0 m-0 text-muted",attrs:{"data-toggle":"modal","data-target":"#staticBackdrop"}},[_vm._v(" "+_vm._s(_vm.bottomherobuttontext)+" ")])]),_c('div',{staticClass:"my-5 row d-flex justify-content-between"},[_c('div',{staticClass:"col-4",on:{"click":function($event){return _vm.redirect('como-realizar-amostra')}}},[_vm._m(0)]),_c('div',{staticClass:"col-4",on:{"click":function($event){return _vm.redirect('liquido-lubrificante')}}},[_vm._m(1)]),_c('div',{staticClass:"col-4",on:{"click":function($event){return _vm.redirect('oleo-diesel')}}},[_vm._m(2)])])],1),_vm._m(3),_vm._m(4),_c('Footer'),_c('div',{staticClass:"modal fade",attrs:{"id":"staticBackdrop","data-backdrop":"static","data-keyboard":"false","tabindex":"-1","aria-labelledby":"staticBackdropLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_vm._m(5),_c('div',{staticClass:"modal-body text-left"},[_c('p',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.heroBottomTexto)+" ")])]),_vm._m(6)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"w-100 py-4 px-4"},[_c('p',{staticClass:"p-0 m-0"},[_vm._v("Veja como realizar a sua mostra")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"w-100 py-4 px-4"},[_c('p',{staticClass:"p-0 m-0"},[_vm._v("Analise de óleo de lubrificante")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"w-100 py-4 px-4"},[_c('p',{staticClass:"p-0 m-0"},[_vm._v("Análise de diesel")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5 mb-0 pb-0",staticStyle:{"background-color":"#ffcb11"}},[_c('div',{staticClass:"col py-5 mb-0 pb-0"},[_c('a',{staticClass:"text-dark",attrs:{"href":"https://soswebmc.cat.com/cat-sos/dashboard?locale=pt_br"}},[_c('h2',[_vm._v("FAÇA AQUI A GESTÃO DE SUAS AMOSTRAS")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5 m-0 p-0",staticStyle:{"background-color":"#f2f2f2"}},[_c('div',{staticClass:"col py-5 mb-0 pb-0"},[_c('h2',[_vm._v("Precisa de outros ensaios? Fale conosco!")]),_c('h5',[_vm._v("vendas.sos@sotreq.com.br | 0800 940 1920")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"staticBackdropLabel"}},[_vm._v(" Análise de líquido de arrefecimento ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Fechar ")])])
}]

export { render, staticRenderFns }