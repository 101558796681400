          <template>
                <div class="row row-cols-1 row-cols-sm-3 cols-md-3 g-2  mx-0">
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Perfuração</p>
                 <img src="images/perfuração.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Mineração</p>
                 <img src="images/mineração.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Construção</p>
                 <img src="images/construcao.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Ferroviário</p>
                 <img src="images/ferroviario.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Agricultura</p>
                 <img src="images/agricultura.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Florestal</p>
                 <img src="images/florestal.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Descarte e reciclagem</p>
                 <img src="images/descarte-e-reciclagem.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Combate a incêndio</p>
                 <img src="images/combate-a-incendio.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Manuseio de materiais</p>
                 <img src="images/manuseio-de-materiais.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Construção leve</p>
                 <img src="images/construcao-leve.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
    <div class="col snip-a pl-0">
        <div class="card h-100  rounded-0 button_slide slide_right">
            <div class="card-body h-100 d-flex flex-row justify-content-between align-items-between">
                 <p class="solution-title">Industrial</p>
                 <img src="images/industrial.png" alt="Perfuração" class="solution-icon">
            </div>
        </div>
    </div>
</div>

 
          </template>
    <script>
    export default {
        name: 'Fancybuttons',
        props: {
            data: {
            type: Array,
            required: true
            },
            onlytitle: {
            type: Boolean,
            default: () => {
                return false
            }
            },
            titleless: {
            type: Boolean,
            default: () => {
                return false
            }
            }
        }   
    }
    </script>
<style scoped>
 .button_slide { 
    background-color: #eaeaea; 
    color: #000; 
    border-left: 10px solid #ffcd11; 
    border-radius: 0; 
    display: inline-block; 
    font-family: unset; 
    font-size: 16px; 
    box-shadow: inset 0 0 0 0 #ffcd11; 
    width: 100%;
  } 

 

  .card-body { 
    flex: 1 1 auto; 
  
  } 

.card-body.h-100.d-flex.flex-row.justify-content-between.align-items-between {
    padding: 0.35rem 1.5rem 0.35rem 1.8rem;
}


</style>      