<template>
  <div class="home">
    <Header />

    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ categoria.nome }}
          </h1>
        </div>
      </div>
      <div class="mt-5 row">
        <div class="col">
          <CardsTreinamentos :data="treinamentos" />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CardsTreinamentos from '@/components/CardsTreinamentos.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Home',
  async mounted () {
    await this.loadCategories()
    await this.loadTreinamentos()
  },
  components: {
    Header,
    CardsTreinamentos,
    Footer
  },
  methods: {
    async loadCategories () {
      console.log(this.$route.params.id)
      const resp = await ApiContent.get('categoria_treinamentos.json')
      resp.data.map((s) => {
        if (s.id === parseInt(this.$route.params.id)) {
          this.categoria = s
        }
      })
      console.log(resp.data)
    },
    async loadTreinamentos () {
      const resp = await ApiContent.get('treinamentos.json')
      this.treinamentos = resp.data.filter((s) => {
        return s.categoria_treinamento_id === parseInt(this.$route.params.id)
      })
      console.log(resp.data)
    }
  },
  data () {
    return {
      bannermercado: '',
      categoria: '',
      treinamentos: [],
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
