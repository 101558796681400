<template>
  <div>
    <Header />
      <div class="loader py-5" v-if="loading">Aguarde...
      
       <div v-if="loading" class="progress-bar-container">
          <div class="progress-bar" :style="{ width: loadProgress + '%' }"></div>
        </div>
      
      </div>
       
    <div class="w-100">
      <div class="herohome">
        <div class="d-block d-sm-block d-md-block d-lg-none">
          <img class="img-fluid" :src="this.heroimg" alt="HeroMercados">
          <div class="container my-5 my-md-0 d-flex align-items-center text-left h-100">
            <div class="text-black text-left">
              <p class="font-weight-bold h1">
                {{ this.longnome }}
              </p>
              <div class="bar"></div>
              <p class="font-weight-bold h1">
                {{ this.callout }}
              </p>
            </div>
          </div>
        </div>
        <div class="d-none d-sm-none d-md-none d-lg-block">
          <div class="hero-overlay">&nbsp;</div>

          <div :style="{ backgroundImage: `url(${this.heroimg})` }" class="hero-img active">
            <div class="container d-flex align-items-center text-left h-100">
              <div class="col-8  text-white text-left">
                <p class="font-weight-bold h1 text-uppercase">
                  {{ this.longnome }}
                </p>
                <div class="bar"></div>
                <p class="font-weight-bold h1 text-uppercase">
                  {{ this.callout }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="yellowbar">&nbsp;</div>
    <div class="greybar"><br><br></div>

    <div class="container">

      <div class="my-3 w-100">
        <p class="h1 text-uppercase font-weight-bold text-center">VISÃO GERAL
        </p>
        <div class="px-5">
          <p class="text-center">{{ this.shortdescription }}</p>
        </div>
      </div>
    </div>

    <div class="w-100 black-bg py-5">
      <div class="container">
        <div id="carouselExampleControlsFoto" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div v-for="(card, i) in total" :key="i" :class="i == 0 ? 'carousel-item active' : 'carousel-item'">
              <div v-if="card.text.includes('yout')" class="embed-responsive embed-responsive-4by3">
                <iframe class="embed-responsive-item" :src="card.text" allowfullscreen></iframe>
              </div>
              <img v-else :src="card.text" class="d-block w-100" alt="Sotreq revendedora Caterpillar">
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleControlsFoto" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControlsFoto" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </a>
        </div>

      </div>
    </div>
    <div class="container">
      <ul class="nav  mb-5 py-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link text-uppercase active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
            aria-controls="pills-home" aria-selected="true">Especificações</a>
        </li>

        <li class="nav-item" role="presentation" v-if="padrao.length > 0">

          <a class="nav-link text-uppercase" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
            aria-controls="pills-profile" aria-selected="false">Equipamento Padrão</a>
        </li>
        <li class="nav-item" role="presentation"  v-if="opcional.length > 0">

          <a class="nav-link text-uppercase" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab"
            aria-controls="pills-contact" aria-selected="false">Equipamento Opcional</a>
        </li>

        <li class="nav-item" role="presentation" v-if="galeriafiles.length > 0 ">

          <a class="nav-link text-uppercase" id="pills-pdf-tab" data-toggle="pill" href="#pills-pdf" role="tab"
            aria-controls="pills-pdf" aria-selected="false">Downloads</a>
        </li>
      </ul>
    </div>
    <div class="container pb-5 mb-5">
      <p class="h1 font-weight-bold text-uppercase text-center mb-4">
        recursos em um instante </p>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div v-for="(card, i) in sorted" :key="i">
            <a class="w-100" data-toggle="collapse" :href="'#collapseExample' + i" role="button" aria-expanded="false"
              aria-controls="'collapseExample' + i">
              <div class="d-flex justify-content-between spec-collapse p-3">
                {{ card.sgroup[0].grupo }}
                <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXR
  vcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVm
  Vyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfM
  SIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDov
  L3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDQ
  1NSA0NTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1NSA0NTU7IiB4bWw6c3
  BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gcG9pbnRzPSI0NTUsMjEyLjUgMjQyLjUsMjEyLjUgM
  jQyLjUsMCAyMTIuNSwwIDIxMi41LDIxMi41IDAsMjEyLjUgMCwyNDIuNSAyMTIuNSwyNDIuNSAy
  MTIuNSw0NTUgMjQyLjUsNDU1IDI0Mi41LDI0Mi41IAoJNDU1LDI0Mi41ICIvPgo8Zz4KPC9nPgo
  8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz
  4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KP
  C9nPgo8Zz4KPC9nPgo8L3N2Zz4=" class="plus-drop">
              </div>
            </a>
            <div v-for="(list, si) in card.sgroup" :key="si" class="collapse" :id="'collapseExample' + i">
              <div class="card card-body flex-md-row row border-0 py-1">
                <p class="col-md-6 text-left"> {{ list.name }}:</p>
                <p class="col-md-6 text-left">{{ list.value }} {{ list.unit }}</p>
              </div>
            </div>
          </div>
          <div class="last-line">&nbsp;</div>
        </div>


          <div class="tab-pane fade show" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div v-for="(card, i) in padrao" :key="i">
              <a class="w-100" data-toggle="collapse" :href="'#collapsePadrao' + i" role="button" aria-expanded="false"
                aria-controls="'collapsePadrao' + i">
                <div class="d-flex justify-content-between spec-collapse p-3">
                  {{ card.padrao.description }}
                  <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXR
    vcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVm
    Vyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfM
    SIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDov
    L3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDQ
    1NSA0NTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1NSA0NTU7IiB4bWw6c3
    BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gcG9pbnRzPSI0NTUsMjEyLjUgMjQyLjUsMjEyLjUgM
    jQyLjUsMCAyMTIuNSwwIDIxMi41LDIxMi41IDAsMjEyLjUgMCwyNDIuNSAyMTIuNSwyNDIuNSAy
    MTIuNSw0NTUgMjQyLjUsNDU1IDI0Mi41LDI0Mi41IAoJNDU1LDI0Mi41ICIvPgo8Zz4KPC9nPgo
    8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz
    4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KP
    C9nPgo8Zz4KPC9nPgo8L3N2Zz4=" class="plus-drop">
                </div>
              </a>
              <div v-for="(list, si) in card.padrao.items" :key="si" class="collapse" :id="'collapsePadrao' + i">
                <div class="card card-body flex-md-row row border-0 py-1">
                               <p class="col-md-12 text-left" v-html="list.description"></p>

                </div>
              </div>
            </div>
            <div class="last-line">&nbsp;</div>
          </div> 
        
                  <div class="tab-pane fade show" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <div v-for="(card, i) in opcional" :key="i">
                <a class="w-100" data-toggle="collapse" :href="'#collapseOpcional' + i" role="button" aria-expanded="false"
                  aria-controls="'collapseOpcional' + i">
                  <div class="d-flex justify-content-between spec-collapse p-3">
                    {{ card.opcional.description }}
                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXR
      vcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVm
      Vyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfM
      SIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDov
      L3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDQ
      1NSA0NTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1NSA0NTU7IiB4bWw6c3
      BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gcG9pbnRzPSI0NTUsMjEyLjUgMjQyLjUsMjEyLjUgM
      jQyLjUsMCAyMTIuNSwwIDIxMi41LDIxMi41IDAsMjEyLjUgMCwyNDIuNSAyMTIuNSwyNDIuNSAy
      MTIuNSw0NTUgMjQyLjUsNDU1IDI0Mi41LDI0Mi41IAoJNDU1LDI0Mi41ICIvPgo8Zz4KPC9nPgo
      8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz
      4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KP
      C9nPgo8Zz4KPC9nPgo8L3N2Zz4=" class="plus-drop">
                  </div>
                </a>
                <div v-for="(list, si) in card.opcional.items" :key="si" class="collapse" :id="'collapseOpcional' + i">
                  <div class="card card-body flex-md-row row border-0 py-1">
                   <!-- <p class="col-md-12 text-left"> {{ list.description }}</p> -->
                                 <p class="col-md-12 text-left" v-html="list.description"></p>

                  </div>
                </div>
              </div>
              <div class="last-line">&nbsp;</div>
            </div>
        <div class="tab-pane fade" id="pills-pdf" role="tabpanel" aria-labelledby="pills-pdf-tab">
          <ul class="list-group text-left">
            <li class="list-group-item" v-for="(file, fi) in galeriafiles" :key="fi">
              <a :href="file.file.url" target="_blank" download>
                {{ file.file.title }}
              </a>

            </li>

          </ul>
        </div>
      </div>

    </div>


        <div class="container">

        <div class="my-3 w-100">
          <p class="h1 text-uppercase font-weight-bold text-center">PRODUTOS RELACIONADOS
          </p>
          <div class="px-5" style="overflow-x: scroll;">
           <div class="container mt-4">
        <div class="card-deck">
            <div v-for="(rela, i) in relacionados" :key="i">

        <div class="card h-100"  style="width: 18em">
          <a href="#"  data-toggle="modal" :data-target="'#exampleModal' + i">
          <img :src="rela.relacionado.image_url" class="card-img-top" alt="...">
          <div class="card-body">
            
     
        </div>
        <div class="card-footer bg-transparent border-0 p-0">
          <h5 class="card-title">{{ rela.relacionado.name }}</h5>
          <!-- Modal -->
          <div class="modal fade" :id="'exampleModal' + i" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title text-dark" id="exampleModalLabel">{{ rela.relacionado.name }}</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div v-for="(list, si) in rela.relacionado.products" :key="si" >
                    <div class="media">
                      <img :src="list.image_url" class="mr-3" alt="...">
                      <div class="media-body">
                        <h5 class="mt-2 pl-3 text-left text-dark">
                          {{ list.name }}
                        </h5>
                      </div>
                    </div>
                    </div>

                </div>

              </div>
            </div>
          </div>



 
          </div>
          </a>
        </div>
</div>
              </div>
    </div>
          </div>
        </div>
      </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Novidade from '@/components/Novidade.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'MaquinaDetalhe',
  data() {
    return {
      loading: false,
      loadProgress: 0,
      heroimg: [],
      longnome: [],
      callout: [],
      shortdescription: [],
      total: [],
      sorted: [],
      padrao: [],
      opcional: [],
      galeriafiles: [],
      relacionados: []
    }
  },
  async mounted() {
    this.startProgress();
    await this.loadCategoria();
  },
  components: {
    Header,
    Footer,
    Novidade
  },
  methods: {
        startProgress() {
      this.loadProgress = 0;
      const interval = setInterval(() => {
        this.loadProgress += 10;
        if (this.loadProgress >= 100) {
          clearInterval(interval);
          this.loadProgress = 0;
        }
      }, 500);
    },
    async loadCategoria() {
      this.loading = true; // activate loading state
      try {
        const resp = await ApiContent.get(`/maquinas/${this.$route.params.id}.json`)
        console.log(resp.data)
        this.heroimg = resp.data?.hero.heroimg  || [];
        this.longnome = resp.data?.hero.longnome  || [];
        this.callout = resp.data?.hero.callout  || [];
        this.shortdescription = resp.data?.shortdescription  || [];
        this.total = resp.data?.total  || [];
        this.sorted = resp.data?.sorted  || [];
        this.padrao = resp.data?.padrao || [];
        this.opcional = resp.data?.opcional  || [];
        this.galeriafiles = resp.data?.galeriafiles  || [];
        this.relacionados = resp.data?.relacionados  || [];
        // this.code = resp.data?.html_content
        // console.log(this.code)
      } catch (error) {
        console.error(error);
        // handle error
      } finally {
        this.loading = false; // deactivate loading state
      }
    }
  },
}

</script>
<style scoped>
.card-hearder {
  color: #000
}

.card-title {
  color: #000
}

.card-text {
  color: #000
}

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.bar {
  margin-top: -8px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}

.traco {
  width: 100px;
  background-color: #ffcb11;
}

.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img {
  max-height: 70vh;
  height: 700px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.herohome {
  margin-top: -80px;
  position: relative;
}

.yellowbar {
  width: 100%;
  height: 12px;
  background-color: #ffcd11;
}

.greybar {
  width: 100%;
  height: 131px;
  background-color: #ececec;

}

.greybar {
  width: 100%;
  height: 100%;
  background-color: #ececec;

}

.black-bg {
  background-color: #000;
}

.separatorbox {
  min-height: 100%;
  border-right: solid 1px #000;
  margin: 0px 1em 0px 1em;
}

.plus-drop {
  width: 1.5em
}

.spec-collapse {
  border-top: solid 0.5px #000;
}

.last-line {
  min-width: 100%;
  border-top: solid 1px #000;
}

a.w-100[aria-expanded='false'] div.d-flex.justify-content-between.spec-collapse.p-3 img.plus-drop {
  transform: rotate(0deg);
  transition: transform 1s;
}

a.w-100[aria-expanded='true'] div.d-flex.justify-content-between.spec-collapse.p-3 img.plus-drop {
  transform: rotate(45deg);
  transition: transform 1s;
}

.hero-overlay {
  height: 100%;
  background-color: #000;
  position: absolute;
  width: 100%;
  opacity: 0.3;
}
.card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row nowrap;
    margin-right: -15px;
    margin-left: -15px;
    min-width: max-content;
}

.card-deck .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
    width: 18em;
    min-width: 18em;
}
img.mr-3 {
    max-width: 80px;
    max-height: 80px;
}

.loader {
 min-width: 100vw;
min-height: 100vh;
position: fixed;
pointer-events: none;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
overflow: hidden;
z-index: 99999999;
background: #fafafa !important;
margin-top: -80px;
}
.progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
}

.progress-bar {
  height: 20px;
  background-color: #4caf50;
  transition: width 0.5s;
}
li a {
    color: #000;
    text-decoration: none;
}
.d-flex.justify-content-between.spec-collapse.p-3 {
    color: #252525;
    text-align: left;
}
li.nav-item {
    border-right: 1px solid #252525;
}
li.nav-item:last-child {
    border: none;
}

@media(max-with: 530px) {
li.nav-item:last-child {
    border: none;
}
}
</style>
