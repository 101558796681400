<template>
<div class="card mb-3">
    <div class="row flex-row-reverse flex-md-row no-gutters">
      <div class="col-md-6">
        <div
          class="card-body h-100 d-flex flex-column justify-content-center align-items-start"
        >
          <h2 class="card-title font-weight-bold text-left">
            <span class="position-relative">
              <h1 class="underline-text font-weight-bold">Motores Industriais Cat</h1>
              <span class="underline"></span>
            </span>
          </h2>
          <p class="card-text pt-1 text-left" style="width: 96%">As soluções tecnológicas dos motores industriais Cat permitem que possam ser aplicados em máquinas de outros fabricantes (OEMs), dentro dos mais diversos segmentos e com entrega de potência para o máximo desempenho. São a escolha ideal para quem busca potência, confiabilidade e durabilidade. Com uma ampla gama de modelos disponíveis, desde 8kW a 1566kW, a Cat oferece a solução perfeita para qualquer aplicação.</p>
          <a type="button" href="https://sotreq.com.br/#/motores/industrial" class="btn  mt-4 btn-lg btn-preto">Conheça os motores</a>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <img src="../../assets/motindcat.png" class="card-img cardwide" alt="..." />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WideCard",
};
</script>

<style scoped>
.btn-preto {
    border: 2px solid white;
    background-color: #252525;
    color: white;
}
.btn-preto:hover {
    color: #fff;
    background-color: #595959;
    border-color: #595959;
}
.underline-text {
  position: relative;
  z-index: 1;
}

.underline {
    margin-top: -5px;
        display: flex;
  height: 8px;
  width: 20%;
  background-color: #ffcb11;
  z-index: -1;
}
.cardwide {
  height: 380px;
    width: auto;
}
@media (max-width: 767.98px) {
  .row {
    flex-direction: column-reverse;
  }
  
.cardwide {
  width: 370px;
  height: auto;
}
}
</style>
