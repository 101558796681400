<template>
  <div class="mb-5 herohome">
    <div class="d-block d-sm-block d-md-block d-lg-none">
      <div
        id="carouselExampleIndicatorsmob"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicatorsmob"
            data-slide-to="0"
            class="nottraco active"
          ></li>
          <li
            class="nottraco"
            v-for="(banner, i) in data.filter((s, ind) => {
              return ind > 0;
            })"
            :key="i"
            data-target="#carouselExampleIndicatorsmob"
            :data-slide-to="i + 1"
          ></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col text-left pb-5">
                                 <div class="card-img-top maquina-card-img" :style="'background-image: url('+ data[0].image +');'">
      &nbsp;
    </div>
                <!--
                <img
                  class="img-fluid"
                  :src="data[0].image"
                  alt="slide"
                /> -->
                <div class="container">
                  <h1 class="font-weight-bold mt-5">
                    {{ data[0].titulo }}
                  </h1>
                  <p>
                    {{ data[0].subtitulo }}
                  </p>
                  <a v-if="data[0].buttonText" :href="data[0].link">
                  <button type="button" class="px-5 btn mt-3 mb-3 btn-secondary">
                    {{ data[0].buttonText }}
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(banner, i) in data.filter((s, ind) => {
              return ind > 0;
            })"
            :key="i"
            class="carousel-item"
          >
            <div class="row">
              <div class="col text-left pb-5">
                <!--
                <img
                  class="img-fluid"
                  :src="banner.image"
                  alt="slide"
                /> -->
                 <div class="card-img-top maquina-card-img" :style="'background-image: url('+ banner.image +');'">
      &nbsp;
    </div>
                <div class="container">
                  <h1 class="font-weight-bold mt-5">
                    {{ banner.titulo }}
                  </h1>
                  <p>
                    {{ banner.subtitulo }}
                  </p>

                  <a v-if="banner.buttonText" :href="banner.link">
                  <button type="button" class="px-5 btn mt-3 mb-3 btn-secondary">
                    {{ banner.buttonText }}
                  </button>
                  </a>
                <!--   <div v-else class="" style="min-height: 150px">&nbsp;</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-md-none d-lg-block d-xl-block">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="traco active"
            :key="0"
          ></li>
          <li
            class="traco"
            v-for="(banner, i) in data.filter((s, ind) => {
              return ind > 0;
            })"
            :key="i"
            data-target="#carouselExampleIndicators"
            :data-slide-to="i + 1"
          ></li>
        </ol>
        <div class="carousel-inner">
          <div
            :style="
              'background-image: url(' +
              data[0].image +
              ');'
            "
            class="carousel-item hero-img active"
          >
            <div class="h-100 d-flex container align-items-center">
              <div class="text-white row mt-5">
                <div class="col-7 text-left">
                  <h1 class="font-weight-bold display-4">
                    {{ data[0].titulo }}
                  </h1>
                  <h4>
                    {{ data[0].subtitulo }}
                  </h4>
                  <a v-if="data[0].buttonText" :href="data[0].link">
                  <button type="button" class="px-5 btn mt-5 btn-secondary">
                   {{ data[0].buttonText }}
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(banner, i) in data.filter((s, ind) => {
              return ind > 0;
            })"
            :key="i"
            :style="
              'background-image: url(' +
              banner.image +
              ');'
            "
            class="carousel-item hero-img"
          >
            <div class="h-100 d-flex container align-items-center">
              <div class="text-white row mt-5">
                <div class="col-7 text-left">
                  <h1 class="font-weight-bold display-4">
                    {{ banner.titulo }}
                  </h1>
                  <h4>
                    {{ banner.subtitulo }}
                  </h4>
                  <a v-if="banner.buttonText" :href="banner.link">
                  <button type="button" class="px-5 btn mt-5 btn-secondary">
                    {{ banner.buttonText }}
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <a
          class="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a> -->
      </div>
    </div>

    <div style="background-color: black">
      <p class="text-white py-3">
        Sotreq+ capitais  <a class="notalink" href="tel:30031920"> 11 3003 1920</a> | outras localidades <a class="notalink" href="tel:08009401920"> 0800 940 1920 </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
/* The hero image */
/* .carousel-indicators > li {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #222;
} */
button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.nottraco {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #222;
}

.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img { 
  height: calc(100vh - 80px) !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.herohome {
  margin-top: -80px;
}
@media (max-width: 538px) {
  .herohome {
    margin-top: 50px ;
  }
  .card-img-top.maquina-card-img {
    height: 250px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
}
</style>
