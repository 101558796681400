<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container p-3 my-4">
      <div class="mb-3" v-html="this.descricao"></div>
      <div class="mb-3" v-if="this.pdf">
        <a :href="this.pdf" download> Faça o download do catálogo </a>
      </div>

      <!-- Nav tabs -->
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="caracteristicas-tab"
            data-toggle="tab"
            href="#caracteristicas"
            role="tab"
            aria-controls="caracteristicas"
            aria-selected="true"
            >Caracteristicas</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="especificacoes-tab"
            data-toggle="tab"
            href="#especificacoes"
            role="tab"
            aria-controls="especificacoes"
            aria-selected="false"
            >Especificações</a
          >
        </li>
        <!--
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="videos-tab" data-toggle="tab" href="#videos" role="tab" aria-controls="videos" aria-selected="false">Videos</a>
      </li> -->
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div
          class="tab-pane active"
          id="caracteristicas"
          role="tabpanel"
          aria-labelledby="caracteristicas-tab"
        >
          <div v-if="this.caracteristicas.length > 1">
            <div v-html="this.caracteristicas"></div>
          </div>
          <div v-else></div>
        </div>
        <div
          class="tab-pane"
          id="especificacoes"
          role="tabpanel"
          aria-labelledby="especificacoes-tab"
        >
          <div v-if="this.especificacoes.length > 1">
            <div v-html="this.especificacoes"></div>
          </div>
          <div v-else></div>
        </div>
       <!-- <div
          class="tab-pane"
          id="videos"
          role="tabpanel"
          aria-labelledby="videos-tab"
        >
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              :src="this.video0"
              allowfullscreen
            ></iframe>
          </div>
          <br />
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              :src="this.video0"
              allowfullscreen
            ></iframe>
          </div>
        </div> -->
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import Novidade from '@/components/Novidade.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'AcessorioDetalhe',
  async mounted () {
    this.loadCategoria()
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    Novidade
  },
  methods: {
    async loadCategoria () {
      const resp = await ApiContent.get(
        `acessorios/${this.$route.params.id}.json`
      )
      console.log(resp.data)
      this.nome = resp.data.nome
      this.descricao = resp.data.descricao
      this.caracteristicas = resp.data.caracteristicas
      this.especificacoes = resp.data.especificacoes
      this.video0 = resp.data.video0
      this.video1 = resp.data.video1
      this.pdf = resp.data.pdf
      this.bannermercado = {
        titulo: resp.data.nome,
        image: resp.data.image,
        subtitulo: ''
      }
    }
  },
  data () {
    return {
      nome: '',
      descricao: '',
      caracteristicas: '',
      especificacoes: '',
      video0: '',
      video1: '',
      pdf: ''
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
div.tab-content div#caracteristicas.tab-pane.active div div div .col-sm-12 {
  padding: 0px !important;
}
button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img { max-height: 70vh; 
  height: 700px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.herohome {
  margin-top: -80px;
}
.yellowbar {
  width: 100%;
  height: 12px;
  background-color: #ffcd11;
}
.greybar {
  width: 100%;
  height: 131px;
  background-color: #ececec;
}
.greybar {
  width: 100%;
  height: 100%;
  background-color: #ececec;
}
.black-bg {
  background-color: #000;
}
.separatorbox {
  min-height: 100%;
  border-right: solid 1px #000;
  margin: 0px 1em 0px 1em;
}
.plus-drop {
  width: 1.5em;
}
.spec-collapse {
  border-top: solid 0.5px #000;
}

.last-line {
  min-width: 100%;
  border-top: solid 1px #000;
}
a.w-100[aria-expanded="false"]
  div.d-flex.justify-content-between.spec-collapse.p-3
  img.plus-drop {
  transform: rotate(0deg);
  transition: transform 1s;
}
a.w-100[aria-expanded="true"]
  div.d-flex.justify-content-between.spec-collapse.p-3
  img.plus-drop {
  transform: rotate(45deg);
  transition: transform 1s;
}
.position-absolute.w-100.h-100.preloader {
  background-color: #fff;
  z-index: 99999;
  position: absolute;
  margin: auto;
  padding-top: 35vh;
}

</style>
