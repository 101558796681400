var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5 herohome"},[_c('div',{staticClass:"d-block d-sm-block d-md-block d-lg-none"},[_c('div',{staticClass:"carousel slide",attrs:{"id":"carouselExampleIndicatorsmob","data-ride":"carousel"}},[_c('ol',{staticClass:"carousel-indicators"},[_c('li',{staticClass:"nottraco active",attrs:{"data-target":"#carouselExampleIndicatorsmob","data-slide-to":"0"}}),_vm._l((_vm.data.filter((s, ind) => {
            return ind > 0;
          })),function(banner,i){return _c('li',{key:i,staticClass:"nottraco",attrs:{"data-target":"#carouselExampleIndicatorsmob","data-slide-to":i + 1}})})],2),_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-left pb-5"},[_c('div',{staticClass:"card-img-top maquina-card-img",style:('background-image: url('+ _vm.data[0].image +');')},[_vm._v("   ")]),_c('div',{staticClass:"container"},[_c('h1',{staticClass:"font-weight-bold mt-5"},[_vm._v(" "+_vm._s(_vm.data[0].titulo)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.data[0].subtitulo)+" ")]),(_vm.data[0].buttonText)?_c('a',{attrs:{"href":_vm.data[0].link}},[_c('button',{staticClass:"px-5 btn mt-3 mb-3 btn-secondary",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.data[0].buttonText)+" ")])]):_vm._e()])])])]),_vm._l((_vm.data.filter((s, ind) => {
            return ind > 0;
          })),function(banner,i){return _c('div',{key:i,staticClass:"carousel-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-left pb-5"},[_c('div',{staticClass:"card-img-top maquina-card-img",style:('background-image: url('+ banner.image +');')},[_vm._v("   ")]),_c('div',{staticClass:"container"},[_c('h1',{staticClass:"font-weight-bold mt-5"},[_vm._v(" "+_vm._s(banner.titulo)+" ")]),_c('p',[_vm._v(" "+_vm._s(banner.subtitulo)+" ")]),(banner.buttonText)?_c('a',{attrs:{"href":banner.link}},[_c('button',{staticClass:"px-5 btn mt-3 mb-3 btn-secondary",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(banner.buttonText)+" ")])]):_vm._e()])])])])})],2)])]),_c('div',{staticClass:"d-none d-md-none d-lg-block d-xl-block"},[_c('div',{staticClass:"carousel slide",attrs:{"id":"carouselExampleIndicators","data-ride":"carousel"}},[_c('ol',{staticClass:"carousel-indicators"},[_c('li',{key:0,staticClass:"traco active",attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"0"}}),_vm._l((_vm.data.filter((s, ind) => {
            return ind > 0;
          })),function(banner,i){return _c('li',{key:i,staticClass:"traco",attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":i + 1}})})],2),_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item hero-img active",style:('background-image: url(' +
            _vm.data[0].image +
            ');')},[_c('div',{staticClass:"h-100 d-flex container align-items-center"},[_c('div',{staticClass:"text-white row mt-5"},[_c('div',{staticClass:"col-7 text-left"},[_c('h1',{staticClass:"font-weight-bold display-4"},[_vm._v(" "+_vm._s(_vm.data[0].titulo)+" ")]),_c('h4',[_vm._v(" "+_vm._s(_vm.data[0].subtitulo)+" ")]),(_vm.data[0].buttonText)?_c('a',{attrs:{"href":_vm.data[0].link}},[_c('button',{staticClass:"px-5 btn mt-5 btn-secondary",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.data[0].buttonText)+" ")])]):_vm._e()])])])]),_vm._l((_vm.data.filter((s, ind) => {
            return ind > 0;
          })),function(banner,i){return _c('div',{key:i,staticClass:"carousel-item hero-img",style:('background-image: url(' +
            banner.image +
            ');')},[_c('div',{staticClass:"h-100 d-flex container align-items-center"},[_c('div',{staticClass:"text-white row mt-5"},[_c('div',{staticClass:"col-7 text-left"},[_c('h1',{staticClass:"font-weight-bold display-4"},[_vm._v(" "+_vm._s(banner.titulo)+" ")]),_c('h4',[_vm._v(" "+_vm._s(banner.subtitulo)+" ")]),(banner.buttonText)?_c('a',{attrs:{"href":banner.link}},[_c('button',{staticClass:"px-5 btn mt-5 btn-secondary",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(banner.buttonText)+" ")])]):_vm._e()])])])])})],2)])]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"black"}},[_c('p',{staticClass:"text-white py-3"},[_vm._v(" Sotreq+ capitais "),_c('a',{staticClass:"notalink",attrs:{"href":"tel:30031920"}},[_vm._v(" 11 3003 1920")]),_vm._v(" | outras localidades "),_c('a',{staticClass:"notalink",attrs:{"href":"tel:08009401920"}},[_vm._v(" 0800 940 1920 ")])])])
}]

export { render, staticRenderFns }