<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" :buttonless='true' />

    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
    <h1 class="ml-0 pl-0 mt-2 font-weight-bold text-yellow text-center text-md-left">
    {{ this.destaqueTitulo }}
</h1>
    <p style="white-space: pre-line" class="mt-3 text-center text-md-left bigp">
    {{ this.destaqueTexto }}
</p>
    </div>
    </div>

    <div class="row px-5 px-md-0">
    <div class="col">
    <div class="d-none d-md-block">
    <CardFale :data="cards" />
    </div>
    <div class="d-sm-block  d-md-none" >
    <BotaoCardSobrenos :data="cards" />
    </div>
    </div>
    </div>
    <div class="row separator px-5 px-md-0">    </div>

<div class="row mt-4">
    <div class="col-12 col-md-3  text-left">
    <h2 class="font-weight-bold">Outras opções</h2>
    <div class="bar mb-3"></div>
    <div class="py-4 d-grid">
<!--    <a class="btn btn-dark text-white" href="#" > Seja um fornecedor</a> -->
    <a class="btn btn-dark text-white mt-4"
href="/#/servicos/treinamentos-tecnicos" >
    Cursos e treinamentos
</a>

</div>

</div>

    <div class="col-12 col-md-8 offset-md-1 text-left">
    <h2 class="font-weight-bold">Atendimento</h2>
    <div class="bar mb-3"></div>
    <h4 class="mb-3 font-weight-bold">Atendimento telefônico, WhatsApp e Telegram</h4>

    <div class="py-2">
    <p class="bigp"> 
      <a class="notalink" href="https://wa.me/551130031920" target="_blank"><i class="fab fa-whatsapp" aria-hidden="true"></i></a> &nbsp; 
      <a class="notalink" href="https://t.me/SotreqBot" target="_blank"><i class="fab fa-telegram" aria-hidden="true"></i></a> &nbsp; 
      <a class="notalink" href="tel:30031920" target="_blank">  11 3003 1920 - Capitais e regiões metropolitanas </a></p>
    <p class="bigp"> <a class="notalink" href="tel:08009401920" target="_blank"> 0800 940 1920 - Demais Localidades </a></p>
    </div>

    </div>
    </div>


    </div>
    <Footer :buttonless="true" />
    </div>
    </template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import CardFale from '@/components/CardFale.vue'
import BotaoCardSobrenos from '@/components/BotaoCardSobrenos.vue'

export default {
  name: 'FaleConosco',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    CardFale,
    BotaoCardSobrenos
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/178.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.conteudo = resp.data.conteudo
      this.cards = resp.data.cards
      this.destaques = resp.data.destaques
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: ''
      }
      this.cards.map(card => {
        card.link = card.link
      })
    }
  },
  data () {
    return {
      destaques: [],
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      conteudo: []
    }
  }
}
</script>
    <style scoped>
    .quadrado {
        border: 5px solid #ffcb11;
    }
    .equipamento_nvo {
        background: rgb(2, 0, 36);
        background: radial-gradient(
            circle,
            rgba(2, 0, 36, 1) 0%,
            rgba(214, 155, 31, 1) 50%
        );
    }
a {
    text-decoration: none;
    color: black;
}
button {
    border: 2px solid white;
    background-color: black;
    color: white;
}

button:hover {
    border: 2px solid black;
    background-color: white;
    color: black;
}
    .d-grid {
        display:grid;
    }
     .bigp {
font-size: 20px !important;

     }
     @media(max-width: 758px){
        .text-black.text-left {
            visibility: hidden;
            display: none;
            position: absolute;
        }
}
</style>
