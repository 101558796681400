<template>
    <div class="home">
      <Header />
      <HeroMercados :data="bannermercado" />
          <div class="sticky-top">
        <div class="bg-sotreq w-100 py-3 text-right">
          <div class="container">
            <a :href="this.heroTopLink" class="notalink" target="_blank">
                   <h4>{{ this.heroTopButtonText }}  <i class="fa fa-arrow-right"></i></h4>
                   </a>
                   </div>
  
        </div>
      </div>
      <div class="container">
            <div v-if="this.conteudo.length > 1">
          <div class="resetforcontent">
          <div  v-html="this.conteudo"></div>
          </div>
          </div>
  </div>
  
  <div class="container-fluid w-100 bg-light pt-5 mt-5">
    <div class="container">
      <h2 class="text-center mb-5">Nossos Valores</h2>
        <IconCardLine :data="this.heroes" />
        </div>
        </div>
    <div class="container">
  
        <h2 class="text-center my-5">Programas</h2>
        <AlternatingHcard :data="this.cards" />
              <h2 class="text-center my-5">Responsabilidade Social</h2>
  
      <CardSimple :data="this.destaques" />
      
            <!--
                  <div class="d-flex justify-content-start mt-5">
       <a :href="textoBaseTexto"
              ><button type="button" class="btn btn-lg">
                {{ this.textoBaseTitulo }}
              </button></a
            >
            </div>
      <CardsFiltragem  :data="this.cards" />
  
        <div class="row">
          <div class="col mt-4 text-left">
            <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
              {{ this.destaqueTitulo }}
            </h1>
            <p style="white-space: pre-line" class="mt-3">
              {{ this.destaqueTexto }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mx-auto">
            <img :src="bottomHeroImage" class="img-fluid mb-5" />
            <a :href="heroBottomLink" 
              ><button type="button" class="btn btn-lg mt-5">
                {{ this.bottomherobuttontext }}
              </button></a
            >
          </div>
        </div>
        -->
      </div>
      <br>
      <br>
      <Footer />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Header from "@/components/Header.vue";
  import HeroMercados from "@/components/Mercados/HeroCarreiras.vue";
  import LineTextBlock from "@/components/shared/LineTextBlock.vue";
  import AlternatingHcard from "@/components/shared/AlternatingHcard";
  import IconCardLine from "@/components/shared/IconCardLine";
  import CardsFiltragem from "@/components/CardsFiltragem.vue";
  import CardSimple from "@/components/shared/CardSimple.vue"
  import Footer from "@/components/Footer.vue";
  import ApiContent from "@/services/api.js";
  
  export default {
    name: "Carreiras",
    mounted() {
      this.loadContent();
    },
    components: {
      Header,
      HeroMercados,
      LineTextBlock,
      AlternatingHcard,
      CardsFiltragem,
      CardSimple,
      IconCardLine,
      Footer,
    },
    methods: {
      async loadContent() {
        const resp = await ApiContent.get("pgcarreiras.json");
        console.log(resp.data);
        this.heroTopTitulo = resp.data.heroTopTitulo;
        this.heroTopTexto = resp.data.heroTopTexto;
        this.heroTopLink = resp.data.heroTopLink;
        this.heroTopButtonText = resp.data.topherobuttontext;
        this.destaqueTitulo = resp.data.destaqueTitulo;
        this.destaqueTexto = resp.data.destaqueTexto;
        this.destaqueChamada = resp.data.destaqueChamada;
        this.textoBaseTitulo = resp.data.textoBaseTitulo;
        this.textoBaseTexto = resp.data.textoBaseTexto;
        this.heroBottomTitulo = resp.data.heroBottomTitulo;
        this.heroBottomTexto = resp.data.heroBottomTexto;
        this.heroBottomLink = resp.data.heroBottomLink;
        this.bottomherobuttontext = resp.data.bottomherobuttontext;
        this.heroBottomLink = resp.data.heroBottomLink;
        this.url = resp.data.url;
        this.topHeroImage = resp.data.topHeroImage;
        this.logo = resp.data.logo;
        this.bottomHeroImage = resp.data.bottomHeroImage;
        this.heroes = resp.data.heroes;
        this.cards = resp.data.cards;
        this.destaques = resp.data.destaques;
        this.conteudo = resp.data.content;
        this.bannermercado = {
          titulo: resp.data.heroTopTitulo,
          image: resp.data.topHeroImage,
          subtitulo: "",
        };
      },
    },
    data() {
      return {
        heroTopTitulo: [],
        heroTopTexto: [],
        heroTopLink: [],
        topherobuttontext: [],
        destaqueTitulo: [],
        destaqueTexto: [],
        destaqueChamada: [],
        textoBaseTitulo: [],
        textoBaseTexto: [],
        heroBottomTitulo: [],
        heroBottomTexto: [],
        heroBottomLink: [],
        created_at: [],
        updated_at: [],
        url: [],
        topHeroImage: [],
        logo: [],
        bottomHeroImage: [],
        cards: [],
        destaques: [],
        conteudo: [],
        heroes: [],
      };
    },
  };
  </script>
  <style scoped>
  .quadrado {
    border: 5px solid #ffcb11;
  }
  .equipamento_nvo {
    background: rgb(2, 0, 36);
    background: radial-gradient(
      circle,
      rgba(2, 0, 36, 1) 0%,
      rgba(214, 155, 31, 1) 50%
    );
  }
  .bg-sotreq {
    background-color: rgb(255, 206, 17 );
  }
  a {
    text-decoration: none;
  }
  button {
    border: 2px solid white;
    background-color: black;
    color: white;
  }
  
  button:hover {
    border: 2px solid black;
    background-color: white;
    color: black;
  }
  </style>
  