<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="row">
        <div class="col mt-4 justify-content-start text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.destaqueTitulo }}
          </h1>

          <img
            class="my-4 img-fluid"
            :src="bottomHeroImage"
            alt="Sotreq iSSO"
          />

          <p style="white-space: pre-line" class="mt-3">
            {{ this.destaqueTexto }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col text-left">
          <a
            v-for="(link, index) in cards"
            :key="index"
            class=""
            :href="link.link"
            ><button
              cla
              type="button"
              class="text-uppercase my-4 btn btn-lg btn-block"
            >
              {{ link.buttontext }}
            </button></a
          >
        </div>
      </div>
      <CollapseSustentabilidade :data="this.relatorios" />
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import CollapseSustentabilidade from './CollapseSustentabilidade.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Home',
  mounted () {
    this.loadContent(),
    this.loadReports()
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    CollapseSustentabilidade
  },
  methods: {
  async loadReports () {
    const reports = await ApiContent.get('reports.json')
    console.log("reports")
    console.log(reports.data)
    this.relatorios = reports.data
  },
    async loadContent () {
      const resp = await ApiContent.get('paginas/101.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.destaques = resp.data.destaques
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      relatorios: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
