<template>
  <div class="herohome">
    <div class="d-block d-sm-block d-md-block d-lg-none">
    <img class="img-fluid" :src="data.image" alt="HeroMercados">
      <div class="container px-0 mt-3  my-md-0 d-flex align-items-center text-left h-100">
          <div class="col  text-black text-left">
            <h1  class="font-weight-bold">
              {{ data.titulo }}
            </h1>
            <div class="bar mt-0"></div>
          </div>
        </div>
    </div>
    <div class="d-none d-sm-none d-md-none d-lg-block">
    <div
      :style="
        'background-image: url(' + data.image + ');'
      "
      class="hero-img active"
    >
      <div class="container d-flex align-items-center text-left h-100">
          <div class="col  text-white text-left">
            <h1 class="fw-700 display-4  text-capitalize">
              {{ data.titulo }}
            </h1>
            <div class="bar mt-1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroMercado',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
/* The hero image */

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img { max-height: 70vh; 
  height: 700px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.herohome {
  margin-top: -80px;
}
</style>
