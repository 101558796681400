<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            Filtros Cat®
          </h1>
          <p style="white-space: pre-line" class="mt-3">
        A chave para prolongar a vida útil dos componentes hidráulicos da sua máquina é reduzir a contaminação. A escolha do Filtro Cat® correto é fundamental para minimizar danos aos componentes causados por abrasivos. Você deve consultar o seu consultor de Suporte ao Produto para determinar quais Filtros Hidráulicos e de Trem de Força Cat® estão disponíveis para atender às necessidades específicas de limpeza das suas aplicações.
          </p>
        </div>
      </div>

       <div class="row">
        <div class="col-md-7">
          <LineTextBlock :data="this.destaques" />
        </div>
        <div class="col-md-5">
          <img
            :src="bottomHeroImage"
            class="img-fluid"
            alt="Sotreq Retiraq Locker"
          />
        </div>
                <div class="col-12">
<h2 class="font-weight-bold text-center">
Aumente a vida útil de sua máquina com filtros de alta qualidade
</h2>
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          :src="destaqueChamada"
          title="Filros Cat"
         class="embed-responsive-item"
          allowfullscreen
        ></iframe>
      </div>
      </div>
</div>
 <div class="row my-5">
        <div class="col text-left">
          <h2 class="font-weight-bold">Eficiência garantida!</h2>
          <div class="bar mb-5"></div>
          <p class="preline">É comprovado: utilizar filtros Cat reduz danos aos componentes causados por abrasivos. Nosso projeto avançado apresenta máxima eficiência de filtragem e protege o motor e outros componentes fundamentais do sistema da sua máquina.</p>
          <p class="font-weight-bold">
          Com os filtros Cat, você conta com:
          </p>
          <ul>
         <li>Maior eficiência de filtragem</li>
         <li>Maior vida útil para os sistemas</li>
         <li>Confiabilidade comprovada</li>
         <li>Garantia de 12 meses </li>

          </ul>

        <h4 class="font-weight-bold">
O resultado é maior produtividade e alto desempenho para o seu equipamento e a sua operação.

        </h4>
        </div>
      </div>
      <div class="row my-5">
        <div class="col text-left">
          <h2 class="font-weight-bold">Informações sobre garantia</h2>
          <div class="bar mb-5"></div>

          <p class="font-weight-bold">
         Geralmente, a garantia para peças compradas em Parts.Cat.Com é de 12 meses a partir da data da fatura, conforme definido na Garantia Limitada da Caterpillar. A Garantia das Peças abrange defeitos no material e de fabricação durante todo o período de Garantia uma vez instalada. Para obter todos os detalhes sobre a Garantia, entre em contato com a Sotreq.
          </p>
        </div>
      </div>
    </div>
    <br>
    <Compra bgcolor="#f5f5f5" />
    <h2 class="text-center font-weight-bold my-5">
      FAÇA A ESCOLHA CERTA E ENTRE <br /> EM CONTATO COM A SOTREQ
    </h2>
    <h5 class="text-center footer-closer">
      Preencha os formulário abaixo e entraremos em contato com você.
    </h5>
    <Footer bgcolor="#f5f5f5" :buttonless="true"  :hasform="true"  gtmterm="pagina_filtros"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Compra from '@/components/Compra.vue'
import LineTextBlock from '@/components/shared/LineTextBlock.vue'


export default {
  name: 'Home',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    Compra,
    LineTextBlock

  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/50.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.destaques = resp.data.destaques
      this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem
        return s
      })
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      destaques: []
    }
  }
}
</script>
<style scoped>
.footercloser {
  margin-bottom: -35px
}
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
