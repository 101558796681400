<!-- eslint-disable no-mixed-spaces-and-tabs -->
<!-- eslint-disable no-tabs -->
<template>
  <div class="footer-gray">
    <div v-if="!buttonless">
      <div class="mx-auto py-5"   v-bind:style="{backgroundColor: bgChosencolor}" >
        <a href="#/contato"
          ><button type="button" class="btn btn-lg btn-black">
            Envie sua mensagem
          </button></a
        >
      </div>
    </div>
    <div v-else-if="hasform">
      <div class="mx-auto mt-5 container">
	<div class="row">
	  <div class="col-12 col-md-2">&nbsp;</div>
	  <div class="col-12 col-md-8">
	 <form class="mt-4" style="margin-bottom: 3vmax">
            <div class="form-group">
              <input
                v-model="NOME"
                placeholder="NOME *"
                type="text"
                class="inputnormal rounded-lg form-control"
              />
            </div>
            <div class="form-group">
              <input
                v-model="EMAIL"
                placeholder="E-MAIL *"
                type="text"
                class="inputnormal rounded-lg form-control"
              />
            </div>
            <div class="form-group">
              <input
                v-model="TELEFONE"
                placeholder="TELEFONE *"
                v-mask="['(##) ####-####', '(##) #####-####']"
                type="tel"
                class="inputnormal rounded-lg form-control"
              />
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <vSelect
                    class="style-chooser"
                    @input="handlecidades"
                    placeholder="ESTADO *"
                    :options="estados"
                    label="sigla"
                    v-model="estadochosen"
                  />
                </div>
                <div class="d-block d-sm-block d-md-block d-lg-none">
                  <div style="height: 4em">&nbsp;</div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <vSelect
                    class="style-chooser"
                    placeholder="CIDADE *"
                    :options="cidades"
                    label="sigla"
                    v-model="cidadechosen"
                  >
                    <template #no-options="">
                      Escolha um Estado antes!
                    </template></vSelect
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <input
                v-model="EMPRESA"
                placeholder="EMPRESA"
                type="text"
                class="inputnormal rounded-lg form-control"
              />
            </div>
            <h4 class="text-left">Como você nos encontrou</h4>
            <div class="form-group">
              <v-select
                v-model="ORIGEM"
                :reduce="(option) => option.id"
                :options="[
                  { label: 'E-MAIL MARKETING', id: 'E-MAIL MARKETING' },
                  { label: 'FACEBOOK', id: 'FACEBOOK' },
                  { label: 'FEIRAS/EVENTOS', id: 'FEIRAS/EVENTOS' },
                  { label: 'GOOGLE', id: 'GOOGLE' },
                  { label: 'INDICAÇÃO', id:'INDICAÇÃO' },
                  { label: 'INSTAGRAM', id: 'INSTAGRAM' },
                    { label: 'LINKEDIN', id: 'LINKEDIN' },
                  { label: 'OUTROS', id: 'OUTROS' },
                ]"
                class="style-chooser"
                placeholder="ESCOLHA *"
              />
            </div>
            <div class="form-group">
              <textarea
                v-model="MENSAGEM"
                style="color: black; border: 1px solid #9a9a9a"
                class="inputnormal form-control rounded-lg"
                placeholder="SUA MENSAGEM *"
                id="exampleFormControlTextarea1"
                rows="5"
              ></textarea>
            </div>
            <div class="form-actions">
              <div class="form-actions-inner">
                <div class="form-group text-left">CAMPOS OBRIGATÓRIOS*</div>
                <p class="font-weight-bold text-uppercase text-left">
                  preferência de contato por:
                </p>
                <div class="mb-4 row justify-content-start">
                  <div class="col-md-auto mt-2 mt-md-0">
                    <div class="d-flex flex-row">
                      <p class="mr-2 my-auto text-uppercase">Telefone</p>
                      <input v-model="tel" class="my-auto" type="checkbox" />
                    </div>
                  </div>
                  <div class="col-md-auto mt-2 mt-md-0">
                    <div class="d-flex flex-row">
                      <p class="mr-2 my-auto text-uppercase">Whatsapp</p>
                      <input v-model="whats" class="my-auto" type="checkbox" />
                    </div>
                  </div>
                  <div class="col-md-auto mt-2 mt-md-0">
                    <div class="d-flex flex-row">
                      <p class="mr-2 my-auto text-uppercase">E-mail</p>
                      <input v-model="emm" class="my-auto" type="checkbox" />
                    </div>
                  </div>
                </div>

                <div v-if="sended">
                  <div class="d-flex justify-contend-end">
                    <div class="alert alert-success" role="alert">Enviado!</div>
                  </div>
                </div>
                <div v-if="!valido">
                  <div class="d-flex">
                    <div class="alert alert-danger" role="alert">
                      Exitem informações obrigatórias não preenchidas
                    </div>
                  </div>
                </div>
                <button
                  @click="sendcontact"
                  type="button"
                  :disabled="sended"
                  class="mt-4 btnsend btn btn-secondary"
                >
                  Enviar
                </button>
              </div>
            </div>
    </form>
	  </div>
	  <div class="col-12 col-md-2">&nbsp;</div>
	</div>
	     </div>
    </div>

    <div style="background-color: black" class="py-5 footersite">
      <footer>
        <!-- INICIO MOBILE -->
        <div class="d-block d-sm-block d-md-block d-lg-none container">
          <div class="container">
            <FooterSocial />
          </div>
          <div class="accordion" id="collapsemObsf1">
            <!-- inicio ewpresas -->
            <div class="mob-card my-4">
              <div class="mob-card-header" id="headingOnesf1">
                <h2 class="mb-0">
                  <button
                    class="
                      btn
                      d-flex
                      w-100
                      collapsed
                      text-white
                      justify-content-between
                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOnesf1"
                    aria-expanded="true"
                    aria-controls="collapseOnesf1"
                  >
                    <span
                      class="
                        animate__fadeInLeftBig animate__bounce animate__delay-5s
                        text-white
                      "
                      >Nossos Negócios</span
                    >
                    <i class="fa fa-angle-right tex-white"></i>
                  </button>
                </h2>
              </div>
              <MobileFooterEmpresas />
            </div>
            <!-- fim empresas -->
            <!-- inicio sobre -->
            <div class="mob-card my-4">
              <div class="mob-card-header" id="headingOnesf2">
                <h2 class="mb-0">
                  <button
                    class="
                      btn
                      d-flex
                      w-100
                      collapsed
                      text-white
                      justify-content-between
                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOnesf2"
                    aria-expanded="true"
                    aria-controls="collapseOnesf2"
                  >
                    <span
                      class="
                        animate__fadeInLeftBig animate__bounce animate__delay-5s
                        text-white
                      "
                      >Sobre a Sotreq</span
                    >
                    <i class="fa fa-angle-right tex-white"></i>
                  </button>
                </h2>
              </div>
              <MobileFooterSobre />
            </div>
            <!-- fim sobre -->
            <!-- inicio elo -->
            <div class="mob-card my-4">
              <div class="mob-card-header" id="headingOnesf3">
                <h2 class="mb-0">
                  <button
                    class="
                      btn
                      d-flex
                      w-100
                      collapsed
                      text-white
                      justify-content-between
                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOnesf3"
                    aria-expanded="true"
                    aria-controls="collapseOnesf3"
                  >
                    <span
                      class="
                        animate__fadeInLeftBig animate__bounce animate__delay-5s
                        text-white
                      "
                      >ELO NEWS</span
                    >
                    <i class="fa fa-angle-right tex-white"></i>
                  </button>
                </h2>
              </div>
              <MobileFooterElo />
            </div>
            <!-- fim elo -->
          </div>
          <!-- end accordion -->
           <div class="text-left col-auto text-white mb-5">
                <h4 class="mb-3">Sotreq+</h4>
                <p class="lineheight">Capitais e Regiões Metropolitanas:<br> 
                  <a href="https://wa.me/551130031920" target="_blank" class="text-light"><i class="fab fa-whatsapp" aria-hidden="true"></i></a>&nbsp; 
                  <a href="https://t.me/SotreqBot" target="_blank" class="text-light"><i class="fab fa-telegram" aria-hidden="true"></i></a>&nbsp; 
                  <a class="notalink" href="tel:30031920">11 3003 1920</a></p>
              <p class="lineheight">Demais localidades:<br>  <a class="notalink" href="tel:08009401920"> 0800 940 1920 </a></p>
                <p class="lineheight">Horário de atendimento<br>   08h às 17h30</p>
              </div>

          <div class="col-auto d-flex flex-column align-items-start">
            <a class="link" href="/#/faleconosco"> Fale conosco </a>
            <a class="link" href="/#/carreiras">Trabalhe conosco </a>
            <!--
							                      <a class="link" href=""> Mapa do Site</a>
									                      -->
          </div>

          <div class="text-white mt-5">
            <p>© Copyright 2023. All rights reserved.</p>
          </div>
        </div>
        <!-- FIM MOBILE -->

        <!-- INICIO DESKTOP -->
        <div class="d-none d-md-none d-lg-block d-xl-block">
          <div class="container text-white">
            <div class="mx-0 px-0 mb-4 row">
              <div class="text-left col-auto mr-5">
                <p class="mb-3  font-weight-bold">Nossos Negócios</p>
                <FooterEmpresas />
              </div>
              <div class="text-left col-auto">
                <p class="mb-3  font-weight-bold">Sobre a Sotreq</p>
                <FooterSobre />
              </div>
              <div class="text-left col-auto">
                <p class="mb-3  font-weight-bold">Sotreq+</p>
                <p>Capitais e Regiões Metropolitanas: <br>
                  <a href="https://wa.me/551130031920" target="_blank" class=" lineheight text-light"><i class="fab fa-whatsapp" aria-hidden="true"></i></a>&nbsp; 
                  <a href="https://t.me/SotreqBot" target="_blank" class=" lineheight text-light"><i class="fab fa-telegram" aria-hidden="true"></i></a>&nbsp; 
                  <a  href="tel:1130031920" class="lineheight text-light">11 3003 1920</a>
                </p>
                <p>Demais localidades: <br>
                  <a class="lineheight text-light" href="tel:08009401920">0800 940 1920 </a>
                  </p>
                 <p>Horário de atendimento<br>   08h às 17h30</p>

              </div>

              <div
                class="text-left d-flex flex-column justify-content-between col"
              >
                <div>
                  <p class="mb-3  font-weight-bold">ELO NEWS</p>

                  <Newsletter />
                </div>
                <div>
                  <FooterSocial />
                </div>
              </div>
            </div>
            <hr class="mx-3 mt-4" style="color: white" />
            <div class="mx-0 px-0 mt-3 row justify-content-md-between">
              <div class="col-auto d-flex flex-row">
                <a class="link mr-2" href="/#/faleconosco"> Fale conosco </a>|
                <a class="link mx-2" href="/#/carreiras"> Trabalhe conosco </a>
                <!--
																					                       <a class="link ml-2" href=""> Mapa do Site</a>
																					                       -->
              </div>
              <div class="col-auto">
                <p>© Copyright 2023. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import ApiContato from '@/api/apiContato.ts'
import MobileFooterEmpresas from '@/components/Footer/Mobile/MobileFooterEmpresas.vue'
import MobileFooterSobre from '@/components/Footer/Mobile/MobileFooterSobre.vue'
import MobileFooterElo from '@/components/Footer/Mobile/MobileFooterElo.vue'
import FooterEmpresas from '@/components/Footer/FooterEmpresas.vue'
import FooterSocial from '@/components/Footer/FooterSocial.vue'
import FooterSobre from '@/components/Footer/FooterSobre.vue'
import FooterElo from '@/components/Footer/FooterElo.vue'
import Newsletter from '@/components/shared/Newsletter.vue'
import ApiContent from '@/services/api.js'
import estadosCidades from '@/api/estadosCidades.json'
import estados from '@/api/estados.json'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { mask } from 'vue-the-mask'
Vue.component('v-select', vSelect)

export default {
  directives: { mask },
  name: 'FOOTER',
  props: {
    buttonless: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    hasform: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    gtmterm: {
      type: String,
      default: () => {
        return 'default'
      }
    },
    msg: String,
    bgcolor: String

  },
  mounted () {
    this.loadContent()
    this.estados = estados
  },
  components: {
    MobileFooterEmpresas,
    MobileFooterSobre,
    MobileFooterElo,
    FooterEmpresas,
    FooterSocial,
    FooterSobre,
    FooterElo,
    Newsletter,
    vSelect
  },
  methods: {
    async loadContent () {
	    const resp = await ApiContent.get('paginas/57.json')
	    console.log(resp.data)
	    this.heroTopTitulo = resp.data.heroTopTitulo
	    this.heroTopTexto = resp.data.heroTopTexto
	    this.heroTopLink = resp.data.heroTopLink
	    this.destaqueTitulo = resp.data.destaqueTitulo
	    this.destaqueTexto = resp.data.destaqueTexto
	    this.destaqueChamada = resp.data.destaqueChamada
	    this.textoBaseTitulo = resp.data.textoBaseTitulo
	    this.textoBaseTexto = resp.data.textoBaseTexto
	    this.heroBottomTitulo = resp.data.heroBottomTitulo
	    this.heroBottomTexto = resp.data.heroBottomTexto
	    this.heroBottomLink = resp.data.heroBottomLink
	    this.bottomherobuttontext = resp.data.bottomherobuttontext
	    this.heroBottomLink = resp.data.heroBottomLink
	    this.url = resp.data.url
	    this.logo = resp.data.logo
	    this.bottomHeroImage = resp.data.bottomHeroImage
	    this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem
        return s
	    })
    },
    async sendcontact () {
      this.$gtm.trackEvent({
        event: event || 'interaction',
        category: 'Lead',
        action: 'click',
        label: 'form submit  sotreq' + ' ' + this.gtmterm,
        value: 'form submit  sotreq' + ' ' + this.gtmterm,
        noninteraction: false // Optional
      })
	    let bool = true
	    if (!this.estadochosen.nome) {
        console.log(this.estadochosen, 'estadochosen')

        bool = false
	    }
	    if (!this.cidadechosen[0]) {
        console.log(this.cidadechosen, 'cidadechosen')
        bool = false
	    }
	    if (this.MENSAGEM === '') {
        console.log(this.MENSAGEM, 'MENSAGEM')
        bool = false
	    }
	    if (this.NOME === '') {
        console.log(this.NOME, 'NOME')
        bool = false
	    }
	    if (this.EMAIL === '') {
        console.log(this.EMAIL, 'EMAIL')
        bool = false
	    }

	    if (this.TELEFONE === '') {
        console.log(this.TELEFONE, 'TELEFONE')
        bool = false
	    }
	    if (this.ORIGEM === '') {
        console.log(this.ORIGEM, 'ORIGEM')
        bool = false
	    }

	    if (bool) {
        let str = ''
        if (this.emm) {
		    str += 'E-mail '
        }
        if (this.tel) {
		    str += ' Telefone '
        }
        if (this.whats) {
		    str += ' WhatsApp '
        }
        const obj = {
		    estado: this.estadochosen.nome,
		    cidade: this.cidadechosen,
		    nome: this.NOME,
		    email: this.EMAIL,
		    empresa: this.EMPRESA,
		    mensagem: this.MENSAGEM,
		    preferenciacontato: str,
		    telefone: this.TELEFONE,
          assunto: 'Lead Site Sotreq - ' + ' ' + this.gtmterm,
		    origem: this.ORIGEM
        }
        console.log(obj)
        this.valido = true
        const resp = await ApiContato.post('contatos.json', { ...obj })
        console.log(resp)
        if (resp.status === 201) {
		    this.sended = true
		    console.log(resp.body)
		    this.$router.push({ name: 'FormularioGeralEnviado', params: { id: this.gtmterm } })
        } else {
		    this.sended = false
        }
	    } else {
        this.valido = false
	    }
    },
    handlecidades (e) {
	    if (e) {
        this.cidadechosen = []
        this.cidades = estadosCidades.estados[e.id].cidades
        console.log(this.cidades)
	    } else {
        this.cidadechosen = []
        this.cidades = []
	    }
    }
  },
  data () {
    return {
	    heroTopTitulo: [],
	    heroTopTexto: [],
	    heroTopLink: [],
	    destaqueTitulo: [],
	    destaqueTexto: [],
	    destaqueChamada: [],
	    textoBaseTitulo: [],
	    textoBaseTexto: [],
	    heroBottomTitulo: [],
	    heroBottomTexto: [],
	    heroBottomLink: [],
	    created_at: [],
	    updated_at: [],
	    url: [],
      logo: [],
	    bottomHeroImage: [],
	    cards: [],
	    estados: [],
	    cidades: [],
	    estadochosen: [],
	    cidadechosen: [],
	    MENSAGEM: '',
	    valido: true,
	    whats: 0,
	    tel: 0,
	    verified: false,
	    emm: 0,
	    NOME: '',
	    sended: false,
	    EMAIL: '',
	    TELEFONE: '',
	    EMPRESA: '',
	    ASSUNTO: '',
      bgChosencolor: this.bgcolor

    }
	  }
}
</script>

<style scoped>
.lineheight:hover {
  text-decoration: underline;
  cursor: pointer;
}
.textinput {
  border: 2px solid white;
  background-color: transparent;
  color: white;
}

hr {
  border-top: 1px solid white;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

.link {
  text-decoration: none;
  color: white;
}
.link:hover {
  text-decoration: underline;
  color: white;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.footersite {
  margin-top: 50px;
}
.mainNav-collapsible .mobileNavOpen .mainNav--itemTitle {
  opacity: 1;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: opacity 0.5s,
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
  display: block;
  position: relative;
}
.mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  position: relative;
  display: inline-block;
  font-size: 0.45rem;
  margin-left: 5px;
}
.mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  position: relative;
  display: inline-block;
  font-size: 0.45rem;
  margin-left: 5px;
}
i.fa.fa-angle-right {
  margin-left: 1em;
}
#collapsemObs1 {
  padding-left: 0.7em;
}

.btn.btn-link.btn-block.text-left.cool-link:not(.collapsed)
  > i.fa.fa-angle-right {
  transform: rotate(90deg);
}
.quadrado {
    border: 5px solid #ffcb11;
}
.equipamento_nvo {
    background: rgb(2, 0, 36);
    background: radial-gradient(
	circle,
	rgba(2, 0, 36, 1) 0%,
	rgba(214, 155, 31, 1) 50%
    );
}
a {
    text-decoration: none;
}
button {
    border: none;
    background-color: black;
    color: white;
}

button:hover {
    border: 2px solid #fff;
    background-color: #000;
    color: #fff;
}
.btnsend {
    text-transform: uppercase;
    background-color: #f7b220;
    border: #f7b220;
    color: black;
    padding: 0.9em 2em;
    border-radius: 0.6em;
}

.btnsend:hover {
    color: #f7b220;
    background-color: #000;
}
.style-chooser .vs__search::placeholder,
			    .style-chooser .vs__dropdown-toggle,
			    .style-chooser .vs__dropdown-menu {
    background: #dfe5fb;
    border: none;
    color: #394066;
    text-transform: lowercase;
    font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
    fill: #394066;
}

.upimg {
    margin-top: -5vmax;
}
.wbold {
    font-weight: bolder;
    color: white;
}

.wnormal {
    font-weight: normal;
    color: white;
}
.pdf-button {
    background-color: #f7b220;
    color: #000000;
    text-decoration: none !important;
}
.pdf-button:hover {
    background-color: #9a9a9a;
}

.yellowdiv {
    height: 31.5vmax;
    margin-right: -4vmax;
    width: 6vmax;
    background-color: #f7b221;
}
.filled {
    background-color: #ab0718;
}
.imagem {
    height: 30vmax;
}
.inputnormal {
    font-size: 16px;
    color: black;
    border: 1px solid #9a9a9a;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

@media only screen and (min-width: 20px) {

    .innertext {
	font: 1.4em;
	font-weight: 400;
    }

}

/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {

    .innertext {
	font: 1.4em;
	font-weight: 400;
    }

    .mob-contato-hero {
	width: unset;
	height: unset;
    }
}

/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {

    .innertext {
	font: 1.4em;
	font-weight: 400;
    }
      /* .bar {
    margin-bottom: 2vmax;
    margin-top: -0vmax;
    height: 1.2em;
    width: 70%;
    background-color: #ab0718;
  } */

}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {
    .icons i {
	text-transform: uppercase;
	font-weight: bolder;
	font-size: 2vmax;
    }
      /* .icons span {
    font-size: 2em;
  } */
    .innertext {
	font: 1.4em;
	font-weight: 400;
    }
}

.form-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    }
</style>
