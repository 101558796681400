<template>
  <div class="h-100 card border">
    <img
      class="card-img-top"
      :src="data.image"
      alt="Sotreq revendedora Caterpillar"
    />
    <div class="card-body text-left">
      <h5 class="card-title font-weight-bold">{{ data.titulo }}</h5>
      <h5 class="card-title font-weight-bold">{{ data.subtitulo }}</h5>
      <p
        style="white-space: pre-line"
        :class="limit ? 'card-text limit' : 'card-text'"
      >
        {{ data.texto }}
      </p>
    </div>
    <div class="bg-white card-footer">
      <a :href="data.link">
        <button type="button" class="float-left px-5 btn btn-secondary">
          {{ data.buttontext ? data.buttontext : "Faça uma cotação" }}
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NovidadeMaquinas",
  props: {
    data: {
      type: Object,
      required: true,
    },
    limit: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
};
</script>

<style scoped>
.limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
button {
  background-color: black;
  color: white;
  text-transform: uppercase;
}
.card-footer {
  border-top: none;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
