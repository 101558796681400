<template>
  <div class="herohome">
    <div class="d-block d-sm-block d-md-block d-lg-none">
      <img class="img-fluid" :src="data.image" alt="HeroMercados" />
      <div
        class="container my-5 my-md-0 d-flex align-items-center text-left h-100"
      v-if="!buttonless" >
        <div class="text-black text-left">
          <h1 class="font-weight-bold display-4">
            {{ data.titulo }}
          </h1>
          <div class="bar"></div>
        </div>
      </div>
    </div>
    <div class="d-none d-sm-none d-md-none d-lg-block">
          <div class="hero-overlay">&nbsp;</div>

      <div
        :style="'background-image: url(' + data.image + ');'"
        class="hero-img active"
      >
        <div class="container d-flex align-items-center text-left h-100">
          <div class="col-5 text-white text-left">
            <h1 class="font-weight-bold display-3" v-if="!oneliner">
             {{ data.titulo }}
            </h1>
            <h1 class="font-weight-bold display-4 oneliner" v-else>
           {{ data.titulo }}
          </h1>
            <div class="bar"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroCarreiras",
  props: {
   buttonless: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    oneliner: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    data: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
/* The hero image */

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img { max-height: 70vh; 
  height: 700px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.herohome {
  margin-top: -80px;
  position: relative;
}
.hero-overlay {
  height: 100%;
  background-color: #000;
  position: absolute;
  width: 100%;
  opacity: 0.3;
}
.oneliner {
  white-space: nowrap !important
}
</style>
