<template>
    <div class="d-flex flex-column justify-content-center">

    <h4 class="text-center font-weight-bold">
    Deseja tirar uma dúvida ou fazer uma cotação?
</h4>
    <a
      href="#pageform"
      class="btn button-yellow snip-button cta-button mt-2 px-3"
      >Fale conosco</a
    >
</div>
</template>-
<script>
export default {
    name: 'Cta',
    props: {
        data: Object
    }
}
</script>
<style scoped>
</style>