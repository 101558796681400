<template>
  <div class="blackfriday1">
    <Header />
    <HeroBlackfriday :data="bannermercado" />

    <div class="container pt-5">
      <div v-if="this.conteudo.length > 1">
        <div class="">
        <div  v-html="this.conteudo"></div>
        </div>
        </div>
        <br>
           <form class="mt-md-5" style="margin-bottom: 2vmax">
        <div class="form-group">
          <input
            v-model="NOME"
            placeholder="NOME *"
            type="text"
            class="inputnormal rounded-lg form-control"
          />
        </div>
        <div class="form-group">
          <input
            v-model="EMAIL"
            placeholder="E-MAIL *"
            type="text"
            class="inputnormal rounded-lg form-control"
          />
        </div>
        <div class="form-group">
          <input
            v-model="TELEFONE"
            placeholder="TELEFONE *"
            v-mask="['(##) ####-####', '(##) #####-####']"
            type="tel"
            class="inputnormal rounded-lg form-control"
          />
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <vSelect
                class="style-chooser"
                @input="handlecidades"
                placeholder="ESTADO *"
                :options="estados"
                label="sigla"
                v-model="estadochosen"
              />
            </div>
            <div class="d-block d-sm-block d-md-block d-lg-none">
              <div style="height: 4em">&nbsp;</div>
            </div>
            <div class="col-md-6 col-sm-12">
              <vSelect
                class="style-chooser"
                placeholder="CIDADE *"
                :options="cidades"
                label="sigla"
                v-model="cidadechosen"
              >
                <template #no-options="">
                  Escolha um Estado antes!
                </template></vSelect
              >
            </div>
          </div>
        </div>
        <div class="form-group">
          <input
            v-model="EMPRESA"
            placeholder="EMPRESA"
            type="text"
            class="inputnormal rounded-lg form-control"
          />
        </div>
        <div class="form-group">
          <textarea
            v-model="MENSAGEM"
            style="color: black; border: 1px solid #9a9a9a"
            class="inputnormal form-control rounded-lg"
            placeholder="SUA MENSAGEM *"
            id="exampleFormControlTextarea1"
            rows="5"
          ></textarea>
        </div>
        <div class="form-actions">
          <div class="form-actions-inner">
            <div class="form-group text-left">CAMPOS OBRIGATÓRIOS*</div>
            

            <div v-if="sended">
              <div class="d-flex justify-contend-end">
                <div class="alert alert-success" role="alert">Enviado!</div>
              </div>
            </div>
            <div v-if="!valido">
              <div class="d-flex">
                <div class="alert alert-danger" role="alert">
                  Exitem informações obrigatórias não preenchidas
                </div>
              </div>
            </div>
            <button
              @click="sendcontact"
              type="button"
              :disabled="sended"
              class="mt-4 btnsend btn btn-secondary float-right"
            >
              Enviar
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="container mb-5">
<h6 class="text-center pb-5">Ofertas válidas para o segmento de Construção e Energia, disponíveis para todas as filiais da Sotreq. Consulte condições comerciais. Financiamento sujeito à aprovação de crédito pelo Banco Caterpillar;
Somente para produtos Cat. Imagens meramente ilustrativas. </h6>
    </div>
    <br>
    <Footer :buttonless="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import ApiContato from "@/api/apiContato.ts";
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import HeroBlackfriday from '@/components/shared/HeroBlackfriday.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import estadosCidades from "@/api/estadosCidades.json";
import estados from "@/api/estados.json";
import vSelect from "vue-select";
import CardContato from "@/components/CardContato.vue";
import "vue-select/dist/vue-select.css";
import { mask } from "vue-the-mask";
Vue.component("v-select", vSelect);

export default {
    directives: { mask },
  name: 'Blackfriday1',
  mounted () {
    this.loadContent();
    this.estados = estados;

  },
  components: {
    Header,
    HeroBlackfriday,
    Footer,
    vSelect,
    CardContato,
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/174.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
      this.destaques = resp.data.destaques
      this.conteudo = resp.data.content
       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
};
      this.cards.map(card => {
        card.link = card.link
      })
    },
    async sendcontact() {
      let bool = true;
      if (!this.estadochosen.nome) {
      console.log(this.estadochosen, "estadochosen");

      bool = false;
    }
    if (!this.cidadechosen[0]) {
      console.log(this.cidadechosen, "cidadechosen");
      bool = false;
    }
    if (this.MENSAGEM === "") {
      console.log(this.MENSAGEM, "MENSAGEM");
      bool = false;
    }
    if (this.NOME === "") {
      console.log(this.NOME, "NOME");
      bool = false;
    }
    if (this.EMAIL === "") {
      console.log(this.EMAIL, "EMAIL");
      bool = false;
    }

    if (this.TELEFONE === "") {
      console.log(this.TELEFONE, "TELEFONE");
      bool = false;
    }
    if (bool) {
      let str = "";
      if (this.emm) {
        str += "E-mail ";
      }
      if (this.tel) {
        str += " Telefone ";
      }
      if (this.whats) {
        str += " WhatsApp ";
      }
      const obj = {
        estado: this.estadochosen.nome,
        cidade: this.cidadechosen,
        nome: this.NOME,
        email: this.EMAIL,
        empresa: this.EMPRESA,
        mensagem: this.MENSAGEM,
        preferenciacontato: str,
        telefone: this.TELEFONE,
        assunto: "BLACK FRIDAY SOTREQ - INTERESSE"
      };
      console.log(obj);
      this.valido = true;
      const resp = await ApiContato.post("contatos.json", { ...obj });
      console.log(resp);
      if (resp.status === 201) {
      this.sended = true;
      console.log(resp.body);
      this.$router.push({ name: 'FormularioEnviadoBlackfriday' });
    } 
    else {
     this.sended = false;
    }
    }
    else {
      this.valido = false;
    }
  },
    handlecidades(e) {
  if (e) {
    this.cidadechosen = [];
    this.cidades = estadosCidades.estados[e.id].cidades;
    console.log(this.cidades);
  } else {
    this.cidadechosen = [];
    this.cidades = [];
  }
}
  },
  data () {
    return {
      destaques: [],
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      conteudo: [],
      cards: [],
      estados: [],
     cidades: [],
     estadochosen: [],
     cidadechosen: [],
     MENSAGEM: "",
     valido: true,
     whats: 0,
     tel: 0,
     verified: false,
     emm: 0,
     NOME: "",
     sended: false,
     EMAIL: "",
     TELEFONE: "",
     EMPRESA: ""
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
  color: black;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
.btnsend {
  text-transform: uppercase;
  background-color: #f7b220;
  border: #f7b220;
  color: black;
  padding: 0.9em 2em;
  border-radius: 0.6em;
}

.btnsend:hover {
  color: #f7b220;
  background-color: #000;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}

.upimg {
  margin-top: -5vmax;
}
.wbold {
  font-weight: bolder;
  color: white;
}

.wnormal {
  font-weight: normal;
  color: white;
}
.pdf-button {
  background-color: #f7b220;
  color: #000000;
  text-decoration: none !important;
}
.pdf-button:hover {
  background-color: #9a9a9a;
}

@supports (-webkit-text-stroke: 1px rgb(63, 59, 59)) {
  h1 {
    text-transform: uppercase;
    font-size: 7vmax;
    font-family: "Ubuntu", sans-serif;
    font-weight: bold;
    -webkit-text-stroke: 1px #c4c4c4;
    -webkit-text-fill-color: #fff;
  }
}
.yellowdiv {
  height: 31.5vmax;
  margin-right: -4vmax;
  width: 6vmax;
  background-color: #f7b221;
}
.filled {
  background-color: #ab0718;
}
.imagem {
  height: 30vmax;
}
.inputnormal {
  font-size: 16px;
  color: black;
  border: 1px solid #9a9a9a;
}
.bigtitle {
  font-size: 6.5vmax;
  margin-bottom: -3vmax;
}

@media only screen and (min-width: 20px) {
  .icons i {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 3.5vmax;
  }
  /* .icons span {
    font-size: 1em;
    text-transform: uppercase;
  } */
  .finan {
    max-width: 15vmax;
    margin-left: 0vmax;
  }
  .innertext {
    font: 1.4em;
    font-weight: 400;
  }
  /* .bar {
    margin-bottom: 2vmax;
    margin-top: -0vmax;
    height: 1.2em;
    width: 70%;
    background-color: #ab0718;
  } */
  .margininside {
    max-width: 100%;
  }
  .circleimg {
    display: none;
  }
  .circles {
    margin-top: 0;
  }
  .margin-middle {
    max-width: 100%;
  }
  .bigtitle {
    font-size: 7vmax;
    margin-top: 4vmax;
    margin-bottom: -2vmax;
    text-transform: uppercase;
  }
  .innertitle {
    font-size: 2vmax;
    text-transform: uppercase;
    font-weight: bolder;
  }
  .inner {
    font-size: 2vmax;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: -5vmax;
  }
  .smallertitle {
    margin: 15px 0px;

    font-weight: bold;
    font-size: 3vmax;
    color: black;
    text-transform: uppercase;
  }
  .amg {
    font-size: 6vmax;
  }
  .mob-contato-hero {
    width: 100%;
    height: 100%;
  }
}

/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {
  .icons i {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 3.5vmax;
  }
  /* .icons span {
    font-size: 2em;
  } */
  .innertext {
    font: 1.4em;
    font-weight: 400;
  }
  /* .bar {
    margin-bottom: 2vmax;
    margin-top: -0vmax;
    height: 1.2em;
    width: 70%;
    background-color: #ab0718;
  } */
  .margininside {
    margin: 15px 0px;
    max-width: 100%;
  }
  .circleimg {
    display: none;
  }
  .circles {
    margin-top: 0;
  }
  .margin-middle {
    max-width: 100%;
  }
  .innertitle {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bolder;
  }
  .bigtitle {
    text-transform: uppercase;
    font-size: 7vmax;
    margin-top: 4vmax;
    margin-bottom: -2vmax;
  }

  .smallertitle {
    margin: 15px 0px;

    text-transform: uppercase;
    font-weight: bold;
    font-size: 3vmax;
    color: black;
  }
  .mob-contato-hero {
    width: unset;
    height: unset;
  }
}

/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {
  .icons i {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 2vmax;
  }
  /* .icons span {
    font-size: 2em;
  } */
  .innertext {
    font: 1.4em;
    font-weight: 400;
  }
  /* .bar {
    margin-bottom: 2vmax;
    margin-top: -0vmax;
    height: 1.2em;
    width: 70%;
    background-color: #ab0718;
  } */
  .margininside {
    max-width: 50%;
  }
  .circleimg {
    display: inline;
  }
  .circles {
    display: none;
  }
  .margin-middle {
    max-width: 70%;
  }
  .bigtitle {
    text-transform: uppercase;
    font-size: 4vmax;
    margin-top: 4vmax;
    margin-bottom: -2vmax;
  }
  .innertitle {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bolder;
  }

  .smallertitle {
    margin: 15px 0px;
    font-weight: bold;
    font-size: 3vmax;
    color: black;
    text-transform: uppercase;
  }
}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {
  .icons i {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 2vmax;
  }
  /* .icons span {
    font-size: 2em;
  } */
  .innertext {
    font: 1.4em;
    font-weight: 400;
  }
  /* .bar {
    margin-bottom: 2vmax;
    margin-top: 0vmax;
    height: 1.2em;
    width: 70%;
    background-color: #ab0718;
  } */
  .margininside {
    max-width: 50%;
  }
  .circleimg {
    display: inline;
    margin: -3vmax px 0px 0px;
  }
  .circles {
    display: none;
  }
  .margin-middle {
    max-width: 70%;
  }
  .bigtitle {
    font-size: 4vmax;
    margin-top: 4vmax;
    margin-bottom: -2vmax;
    text-transform: uppercase;
  }
  .innertitle {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bolder;
  }
  .smallertitle {
    margin: 15px 0px;
    font-weight: bold;
    font-size: 2.5vmax;
    color: black;
    text-transform: uppercase;
  }
}

.form-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
}
</style>
