<template>
  <div class="py-5 bg-darko container">
    <div class="row justify-content-center">
      <div class="col-auto">
        <img
          class="img-fluid"
          src="../assets/portalelo.png"
          alt="Elo news Logo"
        />
      </div>
    </div>
    <div class="mt-3 text-left card-group">
      <div
        :class="index < 2 ? 'card border-right' : 'card'"
        v-for="(elo, index) in data"
        :key="index"
      >
      <a :href="elo.url ? elo.url : 'https://portalelo.com.br'">
        <div class="card-header">
          <p class="yellow">
            {{ elo.titulo }}
          </p>
        </div>
        <div class="card-body text-white">
          <blockquote class="mb-0">
            <p class="">{{ elo.texto }}</p>
          </blockquote>
        </div>
        <div class="card-footer text-white">
          <div class="d-flex flex-row">
            <i class="yellow mr-2 far fa-clock" style="font-size: 24px"></i>
            <p>{{ elo.dia }}</p>
          </div>
        </div>
        </a>
      </div>
    </div>
    <div class="row mt-4 justify-content-center">
      <div class="col-auto">
        <a href="https://portalelo.com.br/">
          <button
            type="button"
            class="text-uppercase mt-3 px-4 py-2 btn btn-secondary"
          >
            Ver todas as matérias
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ELOnews',
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.card-footer {
  background-color: transparent;
}
.card-header {
  background-color: transparent;
}
.card {
  background-color: transparent;
}
.yellow {
  color: #ffd126;
}
.bg-darko {
  background-color: black;
}
button {
  border-radius: 0;
  border: 3px solid white;
  background-color: transparent;
  color: white;
}
button:hover {
  border-radius: 0;
  border: 3px solid black;
  background-color: white;
  color: black;
}

.card-footer {
  border-top: none;
}
.card-header {
  border-bottom: none;
}
.card {
  border-top: none;
  border-bottom: none;
}
</style>
