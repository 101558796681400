<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h2 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.destaqueTitulo }}
          </h2>
        </div>
      </div>

      <div class="container mt-5">
        <div class="card-deck row row-cols-1 row-cols-md-3">
          <div class="mb-4" v-for="(destaque, i) in cards" :key="i">
            <Novidade :data="destaque" />
          </div>
        </div>
      </div>
    </div>

    <Footer :buttonless="false" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import CardSobrenos from "@/components/CardSobrenos.vue";
import Destaque from "@/components/Destaque.vue";
import HeroMercados from "@/components/Mercados/HeroMercados.vue";
import Footer from "@/components/Footer.vue";
import ApiContent from "@/services/api.js";
import Novidade from "@/components/Novidade.vue";

export default {
  name: "TecnologiaSolucoesMineracao",
  mounted() {
    this.loadContent();
  },
  components: {
    Header,
    CardSobrenos,
    Destaque,
    HeroMercados,
    Footer,
    Novidade,
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get("paginas/194.json");
      console.log(resp.data);
      this.heroTopTitulo = resp.data.heroTopTitulo;
      this.heroTopTexto = resp.data.heroTopTexto;
      this.heroTopLink = resp.data.heroTopLink;
      this.destaques = resp.data.destaques;
      this.destaqueTitulo = resp.data.destaqueTitulo;
      this.destaqueTexto = resp.data.destaqueTexto;
      this.destaqueChamada = resp.data.destaqueChamada;
      this.textoBaseTitulo = resp.data.textoBaseTitulo;
      this.textoBaseTexto = resp.data.textoBaseTexto;
      this.heroBottomTitulo = resp.data.heroBottomTitulo;
      this.heroBottomTexto = resp.data.heroBottomTexto;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.bottomherobuttontext = resp.data.bottomherobuttontext;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.url = resp.data.url;
      this.topHeroImage = resp.data.topHeroImage;
      this.logo = resp.data.logo;
      this.bottomHeroImage = resp.data.bottomHeroImage;
      this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem;
        return s;
      });
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent,
      };
    },
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaques: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
    };
  },
};
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(214, 155, 31, 1) 50%);
}

a {
  text-decoration: none;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}</style>
