<template>
  <div>
    <a href="/#/sobre-nos" class="lineheight text-light">
      <p>Apresentação institucional</p>
     </a>
     <a href="/#/filiais" class="lineheight text-light">
        <p >Filiais</p>
      </a>
      <a href="/#/sobre-nos/conduta" class="lineheight text-light">
        <p >Código de Conduta Ética</p>
      </a>
      <a href="/#/sobre-nos/premios-certificacoes" class="lineheight text-light">
        <p >Prêmios e Certificações</p>
      </a>
      <a href="/#/sobre-nos/sustentabilidade" class="lineheight text-light">
        <p >Sustentabilidade</p>
      </a>
      <a href="/#/politica-privacidade" class="lineheight text-light">
        <p >Política de Privacidade</p>
      </a>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'FooterSobre'
}
</script>

  <style scoped>
</style>
