import { render, staticRenderFns } from "./ServicePlusMarc.vue?vue&type=template&id=0bba2652&scoped=true"
import script from "./ServicePlusMarc.vue?vue&type=script&lang=js"
export * from "./ServicePlusMarc.vue?vue&type=script&lang=js"
import style0 from "./ServicePlusMarc.vue?vue&type=style&index=0&id=0bba2652&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bba2652",
  null
  
)

export default component.exports