<template>
  <div class="home" v-if="renderComponent">
    <Header />
      <div data-v-241e4969="" data-v-4f540693="" class="herohome">
        <div data-v-241e4969="" class="d-block d-md-none">
          <img data-v-241e4969="" src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbVFHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--de136505d4a80bc368e1dcc8c14f877751cbef9d/heroFilial2mob.png" alt="HeroMercados" class="img-fluid">
          <div data-v-241e4969="" class="container my-2 my-md-0 d-flex align-items-center text-left h-100">
            <div data-v-241e4969="" class="text-black text-left">
              <h1 data-v-241e4969="" class="mt-3 mt-lg-0 font-weight-bold display-lg-4"> Filiais Sotreq  </h1>
              <div data-v-241e4969="" class="bar"></div>
              </div>
              </div>
              </div>
              <div data-v-241e4969="" class="d-none  d-md-block">
                <div data-v-241e4969="" class="hero-overlay">&nbsp;</div>
                <div data-v-241e4969="" class="hero-img active" style="background-image: url(https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbU1HIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--dd6a3ec6c24d0d5392d8bce9f531acb6a1da7907/heroFilial2.png);">
                <div data-v-241e4969="" class="container d-flex align-items-center text-left h-100 p-0">
                  <div data-v-241e4969="" class="col-7 text-white text-left">
                    <h1 data-v-241e4969="" class="display-4 font-weight-bold lh-sm"> Filiais Sotreq  </h1>
                    <div data-v-241e4969="" class="bar mt-1"></div>
                    <div data-v-241e4969=""></div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
    <div class="container">
      <div class="row my-5">
        <div class="col text-left">
          <h2 class="font-weight-bold">Encontre uma filial <br/> Sotreq Cat perto de você!</h2>
          <div class="bar mb-3 mb-lg-5"></div>
          <p class="preline">Com mais 80 anos de inovação, a Sotreq é uma empresa nacional que fornece tecnologia, maquinário, soluções e suporte ao produto altamente especializado para os segmentos de construção, mineração, energia, petróleo, marítimo, gás, florestal e agronegócio.</p>
        </div>
        <div class="col mapcontainer" >
          <div id="map" class="map"></div>
        </div>
      </div>
    </div>
        <div class="w-100 bg-yellow">
      <div class="container my-5 my-md-0 text-left h-100 pt-3 pb-1">
        <h1 class="text-left text-lg-center font-weight-bold">
          Encontre a filial mais próxima
        </h1>
      </div>
    </div>
    <div class="w-100 bg-trasparent">
      <div class="container  text-left h-100 py-1 py-lg-3">
        <div class="row">
          <div class="col-12 col-xl-6 text-black text-left">
            <div class="row g-0 align-items-end">
              <div class="col-md-6">
                <label for="inputEmail4" class="form-label"
                  >Pesquise por uma filial</label
                >

                <input
                  type="search"
                  class="form-control"
                  placeholder="Digite o nome da filial"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  id="filtro"
                  v-model="termo"
                />
              </div>
              <div class="col-md-4">
                <label for="contact[estado]">Filial por estado</label>
                <select
            placeholder="Escolha..."
  name="contact[estado]"
  id="contact_estado"
  class="form-control"
  required=""
  v-model="state"
                >
                  <option value="">Escolha...</option>
                  <option value="Acre">Acre</option>
                  <option value="Alagoas">Alagoas</option>
                  <option value="Amapá">Amapá</option>
                  <option value="Amazonas">Amazonas</option>
                  <option value="Bahia">Bahia</option>
                  <option value="Ceará">Ceará</option>
                  <option value="Distrito Federal">Distrito Federal</option>
                  <option value="Espírito Santo">Espírito Santo</option>
                  <option value="Goiás">Goiás</option>
                  <option value="Maranhão">Maranhão</option>
                  <option value="Mato Grosso">Mato Grosso</option>
                  <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                  <option value="Minas Gerais">Minas Gerais</option>
                  <option value="Pará">Pará</option>
                  <option value="Paraíba">Paraíba</option>
                  <option value="Paraná">Paraná</option>
                  <option value="Pernambuco">Pernambuco</option>
                  <option value="Piauí">Piauí</option>
                  <option value="Rio de Janeiro">Rio de Janeiro</option>
                  <option value="Rio Grande do Norte">
                    Rio Grande do Norte
                  </option>
                  <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                  <option value="Rondônia">Rondônia</option>
                  <option value="Roraima">Roraima</option>
                  <option value="Santa Catarina">Santa Catarina</option>
                  <option value="São Paulo">São Paulo</option>
                  <option value="Sergipe">Sergipe</option>
                  <option value="Tocantins">Tocantins</option>
                </select>
              </div>
              <div class="col-md-2">
                <button
                  class="btn btn-warning"
                  type="button"
                  @click="makeMySearch"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6 input-group my-3"></div>
        </div>
      </div>
    </div>
      <div class="container pt-4">
      <div
        id="listings"
        class="listings row row-cols-1 row-cols-md-2 row-cols-xl-4 g-1 px-3 px-lg-0"
      ></div>
    </div>

    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Novidade from "@/components/Novidade.vue";
import ApiContent from "@/services/api.js";

export default {
  name: "Filiais",
  async mounted() {
    window.scrollTo(0, 0);
    this.loadCategoria();
  },
  components: {
    Header,
    Footer,
    Novidade,
  },
  methods: {
 async loadCategoria() {
  var searchTerm = this.termo || this.state;
  
  const resp = await ApiContent.get(`geofiliais?commit=Search&q[nome_or_cidade_or_estado_or_endereco_or_servicos_name_cont]=${
    searchTerm ?? ""  }&q[s]=estado+asc`);
      // console.log(this.code)
      L.mapbox.accessToken =
        "pk.eyJ1Ijoia2VudGFyb2Z1aml5IiwiYSI6ImNqYzJjemZkMDBkZDIzNHI1MTdvOHE0eGgifQ.lFB0RUWtS_pJQR9Ne4QlFA";
      var geojson = [];
      geojson = resp.data.filiais;
      console.log(geojson);
      var map = L.mapbox
        .map("map", null, { zoomControl: false })
        .setView([-17.468, -55.822], 4)
        .addLayer(L.mapbox.styleLayer("mapbox://styles/mapbox/streets-v11"));
      new L.Control.Zoom({ position: "bottomleft" }).addTo(map);

      var listings = document.getElementById("listings");
      var locations = L.mapbox.featureLayer().addTo(map);

      locations.setGeoJSON(geojson);

      function setActive(el) {
        var siblings = listings.getElementsByTagName("div");
        for (var i = 0; i < siblings.length; i++) {
          siblings[i].className = siblings[i].className
            .replace(/active/, "")
            .replace(/\s\s*$/, "");
        }

        el.className += " active";
      }

      locations.eachLayer(function (locale) {
        // Shorten locale.feature.properties to just `prop` so we're not
        // writing this long form over and over again.
        var prop = locale.feature.properties;

        // Each marker on the map.
        var popup =
          '<div class="card">' +
          '<h5 class="card-header">' +
          prop.city +
          "</h5>" +
          '<div class="card-body">  <p class="card-text">' +
          prop.city +
          "-" +
          prop.state +
          "<br>" +
          prop.address +
          "<br>" +
          prop.zip +
          "<br>" +
          prop.phone +
          "</p></div>";

         var listing = listings.appendChild(document.createElement("div"));
        listing.className = "col mb-4";

        var innerlisting = listing.appendChild(document.createElement("div"));
        innerlisting.className = "card h-100 border border-dark rounded-0";

        var cardbody = innerlisting.appendChild(document.createElement("div"));
        cardbody.className = "card-body px-2 pt-3";

        var link = cardbody.appendChild(document.createElement("a"));
        link.href = "/#/filial/" + prop.id;
        link.className = "card-link notalink";
        link.innerHTML =
          '<h4 class="text-yellow mb-0 font-weight-bold">' +
          prop.state +
          '</h4> <h4 class="text-black mb-3 font-weight-bold">' +
          prop.nome +
          "</h4>";

        var cardtext = cardbody.appendChild(document.createElement("p"));
        cardtext.className = "card-text";
        if (prop.address) {
          cardtext.innerHTML +=
            prop.address +
            ", CEP:" +
            prop.zip +
            "<br><br>" +
            '<i class="bi bi-telephone-fill"></i>' +
            " " +
            prop.phone;
          // popup +=
        }
        var cardfooter = innerlisting.appendChild(
          document.createElement("div")
        );
        cardfooter.className = "card-footer bg-transparent border-0 p-1 mt-1";

        var footerlink = cardfooter.appendChild(document.createElement("a"));
        footerlink.href = "/#/filial/" + prop.id;
        footerlink.className = "w-100 btn btn-yellow-card";
        footerlink.innerHTML =
          '<span class="text-center text-black"><i class="bi bi-arrow-right-circle"></i> Mais detalhes</span>';
  
        /*
        link.onclick = function () {
          setActive(listing);
          window.scrollTo(0, 0);

          // When a menu item is clicked, animate the map to center
          // its associated locale and open its popup.
          map.setView(locale.getLatLng(), 16);
          locale.openPopup();
          return false;
        };
        */
        // Marker interaction
        locale.on("click", function (e) {
          // 1. center the map on the selected marker.
          map.panTo(locale.getLatLng());

          // 2. Set active the markers associated listing.
          setActive(listing);
        });

        popup += "</div>";
        locale.bindPopup(popup);

        locale.setIcon(
          L.icon({
            iconUrl: "pin.png",
            iconSize: [33, 44],
            iconAnchor: [28, 28],
            popupAnchor: [0, -34],
          })
        );
      });
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    makeMySearch() {
      this.loadCategoria();
      this.forceRerender();
    },
  },
  data() {
    return {
      renderComponent: true,
          termo: '',
    state: '',
    };
  },
};
</script>
<style scoped>

.quadrado {
  border: 5px solid #ffcb11;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}

a {
  text-decoration: none;
}

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}

button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.bar {
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}

.traco {
  width: 100px;
  background-color: #ffcb11;
}

.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img {
  max-height: 70vh;
  height: 700px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}



.yellowbar {
  width: 100%;
  height: 12px;
  background-color: #ffcd11;
}

.greybar {
  width: 100%;
  height: 131px;
  background-color: #ececec;
}

.greybar {
  width: 100%;
  height: 100%;
  background-color: #ececec;
}

.black-bg {
  background-color: #000;
}

.separatorbox {
  min-height: 100%;
  border-right: solid 1px #000;
  margin: 0px 1em 0px 1em;
}

.plus-drop {
  width: 1.5em;
}

.spec-collapse {
  border-top: solid 0.5px #000;
}

.last-line {
  min-width: 100%;
  border-top: solid 1px #000;
}

a.w-100[aria-expanded="false"]
  div.d-flex.justify-content-between.spec-collapse.p-3
  img.plus-drop {
  transform: rotate(0deg);
  transition: transform 1s;
}

a.w-100[aria-expanded="true"]
  div.d-flex.justify-content-between.spec-collapse.p-3
  img.plus-drop {
  transform: rotate(45deg);
  transition: transform 1s;
}

.position-absolute.w-100.h-100.preloader {
  background-color: #fff;
  z-index: 99999;
  position: absolute;
  margin: auto;
  padding-top: 35vh;
}

.sidebar {
  position: relative;
  width: 33.3333%;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.pad2 {
  padding: 20px;
}

.quiet {
  color: #888;
}

.map {
  position: relative;
  width: 358px;
  min-width: 358px;
  max-width: 358px;
  height: 285px;
  min-height: 285px;
  max-height: 285px;
}

.heading {
  background: #fff;
  border-bottom: 1px solid #eee;
  min-height: 60px;
  line-height: 60px;
  padding: 0 10px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #00853e;
  border-radius: 0;
}

.clearfix {
  display: block;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* Marker tweaks */
.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", Sans-serif;
  padding: 0;
  width: 200px;
}

.leaflet-popup-content-wrapper {
  padding: 0;
}

.leaflet-popup-content h3 {
  background: yellow;
  color: #000;
  margin: 0;
  display: block;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -15px;
}

.leaflet-popup-content div {
  padding: 10px;
}

.leaflet-container .leaflet-marker-icon {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}

.btnsend {
  text-transform: uppercase;
  background-color: #f7b220;
  border: #f7b220;
  color: black;
  border-radius: 0.2em;
}

.btnsend:hover {
  color: #f7b220;
  background-color: #000;
}

@media (min-width: 768px) {
  .map {
    width: 100%;
    min-width: 100%;
    max-width: 379px;
  }
  .herohome {
    margin-top: -101px;
}
}
@media (min-width: 1280px) {
  .map {
  position: relative;
  width: 668px;
  min-width: 668px;
  max-width: 668px;
  height: 354px;
  min-height: 354px;
  max-height: 354px;
}
}
@media (max-width: 578px) {
  button.btn.btn-warning {
    width: 100%;
    margin-top: 16px;
}
}

</style>
