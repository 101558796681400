<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="bg-light w-100">
      <div class="container">
        <div class="row bg-dark px-0 mx-0">
          <div class="bg-light mx-0 px-0 text-left text-md-left col-md">
            <div class="my-5 row justify-content-end">
              <div class="col">
                <div class="container">
                  <h1 class="font-weight-bold mr-md--5">
                    {{ this.destaqueTitulo }}.
                  </h1>
                  <p class="mr-md--5">{{ this.destaqueTexto }}</p>

                  <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
                    {{ this.textoBaseTitulo }}
                  </h1>
                  <p style="white-space: pre-line" class="mt-3">
                    {{ this.textoBaseTexto }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-0 px-0 col-md row flex-column">
            <div class="col px-0 mx-0" style="background-color: #ffcb11">
              <div class="row px-0 mx-0 h-100">
                <div
                  class="ml-5 text-left text-white col-md-7 d-flex align-items-center"
                >
                  <a
                    class="text-dark"
                    href="https://soswebmc.cat.com/cat-sos/dashboard?locale=pt_br"
                  >
                    <h1 class="px-0 font-weight-bold mx-0">
                      {{ heroTopTexto }}
                    </h1>
                  </a>
                </div>
              </div>
            </div>
            <div class="col px-0 mx-0">
              <div class="row px-0 mx-0 h-100">
                <div
                  class="ml-5 text-left text-white col-md-7 d-flex align-items-center"
                >
                  <a class="text-white" href="#/lab-sos/nosso-lab">
                    <h1 class="px-0 font-weight-bold mx-0">
                      {{ topherobuttontext }}
                    </h1>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <a href="#/filiais" class="notalink">
      <div class="desatque my-4">
        <div class="row px-0 mx-0">
          <div class="col px-0 mx-0">
            <div class="card mb-3">
              <div
                style="background-color: #d0d0d0"
                class="row px-0 mx-0 d-flex flex-md-row-reverse"
              >
                <div class="col-md-4 mx-0 px-0 d-flex align-items-center">
                  <img :src="bottomHeroImage" class="img-fluid" />
                </div>
                <div
                  style="background-color: #d0d0d0"
                  class="col-md-8 d-flex flex-column justify-content-center py-1"
                >
                  <div class="text-left card-body">
                    <div class="d-flex justify-content-between flex-column">
                      <div>
                        <h1 class="font-weight-bold">{{ heroBottomTitulo }}</h1>
                        <div class="bar mb-5"></div>
                        <h4 class="mb-3">{{ heroBottomTexto }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </a>
      <div class="row mt-4" v-for="(card, index) in cards" :key="index">
        <div class="col-12 text-left">
          <h5 class="font-weight-bold">
            {{ card.titulo }}
          </h5>

          <p style="white-space: pre-line" class="">
            {{ card.texto }}
          </p>
        </div>
      </div>
    </div>
   <!--  <div class="d-block d-sm-block d-md-block d-lg-none">
      <div style="" class="py-5 equipamento_nvo">
        <div class="class py-5 container">
          <div class="row py-5 justify-content-center text-center">
            <div class="py-5 col-8">
              <div class="row mb-2">
                <div class="col">
                  <h2 class="text-uppercase font-weight-bold text-white">
                    PRECISA DE UM EQUIPAMENTO <br />
                    E NÃO QUER INVESTIR em NOVO?
                  </h2>
                </div>
              </div>
              <div class="mt-5 row">
                <div class="col">
                  <a href="https://sotreqseminovos.com.br">
                    <button type="button" class="btndiferente px-5 mx-2 btn">
                      Seminovos Sotreq
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="d-none d-md-none d-lg-block d-xl-block">
      <div
        class="hero-img text-left d-flex flex-column justify-content-center align-items-start"
      >
        <div class="container">
          <h2 class="text-uppercase font-weight-bold text-white">
            {{ destaques[0].titulo }}
          </h2>

          <p style="white-space: pre-line" class="w-50 text-white">
            {{ destaques[0].texto }}
          </p>
        </div>
      </div>
    </div>
 <div class="d-block d-md-block d-lg-none d-xl-none">
      <div
        class="no-hero-img text-left d-flex flex-column justify-content-center align-items-start"
      >
        <div class="container">
          <h2 class="text-uppercase font-weight-bold">
            {{ destaques[0].titulo }}
          </h2>

          <p style="white-space: pre-line" class="">
            {{ destaques[0].texto }}
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col mt-3">
          <h2 class="font-weight-bold">Conheça nossas soluções</h2>
          <div class="row justify-content-between">
            <div
              class="col-12 col-md-4 yellow d-flex justify-content-center align-items-center lab-card"
              @click="redirect('lab-sos/liquido-lubrificante')"
            >
              <h5 class="w-75 py-5">Análise de Óleo Lubrificante</h5>
            </div>
            <div
              @click="redirect('lab-sos/liquido-arrefecimento')"
              class="col-12 col-md-4 yellow d-flex justify-content-center align-items-center lab-card"
            >
              <h5 class="w-75 py-5">Análise de Líquido de Arrefecimento</h5>
            </div>
            <div
              @click="redirect('lab-sos/oleo-diesel')"
              class="col-12 col-md-4 yellow d-flex justify-content-center align-items-center lab-card">
              <h5 class="w-75 py-5">Análise de Óleo <br>Diesel</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h2 class="font-weight-bold mt-4">
      Normas e Métodos dos ensaios
    </h2>
    <div class="container my-4 text-left">
    <h3 class="mb-4">
        Os testes contemplados em nosso programa de análise para amostras de óleos lubrificantes são:
    </h3>

    <ul class="list-group">
        <li class="list-group-item border-0">
            <strong>Viscosidade – ASTM D445:</strong>
            <p class="mb-1">Por meio desse ensaio determinamos o grau de viscosidade do óleo lubrificante analisado. A viscosidade é determinada em viscosímetro cinemático a 100ºC para equipamentos móveis e 40ºC para redutores industriais.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Espectrometria de Infravermelho – ASTM E 2412-04:</strong>
            <p class="mb-1">Para a análise de Infravermelho por Transformada de Fourier utilizamos um equipamento com amostrador automático de última geração. No Infravermelho são feitas as medições de elementos degradantes no lubrificante bem como o teor de sulfatação para óleo de motor:</p>
            <ul>
                <li>Teor de Fuligem</li>
                <li>Teor de Oxidação</li>
                <li>Teor de Nitração</li>
                <li>Teor de Sulfatação</li>
                <li>TBN (amostras de motores)</li>
            </ul>
        </li>
        <li class="list-group-item border-0">
            <strong>Espectrometria de Emissão Ótica a Plasma – ASTM 5185:</strong>
            <p class="mb-1">O teste de elementos químicos é realizado com um espectrômetro de emissão ótica a plasma com medições simultâneas onde avaliamos partículas inferiores a 10 micra de tamanho. Os resultados são expressos em “ppm”, partes por milhão. São analisados 18 elementos, que podem ser de desgaste, contaminação externa e aditivação do óleo.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Contagem de Partículas – ISO 4406:</strong>
            <p class="mb-1">A determinação da quantidade de partículas em suspensão no óleo é realizada com um contador de partículas automático, com medição através de raio laser.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Karl Fischer - (presença de água) ASTM D7546:</strong>
            <p class="mb-1">Esse ensaio permite medir quantitativamente a presença de água no óleo.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Diluição por Combustível (Cromatografia gasosa) ASTM D7593:</strong>
            <p class="mb-1">Esse ensaio permite medir quantitativamente a presença de diesel no óleo motor.</p>
        </li>
    </ul>
    <h3 class="mb-4">Os testes contemplados em nosso programa de análise de Líquido de Arrefecimento são:</h3>
    <ul class="list-group">
        <li class="list-group-item border-0">
            <strong>Glicol (%):</strong>
            <p>Verificação do percentual de glicol presente na amostra. O glicol é responsável por aumentar a temperatura de ebulição e diminuir a temperatura de congelamento do líquido.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>PH:</strong>
            <p>Verifica a acidez ou alcalinidade da amostra de líquido de arrefecimento. Parâmetro conforme os fabricantes.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Condutividade:</strong>
            <p>A condutividade é a medida de como o líquido está conduzindo a corrente elétrica. Este parâmetro deve estar dentro das especificações dos fabricantes.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Nitrito (ppm):</strong>
            <p>O nitrito é um aditivo de alguns líquidos de arrefecimento que fornece proteção contra cavitação e corrosão. É necessário verificar sua concentração afim de estabelecer a necessidade ou não de procedimentos de manutenção para correção de suas concentrações.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Odor:</strong>
            <p>O odor é uma das mais efetivas ferramentas para indicar problemas nos sistemas de arrefecimento. Problemas como passagem de compressão, contaminação por materiais de limpeza dos radiadores, reações químicas entre outros.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Cor:</strong>
            <p>Mudanças na cor do líquido de arrefecimento geralmente podem indicar mudanças químicas no produto ou mistura de outros tipos de líquido de arrefecimento e ou inibidores (SCA ou Extender) ou mistura de água ou produtos de forma incorreta.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Aparência:</strong>
            <p>Verificação de turbidez ou materiais diferentes nas amostras de líquido de arrefecimento, podem indicar contaminação por água, óleo lubrificante, diesel, solventes, utilização de água imprópria, etc.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Precipitado:</strong>
            <p>Material não dissolvido nas amostras de líquido de arrefecimento. São geralmente resultado de corrosão dos componentes metálicos do sistema de arrefecimento ou precipitação dos aditivos do líquido de arrefecimento do motor.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Espuma:</strong>
            <p>Pode ser causada por contaminantes externos ou pela perda de aditivo antiespumante do líquido de arrefecimento. Passagem dos gases de exaustão também podem causar espuma no sistema de arrefecimento.</p>
        </li>
    </ul>
    <h3 class="mb-4">
        Os testes contemplados em nosso programa de análise para amostras de óleo diesel são:
    </h3>
    <ul class="list-group">
        <li class="list-group-item border-0">
            <strong>Teor de Biodiesel – ASTM D7371:</strong>
            <p class="mb-1">Verificação do teor de Biodiesel presente no óleo diesel. O teor de biodiesel deve estar conforme o que especifica legislação vigente.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Teor de Enxofre – ASTM 5185:</strong>
            <p class="mb-1">Verificação do teor de enxofre presente no óleo diesel. O teor de enxofre deve estar conforme a informação do produto utilizado.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Contagem de Partículas – ISO 4406:</strong>
            <p class="mb-1">A determinação da quantidade de partículas em suspensão no óleo é realizada com um contador de partículas automático, com medição através de raio laser.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Viscosidade a 40º - ASTM D445:</strong>
            <p class="mb-1">Medição da viscosidade para verificação de possíveis contaminações e atendimento a especificação da legislação vigente.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Cor:</strong>
            <p class="mb-1">Através do teste é possível averiguar o uso correto do diesel em determinada especificação de acordo com a sua cor.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Teor de água (Karl Fischer) – ASTM D6304:</strong>
            <p class="mb-1">Verificação quantitativa do teor de água contido no óleo diesel.</p>
        </li>
        <li class="list-group-item border-0">
            <strong>Análise de elementos de desgaste e aditivação – ASTM 5185:</strong>
            <p class="mb-1">Verificação dos teores de desgaste, contaminação e aditivos do óleo diesel.</p>
        </li>
    </ul>
</div>

    <div style="background-color: #000000" class="mt-5 mb-0 pb-0">
      <div class="py-5 mb-0 pb-0">
        <h2 class="text-white font-weight-bold">{{ heroBottomLink }}</h2>
        <h4 class="text-white">{{ bottomherobuttontext }}</h4>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Home',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    Footer
  },
  methods: {
    async redirect (c) {
      this.$router.push(c)
    },
    async loadContent () {
      const resp = await ApiContent.get('paginas/107.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.topherobuttontext = resp.data.topherobuttontext
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaques = resp.data.destaques
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.created_at = resp.data.created_at
      this.updated_at = resp.data.updated_at
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.cards = resp.data.cards
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaques: [],
      bottomherobuttontext: [],
      topherobuttontext: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
.hero-img { max-height: 70vh; 
  height: 555px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/Intersecao.png");
}
.no-hero-img {
    height: max-content;
    width: 100%;
    padding: 2rem 0rem 2rem 0rem;
}
.yellow {
  border: 4px solid #ffcb11;
  cursor: pointer;
}
.yellow:hover {
  color: #ffcb11;
  background-color: #f2f2f2;
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: transparent;
  color: white;
}
</style>
