<template>
  <div class="desatque my-4">
    <div class="row">
      <div class="col">
        <div class="card mb-3">
          <div
            :class="
              inverted
                ? 'row d-flex flex-md-row-reverse'
                : 'row d-flex flex-md-row'
            "
          >
            <div class="col-md-7 d-flex align-items-center">
              <img :src="data.image" class="img-fluid" alt="Sotreq revendedora Caterpillar" />
            </div>
            <div
              class="col-md-5 d-flex flex-column justify-content-center py-1"
            >
              <div class="text-left card-body">
                <div class="d-flex justify-content-between flex-column">
                  <div>
                    <h1 class="font-weight-bold">{{ data.titulo }}</h1>
                     <h1 class="font-weight-bold">{{ data.subtitulo }}</h1>
                    <div class="bar"></div>
                    <p  class="mb-3" style="white-space: pre-line">{{ data.texto }}</p>
                  </div>
                  <div>
                    <a :href="data.link" target="_blank">
                      <button
                        v-if="!buttonless"
                        type="button"
                        class="px-5 btn btn-secondary"
                      >
                        {{ data.buttontext ? data.buttontext : "Saiba mais" }}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DestaqueFlorestal',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return { image: '', titulo: '', subtitulo: '', text: '' }
      }
    },
    inverted: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    buttonless: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>

<style scoped>
button {
  border-radius: none;
  border: 3px solid black;
  background-color: transparent;
  color: black;
}

.card {
  border: none;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
