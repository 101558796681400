<template>
  <div class="Mercados my-5">
    <div class="mt-5 container">
      <div class="row justify-content-center">
        <div class="col-auto">
          <h4 class="text-uppercase  font-weight-bold">Mercados</h4>
        </div>
      </div>
      <div class="mt-3 mb-2 row justify-content-center">
        <div class="col-auto">
          <h4 class="">
           A Sotreq atua em diferentes mercados, fornecendo equipamentos e tecnologias de ponta, serviços com mão de obra especializada e comprometimento com o pós-venda. Conheça nossas soluções para o seu ramo de atuação.
          </h4>
        </div>
      </div>

      <div class="mercadosgrid row mt-5 d-flex flex-wrap">
        <div
          class="py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column border-bottom marginside"
        >
          <a class="linkhover" href="/#/areiaebrita">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/areaiebrita.png"
              alt="Areia e Brita"
            />
            <h4 class="  px-5 px-md-0 text-uppercase">Areia e Brita</h4>
          </a>
        </div>
        <div
          class="py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column border-bottom marginside"
        >
          <a class="linkhover" href="/#/agricola">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/agricola.png"
              alt="Agrícola"
            />

            <h4 class="  px-md-0 px-5  text-uppercase">Agrícola</h4>
          </a>
        </div>
        <div
          class="py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column border-bottom marginside"
        >
          <a class="linkhover" href="/#/construcao">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/construcao.png"
              alt="Construção"
               style="max-width: 127px;"
            />
            <h4 class="  px-5 px-md-0 text-uppercase">Construção</h4>
          </a>
        </div>
        <div
          class="py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column border-bottom marginside"
        >
          <a class="linkhover" href="/#/energia">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/energia.png"
              alt="Energia"

            />
          <h4 class="  px-5 px-md-0 text-uppercase">Energia</h4>
          </a>
        </div>
        <div
          class="py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column border-bottom"
        >
          <a class="linkhover" href="/#/florestal">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/florestal.png"
              alt="Florestal"
            />
          <h4 class="  px-5 px-md-0 text-uppercase">Florestal</h4>
          </a>
        </div>
      </div>
      <div class="mercadosgrid row d-flex flex-wrap">
        <div
          class="border-sm-bottom py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column marginside"
        >
          <a class="linkhover" href="/#/industrial">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/industrial.png"
              alt="Industrial"
              style="max-width: 140px;"
            />
          <h4 class="px-5 px-md-0 text-uppercase">Industrial</h4>
          </a>
        </div>
        <div
          class="border-sm-bottom py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column marginside"
        >
          <a class="linkhover" href="/#/maritimo">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/maritimo.png"
              alt="Marítimo"

            />
          <h4 class="  px-5 px-md-0 text-uppercase">Marítimo</h4>
          </a>
        </div>
        <div
          class="border-sm-bottom py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column marginside"
        >
          <a class="linkhover" href="/#/mineracao">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/mineracao.png"
              alt="Mineração"  style="max-width: 100px;"
            />

          <h4 class="  px-5 px-md-0 text-uppercase">Mineração</h4>
          </a>
        </div>
        <div
          class="border-sm-bottom py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column marginside"
        >
          <a class="linkhover" href="/#/oleoegas">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/oleoegas.png"
              alt="Óleo e Gás"
            />

          <h4 class="  px-5 px-md-0 text-uppercase">Óleo e Gás</h4>
          </a>
        </div>
        <div
          class="border-sm-bottom py-5 col d-flex align-items-center justify-content-center justify-content-md-end flex-md-column"
        >
          <a class="linkhover" href="/#/pavimentacao">
            <img
              class="mr-3 mr-md-0 img-fluid"
              src="../assets/pavimentacao.png"
              alt="Pavimentação"  style="max-width: 100px;"
            />

          <h4 class="  px-5 px-md-0 text-uppercase">Pavimentação</h4>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MERCADOS',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.mercadosgrid div {
  cursor: pointer;
}
.marginside {
  border-right: 2px solid #dcdcde;
}
a{
  text-decoration: none;
}
img {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 95%;
  max-width: 95%;
}

.linkhover:hover  img{
  overflow: hidden;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
img {
  height: auto;
  max-width: 80px;
  padding-bottom: 1em;
}
h4 {
  color: black;
}
@media(max-width:768px) {
  .border-sm-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
}
</style>
