<template>
    <div>
        <div class="w-100 py-0 mt-2 bg-yellow">
    <div class="container">
        <h2 class="ml-0 pl-4 mt-2 font-weight-bold text-left snip-h2"> {{ data.titulo }}</h2>
    </div>
</div>
<div class="hspacer20"></div>
<h5 class="font-weight-bold subtitle">{{ data.subtitulo }}</h5>
<div class="hspacer8"></div>
<article class="media row">
  <figure class="media-left  col-12 col-md-6 order-last">
    <p class="image is-64x6">
      <img class="imgbg img-fluid" :src="data.image" />
    </p>
  </figure>
  <div class="col-12 col-md-6 media-content h-100 d-flex flex-column justify-content-center">
    <div class="content">
             <h2 class="title font-weight-bold mb-0 pt-0 mt-2">
{{ data.titulo }}
            </h2>
      <div class="hspacer8"></div>
      <div class="bar my-0"></div>
      <div class="hspacer14"></div>
        <p class="font-weight-bold">Aplicações</p>
             <div class="hspacer14"></div>
           <ul
            class="text"
            v-html="data.texto"
          ></ul>
    </div>
  </div>
  
</article>
    </div>
</template>
<script>
export default {
    name: 'MediaRight',
    props: {
        data: Object
}
}
</script>