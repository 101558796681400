import { render, staticRenderFns } from "./Sem.vue?vue&type=template&id=76cf6672&scoped=true"
import script from "./Sem.vue?vue&type=script&lang=js"
export * from "./Sem.vue?vue&type=script&lang=js"
import style0 from "./Sem.vue?vue&type=style&index=0&id=76cf6672&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76cf6672",
  null
  
)

export default component.exports