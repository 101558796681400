<template>
    <div class="home">
      <Header />
      <HeroMercados :oneliner="true" :data="bannermercado"  />
      <div class="container pt-0 pt-md-5">
        <div class="row">
          <div class="col text-left">
            <h2 class="font-weight-bold">{{ this.destaqueTitulo }}</h2>
            <div class="mb-3"></div>
          </div>
        </div>
        <div class="row">

          <div class="col-12 col-md-8 text-left ">
            <p class="">
              Acessar a <a href="https://parts.cat.com/sotreq" target="_blank" > Loja on-line de Peças </a> significa consultar o preço e disponibilidade de 1,4 milhões de peças e realizar pedidos com agilidade, praticidade e segurança.</p>
              <p class="">
                Com a <a href="https://parts.cat.com/sotreq" target="_blank" > Loja on-line de Peças </a> você agrega inteligência no processo de compra, tendo acesso a relatórios, histórico de pedidos, listas de compras, preferencias de conta, sistema de compatibilidade de peças e muitas outras funcionalidades.
            </p>

            <div class="d-block d-md-none">
            <div class="container-flud w-100 mb-3">
            <img :src="this.logo" class="img-fluid"/>
            </div>
            </div>

            <h2 class="text-yellow font-weight-bold">
              {{ this.destaqueChamada }}
            </h2>

          <div v-if="this.conteudo.length > 1">
            <div class="resetforcontent">
            <div  v-html="this.conteudo"></div>
            </div>
          </div>
          </div>
          <div class="col-12 col-md-4 ">
            <div class="d-none d-md-block">
            <img :src="this.logo" />
            </div>
          </div>
        </div>

        <div class="desatque my-4">
            <div class="row px-0 mx-0">
              <div class="col px-0 mx-0">
                <div class="mb-3">
                  <div class="row px-0 mx-0 d-flex">
                    <div class="col-md-6 mx-0 px-0 d-flex align-items-center nopadding">
                      <img
                        :src="this.bottomHeroImage"
                        class="img-fluid"
                      />
                    </div>
                    <div
                      class="
                        col-md-6
                        px-0
                        d-flex
                        flex-column
                        justify-content-center
                        py-1
                      "
                    >
                      <div class="text-left card-body d-flex align-items-center px-0 px-md-3">
                        <div class="d-flex justify-content-between flex-column">
                          <div>
                            <h2 class="font-weight-bold">{{ this.heroTopTexto }}</h2>
                            <div class="bar mb-1"></div>
                            <p class="my-4 h4 font-weight-bold">

                            Escolha o dia e horário e agendaremos uma demonstração da <a href="https://parts.cat.com/sotreq" target="_blank" > Loja on-line de Peças </a> para melhor aproveitamento da ferramenta.
                            </p>
                              <a :href="this.heroTopLink" target="_blank">
        <button type="button" class="px-5 btn btn-secondary">{{ this.topHeroButtonText }}</button>
      </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4 text-left">
              <h2 class="font-weight-bold">{{ this.textoBaseTitulo }}</h2>
              <div class="bar mb-1"></div>
              <p class="my-3">
                {{ this.textoBaseTexto }}
              </p>
            </div>
            <div class="card-deck row row-cols-1 row-cols-md-3 px-3 px-md-0">
              <div class="mb-4 bnt-lower" v-for="(destaque, i) in cards" :key="i">
                <Novidade :data="destaque" />
              </div>
          </div>
            <div class="text-left">
              <h2 class="font-weight-bold">{{heroBottomTitulo}}</h2>
              <div class="bar mb-1"></div>
              <p class="mt-3">
                {{this.heroBottomTexto}}
              </p>
              <p class="mb-3">
                {{this.heroBottomLink}}
              </p>

              <a :href="this.bottomherobuttontext" download>
                Política de Devolução
              </a>
            </div>
      </div>
      <Footer />
    </div>
  </template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Novidade from '@/components/Novidade.vue'

export default {
  name: 'PecasOnline',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    Novidade
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/185.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.topHeroButtonText = resp.data.topherobuttontext
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.conteudo = resp.data.content

      this.cards = resp.data.destaques.map(s => {
        s.image = s.image
        s.titulo = s.titulo
        return s
      })
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: ''
      }
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      topHeroButtonText: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      conteudo: [],
      cards: []
    }
  }
}
</script>
  <style scoped>
  .quadrado {
    border: 5px solid #ffcb11;
  }
  .equipamento_nvo {
    background: rgb(2, 0, 36);
    background: radial-gradient(
      circle,
      rgba(2, 0, 36, 1) 0%,
      rgba(214, 155, 31, 1) 50%
    );
  }
  a {
    text-decoration: none;
  }
  button {
    border: 2px solid white;
    background-color: black;
    color: white;
    text-transform: inherit !important
  }

  button:hover {
    border: 2px solid black;
    background-color: white;
    color: black;
  }
  .resetforcontent {
    line-height: 37px !important;
  }
  button.float-left.px-5.btn.btn-secondary {
      text-transform: inherit !important;
  }
  </style>
  <style>
  div.mb-4.bnt-lower div.h-100.card.border div.bg-white.card-footer a button.float-left.px-5.btn.btn-secondary {
      text-transform: inherit;
  }
  </style>
