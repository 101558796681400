<template>
  <div class="herohome">
   
    <div class="">
      <div class="hero-overlay">&nbsp;</div>

      <div
        :style="'background-image: url(' + data.image + ');'"
        class="hero-img active"
      >
        <div class="container d-flex align-items-center text-left h-100 p-0">
          <div class="col-12 col-md-7 text-white text-left">
            <h1 class="display-4 font-weight-bold  lh-sm mb-0">
              {{ data.titulo }}
              <br v-if="data.subtitulo" />
              {{ data.subtitulo }}
            </h1>
            <div class="bar mt-1"></div>
            <div class="">
              <p class="pt-4 pb-4" v-html="data.herocontent" v-if="data.herocontent" >
              </p>

              <a :href="data.link" type="button" class="fs-6 px-3 btn btn-sm btn-warning"
                v-if="data.buttontext" >{{ data.buttontext }}   <i :class="data.texto"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroIndustrial',
  props: {
    buttonless: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    oneliner: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    data: {
      type: Array,
      required: true
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
/* The hero image */

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img {
  height: 70vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 500px;
}
@media(max-width: 768px) {
  .hero-img {
   height: 626px;
        max-height: 626px;
        min-height: 626px;
        background-size: cover;
        background-position: right;
  }
 iv.home div.herohome div div.hero-img.active div.container.d-flex.align-items-center.text-left.h-100.p-0 div.col-12.col-md-7.text-white.text-left div p.pt-4.pb-4  {
  font-size: 20px !important;
 }
 div.herohome div div.hero-img.active div.container.d-flex.align-items-center.text-left.h-100.p-0 div.col-12.col-md-7.text-white.text-left h1.display-4.font-weight-bold.lh-sm {
  font-size: 56px !important;
 }
  .col-12.col-md-7.text-white.text-left {
    margin-top: -57px;
}
div.herohome div div.hero-img.active div.container.d-flex.align-items-center.text-left.h-100.p-0 div.col-12.col-md-7.text-white.text-left {
  padding-left: 0px
}
}
/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.herohome {
  margin-top: -80px;
  position: relative;
}
.hero-overlay {
  height: 100%;
  background-color: #000;
  position: absolute;
  width: 100%;
  opacity: 0.3;
}
.oneliner {
  white-space: nowrap !important;
}
.lh-sm {
  line-height: 62px !important;
}
h1.display-4.font-weight-bold.lh-sm.mb-0 {
    margin-bottom: 12px !important;
}
</style>
