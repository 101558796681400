<template>

      <a :href="data.url" class="link-black">
{{ data.nome }}
      </a>
</template>

<script>
export default {
  name: 'Termosbuscados',
  props: {
    data: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
input #searchModule {
    color: #000000 !important;
  }
  .link-black {
    color: #000000;
  }
  button,
  input {
    margin: 0;
  }
  button,
  input {
    background-color: rgba(0, 0, 0, 0);
    color: #000000;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button {
    text-transform: none;
  }
  [type='submit'],
  button {
    -webkit-appearance: button;
  }
  [type='submit']::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  [type='submit']:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [tabindex],
  a,
  button,
  input,
  label {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .right {
    float: right;
  }
  .visibility-hidden {
    opacity: 0;
    position: absolute;
    left: -9999999px;
    top: -9999999px;
    visibility: hidden;
    width: 0;
    display: block;
    height: 0;
  }
  .searchBar {
    display: none;
  }
  .searchBar--overlay {
    background: rgba(51, 51, 51, 0.3);
    bottom: 0;
    position: absolute;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }
  .searchBar.open {
    display: block;
  }
  .searchBar--wrapper {
    background: #f1f2f2;
    position: absolute;
    padding-top: 21px;
    width: 100%;
    top: 49px;
    z-index: 2001;
    left: auto;
  }
  .searchBar .arrow {
    border-bottom: 15px solid #f1f2f2;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    content: '';
    height: 0;
    margin-left: -6px;
    position: absolute;
    top: -9px;
    right: 15px;
    width: 0;
  }
  .searchBar .arrow:before {
    border-bottom: 15px solid #f1f2f2;
    border-left: 15px solid rgba(0, 0, 0, 0);
    border-right: 15px solid rgba(0, 0, 0, 0);
    content: '';
    height: 0;
    top: 0;
    left: -9px;
    margin-left: -6px;
    position: absolute;
    width: 0;
  }
  .searchModule .container {
    border-bottom: 0;
  }
  @media (min-width: 48rem) and (max-width: 63.9375rem) {
    .searchModule--wrapper {
      padding: 0 15px;
    }
  }
  @media (min-width: 64rem) {
    .searchModule--wrapper {
      padding: 0 22px;
    }
  }
  .searchModule--input {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-bottom: 1px solid #939598;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-bottom: 40px;
    padding-bottom: 7px;
    padding-top: 30px;
  }
  .searchModule--input,
  .searchModule--input-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .searchModule--input-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 17px;
  }
  .searchModule--input-icon .icon-search:before {
    font-size: 1.375rem;
  }
  .searchModule--input-txt {
    -webkit-box-flex: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
  }
  .searchModule--input-cta {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: none;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-left: 20px;
    padding-top: 20px;
  }
  .searchModule--input-cta .cta {
    font-size: 1rem;
    display: table;
    white-space: nowrap;
    border: 0;
    padding: 0;
  }
  .searchModule--input-cta .cta.disabled {
    color: #ffc22b;
    cursor: default;
  }
  .searchModule--input-cta .cta.disabled .icon:before {
    color: #ffc22b;
  }
  .searchModule--search {
    font-size: calc(0.64706rem + 3.67647vw);
    line-height: calc(0.70588rem + 5.14706vw);
    letter-spacing: 0.0625rem;
    width: 100%;
    border: 0;
    padding: 0;
  }
  .searchModule--popular {
    padding-bottom: 40px;
  }
  .searchModule--popular-items {
    display: block;
  }
  .searchModule--popular-item {
    margin-right: 0;
    margin-bottom: 0;
  }
  .searchModule--popular-item:not(:last-child) {
    margin-bottom: 20px;
  }
  .searchModule--popular h4 {
    margin-bottom: 20px;
  }
  .searchModule--autocomplete {
    width: 100%;
    background: #f1f2f2;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 100;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .searchModule input:-ms-input-placeholder {
    color: #b1b3b6;
  }
  .searchModule input:placeholder {
    color: #b1b3b6;
    opacity: 1;
  }
  .searchModule input {
    outline: none;
  }
  .searchModule input:focus {
    outline: none;
  }
  .searchModule input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  .searchModule input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  @media (min-width: 0) and (max-width: 47.9375rem) {
    .container {
      padding: 0 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
  @media (min-width: 0) and (max-width: 47.9375rem) {
    .searchBar--container {
      padding: 0;
    }
    .searchBar--close .icon-close-light {
      font-size: 0.9375rem;
    }
  }
  @media (min-width: 48rem) {
    .searchBar .arrow {
      right: 25px;
      top: -15px;
    }
    .searchBar--close {
      right: 30px;
    }
    .searchModule .container {
      border-bottom: 1px solid #d1d3d4;
    }
  }
  @media (min-width: 48rem) and (min-width: 48rem) and (max-width: 63.9375rem) {
    .searchModule--wrapper {
      padding: 0 15px;
    }
  }
  @media (min-width: 48rem) and (min-width: 64rem) {
    .searchModule--wrapper {
      padding: 0 22px;
    }
  }
  @media (min-width: 48rem) {
    .searchModule--input {
      padding-bottom: 10px;
      margin-bottom: 65px;
      padding-top: 70px;
    }
    .searchModule--input-icon {
      padding-right: 23px;
      padding-top: 7px;
    }
    .searchModule--input-cta {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-top: 17px;
    }
  }
  @media (min-width: 48rem) {
    .searchModule--popular {
      padding-bottom: 70px;
    }
  }
  @media (min-width: 48rem) and (max-width: 63.9375rem) {
    .searchBar--wrapper {
      top: 69px;
    }
    .searchBar--close {
      top: 29px;
    }
  }
  @media (min-width: 64rem) {
    .searchBar--wrapper {
      top: 89px;
    }
    .searchBar .arrow {
      right: 35px;
    }
    .searchBar--close {
      top: 37px;
      right: 40px;
    }
    .searchModule--input-icon {
      padding-right: 27px;
      padding-top: 8px;
    }
    .searchModule--popular-items {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .searchModule--popular-item:not(:last-child) {
      margin-right: 40px;
    }
  }
  @media screen and (min-width: 64rem) {
    .searchModule--search {
      line-height: 4rem;
      font-size: 3rem;
    }
    .searchModule--popular-item a {
      line-height: 2rem;
      font-size: 1.25rem;
    }
  }
  @media screen and (max-width: 30rem) {
    .searchModule--search {
      line-height: 2.25rem;
      font-size: 1.75rem;
    }
    .searchModule--popular-item a {
      line-height: 1.75rem;
      font-size: 1rem;
    }
  }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .searchModule--search {
      line-height: 0;
    }
  }
  body {
    background-image: url(https://picsum.photos/id/967/1280/1080);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .cool-link {
    display: inline-block;
    color: #ffc22b;
    text-decoration: none;
  }
  .cool-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #ffc22b;
    transition: width 0.3s;
  }
  li.show > .cool-link::after {
    width: 100%;
  }
  .cool-link:hover::after {
    width: 100%;
    /*transition: width .3s;*/
  }
  .navbar-sotreq {
    height: 80px;
    z-index: 99;
  }
  .header-logo {
    width: 166px;
  }
  .dropdown-toggle.active-dropdown::after {
    transform: rotate(-90deg);
  }
  .dropdown-menu {
    margin: 0px;
  }
  .dropdown-menu.show {
    display: flex;
    /*top: unset !important;*/
    left: 0 !important;
    width: 100%;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-radius: 0px !important;
  }
  a.dropdown-item.dropdown-toggle {
    /*padding: .5rem 1rem;*/
  }
  .img-menu-box {
    padding-right: 2em;
    border-right: 1px solid #d6d6d6;
  }
  a.dropdown-item.dropdown-toggle[aria-expanded='true'] {
    /*padding: .5rem 1rem;*/
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }
  #navbarNavDropdown > ul > li.nav-item.dropdown.show > ul > div > li.show > a {
    /*padding: .5rem 1rem;*/
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }
  .dropdown-item {
    color: #ffffff;
    padding: 0.5rem 1rem;
  }
  .dropdown-item:hover {
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }
  .dropdown-item-in {
    color: #000000;
    padding: 0.5rem 1rem;
  }
  .dropdown-item-in:hover {
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }
  .dropdown,
  di .dropleft,
  .dropright,
  .dropup {
    position: revert !important;
  }
  .navbar-expand-md .navbar-collapse {
    justify-content: center;
  }
  .navbar.navbar-expand-md.navbar-dark.navbar-sotreq {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.5202970095593544) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .navbar.navbar-expand-md.navbar-dark.navbar-sotreq.over {
    background: #ffffff !important;
    color: #000 !important;
  }
  .navbar-sotreq.over > .container > #navbarNavDropdown > ul > li > a {
    color: #000000;
  }
  .navbar-sotreq.over
    > .container
    > #navbarNavDropdown
    > ul
    > li.nav-item.dropdown.show
    > ul
    > div
    > li.show
    > ul
    > div
    > div
    > div
    > div
    > div
    > ul
    > li
    > a {
    color: #000000;
  }
  .navbar-sotreq.over
    > .container
    > #navbarNavDropdown
    > ul
    > li.nav-item.dropdown.show
    > ul
    > div
    > li.show
    > ul
    > div
    > div
    > div
    > div
    > div.pl-4.mx-0
    > ul
    > li
    > a {
    color: #000000;
  }
  .navbar-sotreq.over > .container > #navbarNavDropdown > ul > li.nav-item.dropdown.show > ul > div > li > a {
    color: #000000;
  }
  .navbar-sotreq.front-link {
    color: #ffffff;
  }
  .internal-link {
    color: #000000;
  }
  .navbar.navbar-expand-md.navbar-dark.navbar-sotreq:hover {
    background: #ffffff !important;
    color: #000000;
  }
  .navbar-sotreq:hover > .container > #navbarNavDropdown > ul > li > a {
    color: #000000;
  }
  .navbar-sotreq:hover > .container > #navbarNavDropdown > ul > li > a.nav-link:hover {
    color: #ffc22b !important;
    /*border-bottom: 2px solid #ffc22b;*/
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #ffc22b !important;
    background-color: unset;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #ffc22b;
    background-color: unset;
  }
  #navbarNavDropdown > ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  li.nav-item.dropdown.show > ul.dropdown-menu {
    border: 1px solid #d6d6d6;
    border-radius: 0px;
    padding-bottom: 1em;
    padding-top: 1em;
    /*margin: 1rem 0 0;*/
  }
  li.show > ul.dropdown-menu.show {
    border-top: 1px solid #d6d6d6;
    border-bottom: none;
    /*margin: 1rem 0 0;*/
  }
  a.internal-link > h6 {
    margin-bottom: 1.5em;
  }
  h6.internal-link {
    margin-bottom: 1.5em;
  }
  .upcaret {
    width: 3em;
  }
  .navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
  }
  a.internal-link.text-muted > h6 {
    padding-bottom: 1rem;
    margin-bottom: 0rem;
  }
  .dropdown-toggle::after {
    display: block;
    margin-left: unset;
    vertical-align: unset;
    content: '';
    border-top: unset;
    border-right: unset;
    border-bottom: unset;
    border-left: unset;
  }
  .lupa-busca {
    color: #ffc22b !important;
  }
  .termos-buscados {
    color: #ffc22b !important;
    text-transform: uppercase;
  }
  .w-sidebar {
    width: 200px;
    max-width: 200px;
    margin-top: 3em;
  }
  .vh-100 {
    min-height: 100vh;
  }
  .row.collapse {
    margin-left: -200px;
    left: 0;
    transition: margin-left 0.15s linear;
  }
  .row.collapse.show {
    margin-left: 0 !important;
  }
  .row.collapsing {
    margin-left: -200px;
    left: -0.05%;
    transition: all 0.15s linear;
  }
  .menu-spacer {
    position: absolute;
  }
  @media (max-width: 1000px) {
    .copy-text {
      font-size: 14px;
    }
    .blue-button {
      width: 100%;
      justify-content: center;
    }
    .download-button {
      max-width: unset;
    }
    .hero-logo {
      width: 65%;
    }
    .map-box {
      min-height: 10em;
      min-width: 22em;
    }
    .row.collapse,
    .row.collapsing {
      margin-left: 0 !important;
      left: 0 !important;
      overflow: visible;
    }
    .row > .sidebar.collapse {
      display: flex !important;
      margin-left: -100% !important;
      transition: all 0.3s linear;
      position: fixed;
      z-index: 1050;
      max-width: 0;
      min-width: 0;
      flex-basis: auto;
    }
    .row > .sidebar.collapse.show {
      margin-left: 0 !important;
      width: 100%;
      max-width: 100%;
      min-width: initial;
    }
    .row > .sidebar.collapsing {
      display: flex !important;
      margin-left: -10% !important;
      transition: all 0.3s linear !important;
      position: fixed;
      z-index: 1050;
      min-width: initial;
    }
    .hamburger {
      position: fixed;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
      background-color: #000;
    }
    .mob-brand {
      height: 56px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
    .main-menu {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .fa-bars {
      align-self: center;
      font-size: 2.333em !important;
      color: #fff !important;
    }
    .header-logo {
      width: 150px;
    }
    .mob-search {
      color: #fff;
      margin-right: 1em;
    }
    .w-sidebar {
      margin-top: 2em;
    }
    .lblue-bg {
      background-color: #ececec;
    }
    .mob-card {
      background-color: #ececec;
      color: #fff;
    }
    .mob-card-header {
      background-color: #ececec;
    }
    .btn-outline-warning {
      color: #ffc107 !important;
    }
    .btn-outline-warning:hover {
      color: #fffblz per !important;
    }
    .mob-internal-link {
      color: #000;
    }
    .btn.btn-link.btn-block.text-left.collapsed {
      color: #000000;
      font-size: 24px;
    }
    .btn.not(:disabled):not(.disabled) {
      color: #4a4a4a;
      background-color: #000c;
      font-size: 24px;
    }
    .btn.btn-link.btn-block.text-left.collapsed:focus {
      box-shadow: none;
      text-decoration: none;
    }
    .btn.btn-link.btn-block.text-left.collapsed:hover {
      box-shadow: none;
      text-decoration: none;
    }
    btn.focus,
    .btn:focus {
      outline: 0;
      box-shadow: none;
      text-decoration: none;
    }
    .btn.btn-link.btn-block.text-left {
      color: #4a4a4a;
      font-size: 24px;
    }
    .btn.btn-link.btn-block.text-left:hover {
      color: #4a4a4a;
      text-decoration: none;
      font-size: 24px;
    }
    .mob-menu-card-body {
      text-align: left;
    }
    .mob-menu-list {
      list-style: none;
      padding-inline-start: 15px !important;
    }
    .row.vh-100.no-gutters.d-flex.h-100.position-absolute.collapse.show {
      top: 0;
    }
  }
  @media (max-width: 538px) {
    .internal-link h6 {
      color: #ffffff;
    }
    li.nav-item {
      margin-bottom: 1em;
    }
  }
  .mainNav-collapsible .mobileNavOpen .mainNav--itemTitle {
    opacity: 1;
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
      -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1;
    display: block;
    position: relative;
  }
  .mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    position: relative;
    display: inline-block;
    font-size: 0.45rem;
    margin-left: 5px;
  }
  .mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    position: relative;
    display: inline-block;
    font-size: 0.45rem;
    margin-left: 5px;
  }
  i.fa.fa-angle-right {
    margin-left: 1em;
  }
  #collapsemObs1 {
    padding-left: 0.7em;
  }
  .btn.btn-link.btn-block.text-left.cool-link:not(.collapsed) > i.fa.fa-angle-right {
    transform: rotate(90deg);
  }
  .lblue-bg {
    overflow: scroll;
  }
</style>
