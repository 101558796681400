var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5 herohome"},[_c('div',{staticClass:"d-block d-sm-block d-md-block d-lg-none"},[_c('div',{staticClass:"carousel slide",attrs:{"id":"carouselExampleIndicatorsmob","data-ride":"carousel"}},[_c('ol',{staticClass:"carousel-indicators"},[_c('li',{staticClass:"nottraco active",attrs:{"data-target":"#carouselExampleIndicatorsmob","data-slide-to":"0"}}),_vm._l((_vm.data.filter((s, ind) => {
            return ind > 0;
          })),function(banner,i){return _c('li',{key:i,staticClass:"nottraco",attrs:{"data-target":"#carouselExampleIndicatorsmob","data-slide-to":i + 1}})})],2),_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active"},[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col text-left pb-5"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.data[0].image,"alt":"slide"}}),_c('div',{staticClass:"container"},[_c('h3',{staticClass:"text-uppercase yellowtext mt-5"},[_vm._v(" "+_vm._s(_vm.data[0].titulo)+" ")]),_c('h2',{staticClass:"text-white font-weight-bold text-uppercase w-75 mt-5"},[_vm._v(" "+_vm._s(_vm.data[0].subtitulo)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.data[0].texto)+" ")]),_c('button',{staticClass:"py-2 px-5 btn mt-2 mb-3 btn-secondary",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.data[0].buttontext)+" ")])])])])]),_vm._l((_vm.data.filter((s, ind) => {
            return ind > 0;
          })),function(banner,i){return _c('div',{key:i,staticClass:"carousel-item"},[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col text-left pb-5"},[_c('img',{staticClass:"img-fluid",attrs:{"src":banner.image,"alt":"slide"}}),_c('div',{staticClass:"container"},[_c('h4',{staticClass:"font-weight-bold mt-5"},[_vm._v(" "+_vm._s(banner.titulo)+" ")]),_c('h2',{staticClass:"text-white font-weight-bold text-uppercase w-75 mt-5"},[_vm._v(" "+_vm._s(banner.subtitulo)+" ")]),_c('p',[_vm._v(" "+_vm._s(banner.texto)+" ")]),_c('button',{staticClass:"py-2 px-5 btn mt-2 mb-3 btn-secondary",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(banner.buttontext)+" ")])])])])])})],2)])]),_c('div',{staticClass:"d-none d-md-none d-lg-block d-xl-block"},[_c('div',{staticClass:"carousel slide",attrs:{"id":"carouselExampleIndicators","data-ride":"carousel"}},[_c('ol',{staticClass:"carousel-indicators"},[_c('li',{key:0,staticClass:"traco active",attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"0"}}),_vm._l((_vm.data.filter((s, ind) => {
            return ind > 0;
          })),function(banner,i){return _c('li',{key:i,staticClass:"traco",attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":i + 1}})})],2),_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item hero-img active",style:('background-image: url(' + _vm.data[0].image + ');')},[_c('div',{staticClass:"h-100 d-flex container align-items-center"},[_c('div',{staticClass:"text-white row mt-5"},[_c('div',{staticClass:"col text-left"},[_c('h2',{staticClass:"text-uppercase yellowtext"},[_vm._v(" "+_vm._s(_vm.data[0].titulo)+" ")]),_c('h1',{staticClass:"text-white font-weight-bold text-uppercase w-75 mt-5"},[_vm._v(" "+_vm._s(_vm.data[0].subtitulo)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.data[0].texto)+" ")]),_c('button',{staticClass:"py-2 px-5 btn mt-2 btn-secondary",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.data[0].buttontext)+" ")])])])])]),_vm._l((_vm.data.filter((s, ind) => {
            return ind > 0;
          })),function(banner,i){return _c('div',{key:i,staticClass:"carousel-item hero-img",style:('background-image: url(' + banner.image + ');')},[_c('div',{staticClass:"h-100 d-flex container align-items-center"},[_c('div',{staticClass:"text-white row mt-5"},[_c('div',{staticClass:"col text-left"},[_c('h2',{staticClass:"text-uppercase yellowtext"},[_vm._v(" "+_vm._s(banner.titulo)+" ")]),_c('h1',{staticClass:"text-white font-weight-bold text-uppercase w-75 mt-5"},[_vm._v(" "+_vm._s(banner.subtitulo)+" ")]),_c('p',[_vm._v(" "+_vm._s(banner.texto)+" ")]),_c('button',{staticClass:"py-2 px-5 btn mt-2 btn-secondary",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(banner.buttontext)+" ")])])])])])})],2)])]),_c('div',{staticClass:"py-1",staticStyle:{"background-color":"#ffbb11"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }