<template>
  <div>
    <div class="row d-flex flex-row  justify-content-between justify-content-md-around">
      <a href="https://www.facebook.com/sotreqcat/" target="_blank">
        <div class="circle col-auto">
          <i class="fab fa-facebook-f"></i>
        </div>
      </a>
      <a href="https://www.instagram.com/sotreqcat/" target="_blank">
        <div class="circle col-auto">
          <i class="fab fa-instagram"></i>
        </div>
      </a>
      <a href="https://br.linkedin.com/company/sotreq-sa" target="_blank">
        <div class="circle col-auto">
          <i class="fab fa-linkedin-in"></i>
        </div>
      </a>
      <a href="https://twitter.com/sotreqcat" target="_blank">
              <div class="circle col-auto">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg>
              </div>
            </a>
            <a href="https://www.youtube.com/user/gruposotreqbr" target="_blank">
             <div class="circle col-auto">
                <i class="fab fa-youtube"></i>
              </div>
            </a>
        </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'FooterSocial'
}
</script>
<style scoped>
</style>
