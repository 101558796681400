<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="desatque my-4">
        <div class="row">
          <div class="col">
            <div class="card mb-3">
              <div :class="'row d-flex flex-md-row'">
                <div class="col-md-7 d-flex align-items-center">
                  <img :src="destaques[0].image" class="img-fluid" alt="Sotreq revendedora Caterpillar" />
                </div>
                <div
                  class="col-md-5 d-flex flex-column justify-content-center py-1"
                >
                  <div class="text-left align-items-center d-flex card-body">
                    <div class="d-flex justify-content-between flex-column">
                      <div>
                        <p class="">
                          {{ destaques[0].titulo }}
                        </p>
                        <div class="bar mb-5"></div>
                        <h5 class="font-weight-bold">
                          {{ destaques[0].subtitulo }}
                        </h5>
                        <p style="white-space: pre-line">
                          {{ destaques[0].texto }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4" v-for="(card, index) in cards" :key="index">
        <div class="col-9 text-left">
          <h5 class="font-weight-bold">
            {{ card.titulo }}
          </h5>

          <p style="white-space: pre-line" class="">
            {{ card.texto }}
          </p>
        </div>
      </div>
      <div class="my-4 d-flex justify-content-start row">
        <div class="col-auto">
          <img class="img-fluid" :src="bottomHeroImage" alt="Sotreq Aluguel" />
        </div>
        <div class="col d-flex align-items-center">
          <div class="d-flex justify-content-start">
            <div
              class="mt-3 col-md-auto mx-0 justify-content-center align-items-center"
            >
              <div class="par" style="max-height: 50vh; overflow-y: scroll">
                <button
                  class="h-100 py-2 btnamigo h-md-auto"
                  type="button"
                  data-toggle="collapse"
                  data-target="#filtro3"
                  aria-expanded="false"
                  aria-controls="filtro3"
                >
                  <div
                    class="inner row mx-0 px-0 justify-content-center align-items-center"
                  >
                    <div class="col-auto text-center">
                      <p class="my-auto">
                        Filiais com serviço de correspondente bancário do Banco
                        Caterpillar
                      </p>
                    </div>
                    <div class="inners col-auto align-items-center filtro text-center">
                      <i class="fa fa-arrow-down" style="font-size: 36px"></i>
                    </div>
                  </div>
                </button>

                <div
                  style="white-space: break-spaces"
                  id="filtro3"
                  class="w-100 collapse"
                >
                  <div
                    v-for="(novo, i) in this.filiais"
                    :key="i"
                    class="text-left dropdown-item"
                  >
                    <p
                      style="white-space: break-spaces"
                      v-for="(str, j) in novo.sim"
                      :key="j"
                    >
                      {{ str }}
                    </p>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import filiais from '@/services/filiais.js'

export default {
  name: 'Home',
  mounted () {
    this.loadContent()
    this.filiais = this.filiais.sort((a, b) => {
      const estadoA = a.sim[0].split('-')[1]
      const estadoB = b.sim[0].split('-')[1]
      console.log(estadoB)

      let comparison = 0
      if (estadoA > estadoB) {
        comparison = 1
      } else if (estadoA < estadoB) {
        comparison = -1
      }
      return comparison
    })
    console.log(this.filiais)
    this.filiais.push({
      sim: [
        `
  Canais de Comunicação - Banco Caterpillar:`,

        'Atendimento ao Cliente: 11 2109-2213',

        'Ouvidoria: 0800-722-7237 ouvidoria@cat.com',

        'Canal de Denúncia: 0800-892-0594'
      ]
    })
  },
  components: {
    Header,
    HeroMercados,
    Footer
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/93.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.destaques = resp.data.destaques
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.destaques = resp.data.destaques
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
};
    },
  },
  data () {
    return {
      filiais: filiais,
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      destaques: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
.btnamigo:not(.collapsed) > div div i.fa-arrow-down {
    transform: rotate(180deg);
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
</style>
