<template>
  <div class="mt-4 cardsmercado">
    <div class="mt-4 card-deck row row-cols-1 row-cols-md-3">
      <div class="mb-4" v-for="(card, i) in data" :key="i">
        <a :href="card.link">
          <div class="card h-100">
            <img :src="card.imagem" class="card-img-top img-fluid" />
            <div class="card-body">
              <h4 class="card-text">
                {{ card.titulo }}
              </h4>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardFale',
  props: {
    data: {
      type: Array,
      required: true
    },
    onlytitle: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    titleless: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>

<style scoped>
/* The hero image */
/* .maquina{
  cursor: pointer;
} */

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

img {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.maquina {
  cursor: pointer;
}
.card:hover h4 {
  color: #ffcb11;
}
.maquina:hover img {
  overflow: hidden;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
a {
  text-decoration: none;
  color: black;
}
.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img { max-height: 70vh; 
  height: calc(100vh - 80px) !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
</style>
