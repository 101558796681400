var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desatque mt-3 mb-1 mt-md-4 mt-md-4"},[_c('div',{staticClass:"d-block d-sm-block d-md-block d-lg-none"},[_c('div',{staticClass:"row px-0 mx-0"},[_c('div',{staticClass:"col px-0 mx-0"},[_c('div',{staticClass:"card mb-3 border-0"},[_c('div',{class:_vm.inverted
                ? 'row px-0 mx-0 d-flex flex-md-row-reverse'
                : 'row px-0 mx-0 d-flex'},[_c('div',{staticClass:"col-lg-6 d-flex flex-column justify-content-center py-0 px-0"},[_c('div',{staticClass:"text-left card-body d-flex flex-column justify-content-center pt-0 px-0"},[_c('div',{class:'d-flex justify-content-between flex-column'},[_c('div',{class:!this.inverted?'ml-md-4':' '},[(_vm.titulo)?_c('h2',{staticClass:"text-left fw-700"},[_vm._v(_vm._s(_vm.titulo))]):_vm._e(),(_vm.hasBar)?_c('div',{staticClass:"bar mb-0",staticStyle:{"height":"6px"}}):_vm._e(),(_vm.subtitulo)?_c('div',{staticClass:"fw-700 my-2"},[_vm._v(_vm._s(_vm.subtitulo))]):_vm._e(),_c('p',{staticClass:"mb-1 mt-3",style:({ whiteSpace: 'pre-wrap' })},[_vm._v(" "+_vm._s(_vm.text)+" ")])])])])]),_c('div',{staticClass:"col-lg-6 mx-0 px-0 d-flex align-items-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.image,"alt":"Sotreq revendedora Caterpillar"}})])])])])])]),_c('div',{staticClass:"d-none d-sm-none d-md-none d-lg-block"},[_c('div',{staticClass:"row px-0 mx-0"},[_c('div',{staticClass:"col px-0 mx-0"},[_c('div',{staticClass:"card mb-3 border-0"},[_c('div',{class:_vm.inverted
                ? 'row px-0 mx-0 d-flex flex-md-row-reverse'
                : 'row px-0 mx-0 d-flex'},[_c('div',{staticClass:"col-lg-6 mx-0 px-0 d-flex align-items-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.image,"alt":"Sotreq revendedora Caterpillar"}})]),_c('div',{class:_vm.inverted
                ? 'col-lg-6 d-flex flex-column justify-content-center py-1 pl-0  pr-0 pr-md-3'
                : 'col-lg-6 d-flex flex-column justify-content-center py-1  pl-0 pl-md-2'},[_c('div',{staticClass:"text-left card-body d-flex flex-column justify-content-center px-0",staticStyle:{"padding-top":"0px"}},[_c('div',{class:'d-flex justify-content-between flex-column'},[_c('div',{class:!this.inverted?'ml-md-4':' '},[(_vm.titulo)?_c('h2',{staticClass:"text-left fw-700"},[_vm._v(_vm._s(_vm.titulo))]):_vm._e(),(_vm.hasBar)?_c('div',{staticClass:"bar mb-1",staticStyle:{"height":"6px"}}):_vm._e(),(_vm.subtitulo)?_c('div',{staticClass:"fw-700 my-2"},[_vm._v(_vm._s(_vm.subtitulo))]):_vm._e(),_c('p',{staticClass:"mb-3 mt-4",style:({ whiteSpace: 'pre-wrap' })},[_vm._v(" "+_vm._s(_vm.text)+" ")])])])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }