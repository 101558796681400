<template>
  <div class="home">
    <Header />
    <HeroMineracaoPaginas :data="bannermercado" />
   <div class="container pt-5">
          <div class="row">
      <div class="col  text-left">
        <h2 class="font-weight-bold">{{ this.destaqueTitulo }}</h2>
        <div class="bar mb-3"></div>
      </div>
    </div>
      <div class="mb-4 row">
        <div class="col">
          <div class="mb-3">
            <div :class="'row d-flex flex-md-row'">
              <div
                class="col-md-12 d-flex flex-column justify-content-center py-1"
              >
                <div class="text-left">
                  <div class="d-flex justify-content-between flex-column">
                    <div>
                      <h3 class="text-uppercase font-weight-bold">
                      </h3>
                      <p style="; white-space: pre-line">
                        {{ destaqueTexto }}
                      </p>
                      <h4 class="text-uppercase font-weight-bold">
                        {{ destaqueChamada }}
                      </h4>
                      <p style="; white-space: pre-line">
                        {{ textoBaseTexto }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col text-left">
          <h3 class="text-uppercase font-weight-bold">
            {{ textoBaseTitulo }}
          </h3>
          <p style="; white-space: pre-line">
            {{ textoBaseTexto }}
          </p>
        </div>
      </div>

    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMineracaoPaginas from '@/components/HeroMineracaoPaginas.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'Home',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    HeroMineracaoPaginas,
    Footer

  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/136.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.destaques = resp.data.destaques
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem
        return s
      })
       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
};
    },
  },
  data () {
    return {
      destaques: [],
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: #ffcb11;
  color: black;
}

button:hover {
  border: 2px solid black;
  background-color: black;
  color: white;
}
</style>
