<template>
  <div class="mt-4 cardsmercado">
    <div class="mt-4 card-deck row row-cols-1 row-cols-md-2">
      <div class="mb-4" v-for="(card, i) in data" :key="i">
          <div class="card h-100 border-1">
            <img :src="card.image" class="card-img-top img-fluid" />
            <div class="card-body">
              <p >
                <h4 class="text-left"> {{ card.titulo }} </h4>
              <p class="text-left">{{ card.texto }}</p>
              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardSimple',
  props: {
    data: {
      type: Array,
      required: true
      // default: () => {
      //  return { image: "", titulo: "", subtitulo: "", text: "" };
      // },
    },
    inverted: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    buttonless: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>

<style scoped>
.limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
button {
  background-color: black;
  color: white;
  text-transform: uppercase;
}
.card-footer {
  border-top: none;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
a {
  text-decoration: none;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
