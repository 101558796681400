<template >
 <div v-bind:style="{backgroundColor: bgChosencolor}">
<div class="Mercados pt-1 pb-4">
    <div class="mt-4 container" >
      <div class="row">
        <div class="col-12 col-md-1">&nbsp;</div>
        <div class="col-12 col-md-10">
          <div class="row justify-content-center">
            <div class="col-auto">
              <h2 class="font-weight-bold">Opções de compras</h2>
            </div>
          </div>
          <div class="mt-3 mb-2 row justify-content-center">
            <div class="col-auto">
              <h4 class="font-weight-bold">
                Mais escolhas, mais conveniência, mais formas de comprar!
              </h4>
            </div>
          </div>

          <div class="mercadosgrid mt-5 d-flex flex-wrap">
            <div class="card-group">
              <div class="card compracard border-bottom marginside">
                <a href="https://parts.cat.com/pt/sotreq/" target="_blank" class="notalink">
                <i class="bi bi-cart-check-fill" style="font-size: 41px"></i>
                <div class="card-body nopadding">
                   <h5 class="p-2 px-md-0 font-weight-bold">Loja on-line de Peças</h5>
                  <p class="text-center">
                    Compre peças para os seus equipamentos com comodidade e
                    facilidade.
                  </p>
                </div>
                <div class="card-footer nofooter"></div>
                                  </a>
              </div>
              <div class="card compracard border-bottom marginside">
                <i class="bi bi-telephone-fill" style="font-size: 41px"></i>
                <div class="card-body nopadding">
                   <h5 class="p-2 px-md-0 font-weight-bold">Telefone</h5>
                  <p class="text-center">
                    Capital e Regiões Metropolitanas:<br />
                    <a data-v-1cc054d4="" href="tel:30031920"
        class="cleanlink">
                  11 3003 1920
                    </a><br />
                    Demais localidades:<br />
                    <a data-v-1cc054d4="" href="tel:08009401920" class="cleanlink">
                    0800 940 1920
                    </a>
                  </p>
                </div>
                <div class="card-footer nofooter"></div>
              </div>
              <div
                class="card compracard col border-bottom marginside"
              >
              <a href="/#/contato" class="notalink">
                <i
                  class="bi bi-chat-left-text-fill"
                  style="font-size: 41px"
                ></i>
                <div class="card-body nopadding">
                   <h5 class="p-2 px-md-0 font-weight-bold">Bate papo</h5>
                  <p class="text-center">
                    Prefere conversar conosco? <br />A Sotreq tem um
                    representante on-line esperando
                  </p>
                </div>
                <div class="card-footer nofooter"></div>
              </a>
              </div>
            </div>
          </div>
           <div class="mercadosgrid d-flex flex-wrap">
            <div class="card-group compragroup">
              <div class="card compracard pt-3  border-sm-bottom marginside">
                <a href="/#/filiais" class="notalink">
                <i class="bi bi-geo-alt-fill" style="font-size: 41px"></i>
                <div class="card-body nopadding">
                   <h5 class="p-2 px-md-0 font-weight-bold">Filiais</h5>
                <p class="text-center">
                  Encontre uma filial mais próxima e compre diretamente com a
                  Sotreq.
                </p>
                </div>
                <div class="card-footer nofooter"></div>
                                </a>
              </div>
              <div class="card compracard  pt-3 border-sm-bottom  marginside">
                <a href="/#/contato" class="notalink">
                <i class="bi bi-envelope-fill" style="font-size: 41px"></i>
                <div class="card-body nopadding">
                 <h5 class="p-2 px-md-0 font-weight-bold">E-mail</h5>
                <p class="text-center">
                  Envie-nos um e-mail e um representante respondera o mais rápido possível
                </p>
                </div>
                <div class="card-footer nofooter"></div>
                </a>
              </div>
              <div
                class="card compracard col    marginside pt-3"
              >
                <a href="/#/faleconosco" class="notalink">
                <i
                  class="bi bi-phone-fill"
                  style="font-size: 41px"
                ></i>
                <div class="card-body nopadding">
                 <h5 class="p-2 px-md-0 font-weight-bold">
                  WhatsApp e Telegram
                </h5>
                <p class="text-center">
                  Compre através do WhatsApp ou Telegram, direto do seu celular
                </p>
                </div>
                <div class="card-footer nofooter"></div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-1">&nbsp;</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Compra',
  props: {
    msg: String,
    bgcolor: String
  },
  data () {
    return {
      bgChosencolor: this.bgcolor
    }
  }
}
</script>

<style scoped>
.mercadosgrid div {
  cursor: pointer;
}
.marginside {
  border-right: 2px solid #dcdcde;
}
a {
  text-decoration: none;
}
img {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 95%;
  max-width: 95%;
}

.linkhover:hover img {
  overflow: hidden;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
img {
  height: auto;
  max-width: 80px;
  padding-bottom: 1em;
}
h4 {
  color: black;
}
.card-footer.nofooter {
  background-color: transparent !important;
  padding: 0px !important;
  border-top: none;
}
.compracard {
  border: none;
  border-radius: 0px !important;
  background-color: transparent !important;
}
.border-top {
  border-top: 1px solid #000;
  padding-top: 1em !important;
}
.border-bottom {
  border-bottom: 1px solid #000 !important;
}
.compracard:nth-child(2) {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

@media (max-width: 768px) {
  .border-sm-bottom {
    border-bottom: 1px solid #000 !important;
  }
  .compracard:nth-child(2) {
  border-left: none;
  border-right: none;
}
}
</style>
