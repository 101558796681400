<template>
  <div class="home" style="text-align: left !important;">
    <Header />
    <HeroIndustrial :data="bannermercado" />
    <div class="hspacer40"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="text-left font-weight-bold">
            Prontos para qualquer trabalho.
          </h1>
          <div class="hspacer20"></div>
          <p class="w80">
            Os motores industriais Cat estão disponíveis nas capacidades de 0,5L
            a 32L, para atender aos fabricantes de equipamentos do mundo todo.
            São motores que alimentam milhares de aplicações exclusivas dos
            principais OEMs (Fabricantes Originais de Equipamentos) e clientes
            finais, com informações técnicas, protótipos e validação de motores.
          </p>
          <div class="hspacer24"></div>
          <p class="w80">
            São motores prontos para o trabalho, projetados para alimentar desde
            as menores máquinas, até as mais pesadas, em qualquer ambiente e com
            desempenho incomparável. Com a Sotreq você tem apoio em cada etapa
            do desenvolvimento. Tudo isso com a confiabilidade de mais de 60
            filiais distribuídas em território nacional.
          </p>
          <div class="hspacer24"></div>
          <h6 class="font-weight-bold mb-0">Mercados em que atuamos:</h6>
          <ul>
            <li class="noidentlist">Mineração</li>
            <li class="noidentlist">Agricultura</li>
            <li class="noidentlist">Florestal, Resíduos e Reciclagem</li>
            <li class="noidentlist">Construção Leve e Industrial em geral</li>
            <li class="noidentlist">Manuseio de Materiais</li>
            <li class="noidentlist">Construção</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <h2 class="font-weight-bold mb-0 pt-0 mt-1">Motores Industriais Cat</h2>
      <div class="hspacer10"></div>
      <div class="bar mt-0"></div>
      <div class="hspacer12"></div>
      <h4 class="font-weight-bold mb-0">Soluções para vários mercados.</h4>
      <div class="hspacer24"></div>
      <p class="w80">
        No coração de uma grande operação, pulsa uma grande máquina. A
        confiabilidade, robustez e uptime não acontecem por acaso. Ela é o
        resultado de projetos comprovados, fabricação de qualidade e extensa
        validação em campo dos equipamentos nos principais segmentos econômicos
        do País, além do suporte premium mundialmente conhecido da rede de
        revendedores Cat.
      </p>
      <div class="hspacer28"></div>
      <Fancybuttons />
      <div class="hspacer32"></div>
      <cta />
      <div class="hspacer26"></div>
      <div class="container emissions-section">
        <div class="motor-header text-left">
          <h2 class="font-weight-bold mb-0 pt-0 mt-1">
            Motores industriais ao extremo
          </h2>
          <div class="hspacer10"></div>
          <div class="bar mt-1"></div>
          <div class="hspacer12"></div>
          <h4 class="emissions-subheading">
            Os desafios do nosso país, são nosso laboratório
          </h4>
          <div class="hspacer14"></div>
          <p class="emissions-text w80">
            Os motores industriais a diesel Cat são colocados à prova nas
            condições diárias mais extremas. Nos mais longínquos limites do
            Brasil, passando por calor, frio, umidade e secas, este é o mundo
            real em que nossos motores irão funcionar. Quando e onde você
            precisar.
          </p>
        </div>
        <div class="hspacer18"></div>
        <div class="row">
          <div class="col-12 col-md-9">
            <div class="motor-header text-left">
              <h2 class="font-weight-bold mb-0 pt-0 mt-1">
                Produzidos de acordo com sua necessidade.
              </h2>
              <div class="hspacer12"></div>
              <div class="bar mt-0"></div>
              <div class="hspacer12"></div>
              <h4 class="emissions-subheading">
                De acordo com as emissões globais.
              </h4>
              <div class="hspacer12"></div>
              <p class="emissions-text">
                Acompanhar a evolução das normas de emissões é um desafio que
                nossa equipe enfrenta a cada mudança. Trazer novas soluções que
                estejam em dia com as últimas regulamentações é a nossa
                especialidade para que você tenha uma ampla visão de como estas
                regulamentações afetam o seu setor. Nossa equipe de emissões
                sabe o que acontece no mundo todo para que você não precise se
                preocupar e tenha a tranquilidade que o seu motor está de acordo
                com os níveis de emissão de cada região.
              </p>
            </div>
          </div>
          <div
            class="col-12 col-md-3 d-flex justify-content-center justify-conten-md-end align-items-center align-items-md-end"
          >
            <img src="images/co2.png" class="co2icon" alt="" />
          </div>
        </div>
      </div>
      <div class="hspacer40"></div>
      <cta />
      <div class="hspacer32"></div>
      <div class="container emissions-section">
        <div class="motor-header text-left">
          <h2 class="font-weight-bold mb-0 pt-0 mt-1">
            Tudo o que você espera dos motores industriais Cat.
          </h2>
          <div class="hspacer12"></div>
          <div class="bar mt-0"></div>
          <div class="hspacer12"></div>
          <p class="emissions-text w80">
            Fique tranquilo. Os motores industriais Cat® podem ser aplicados em
            uma gama de equipamentos e máquinas que vão dar a você a cobertura
            que você precisa. Da construção, mineração, agricultura e, para o
            manuseio de materiais, resíduos e reciclagem, construção leve e
            aplicações industriais em geral. A lista de trabalhos aumenta e você
            pode contar com a Sotreq para levar a melhor solução até você.
          </p>
        </div>
      </div>
      <section class="motores-grid">
        <div class="hspacer24"></div>
        <h2 class="font-weight-bold text-center m-0">MOTORES CAT</h2>
        <div class="hspacer12"></div>
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          <div
            v-for="(card, i) in this.motores.slice(0, 8)"
            :key="i"
            class="col product-card"
          >
            <p class="product-model">Modelo</p>
            <div class="hspacer6"></div>
            <h2 class="product-title font-weight-bold mt-0">
              {{ card.modelo }}
            </h2>
            <div class="hspacer6"></div>
            <img class="product-image pt-0" :src="card.image" />
            <div class="product-specs">
              <div class="hspacer4"></div>
              <strong>Potência Máxima</strong>
              <div class="hspacer4"></div>
              {{ card.potencia_maxima }}
              <div class="hspacer6"></div>
              <strong>Torque Máximo</strong>
              <div class="hspacer4"></div>
              {{ card.torque_maximo }}
              <div class="hspacer6"></div>
              <strong>Emissões</strong>
              <div class="hspacer4"></div>
              {{ card.emissoes }}
            </div>
          </div>
        </div>
      </section>
      <div class="hspacer22"></div>
      <div class="w-100 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-toggle="modal"
          data-target="#motorModal"
        >
          Ver todos os motores
        </button>
      </div>
      <div class="hspacer20"></div>
      <h2 class="font-weight-bold text-center m-0">Mercados</h2>
      <div class="hspacer14"></div>
      <div v-for="(destaque, index) in destaques.slice(0,3  )" :key="index">
        <MediaRight v-if="index % 2 !== 0" :data="destaque" />
        <MediaLeft v-else :data="destaque" />
      </div>
            <div class="hspacer14"></div>
      <cta />
      <div class="hspacer14"></div>
  <div v-for="(destaque, index) in destaques.slice(3, 6)" :key="index">
        <MediaRight v-if="index % 2 == 0" :data="destaque" />
        <MediaLeft v-else :data="destaque" />
      </div>
      <div class="hspacer22"></div>
      <section id="tecnologia">
         <h2 class="font-weight-bold mb-0 pt-0 mt-1">Tecnologia que integra sistemas de hardware e software</h2>
      <div class="hspacer10"></div>
      <div class="bar mt-0"></div>
      <div class="hspacer12"></div>
          <p class="w80">
          A tecnologia dos motores industriais Cat® permite alcançar o máximo de performance, atendendo os padrões de emissões de cada região e com otimização de consumo.
          </p>
          <div class="hspacer24"></div>
          <p class="w80">
          As novas tecnologias produzidas todos os anos, com inúmeras horas de funcionamento, mostram a experiência e inovação tecnológica dos motores industriais. Com sistemas integrados, os motores oferecem total flexibilidade em várias faixas de potência para os diversos regimes de aplicação e integração aos mais diversos equipamentos. Fale com a Sotreq e encontre o motor industrial mais adequado à sua operação.
          </p>
                    <div class="hspacer24"></div>
          <p class="w80">
Você vai se surpreender com tudo o que os motores industriais Cat oferecem.
          </p>
                    <div class="hspacer24"></div>
      </section>
    </div>
    <div
      class="modal fade"
      id="motorModal"
      tabindex="-1"
      aria-labelledby="motorModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-xl dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content rounded-0">
          <div class="modal-header border-0">
            <h4 class="modal-title font-weight-bold" id="motorModalLabel">
              MOTORES CAT
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
             <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
          <div
            v-for="(card, i) in this.motores.slice(8, -1)"
            :key="i"
            class="col product-card"
          >
            <p class="product-model">Modelo</p>
            <div class="hspacer6"></div>
            <h2 class="product-title font-weight-bold mt-0">
              {{ card.modelo }}
            </h2>
            <div class="hspacer6"></div>
            <img class="product-image pt-0" :src="card.image" />
            <div class="product-specs">
              <div class="hspacer4"></div>
              <strong>Potência Máxima</strong>
              <div class="hspacer4"></div>
              {{ card.potencia_maxima }}
              <div class="hspacer6"></div>
              <strong>Torque Máximo</strong>
              <div class="hspacer4"></div>
              {{ card.torque_maximo }}
              <div class="hspacer6"></div>
              <strong>Emissões</strong>
              <div class="hspacer4"></div>
              {{ card.emissoes }}
            </div>
          </div>
        </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <h2 class="font-weight-bold text-center w-50 mx-auto" id="pageform">Preencha seus dados que a Sotreq entra em contato com você!</h2>
    <Footer :hasform="true" :buttonless="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HeroIndustrial from "@/components/Mercados/HeroIndustrial.vue";
import LineTextBlockExtendedText from "@/components/shared/LineTextBlockExtendedText.vue";
import CardsFiltragem from "@/components/CardsFiltragem.vue";
import Footer from "@/components/Footer.vue";
import ApiContent from "@/services/api.js";
import CardMotor from "../components/MotoresIndustriais/CardMotor.vue";
import Fancybuttons from "@/components/MotoresIndustriais/Fancybuttons.vue";
import MediaRight from "@/components/MotoresIndustriais/MediaRight.vue";
import MediaLeft from "@/components/MotoresIndustriais/MediaLeft.vue";
import Cta from "@/components/MotoresIndustriais/Cta.vue";

export default {
  name: "MotoresIndustriais",
  mounted() {
    this.loadContent();
    this.loadMotores();
  },
  components: {
    Header,
    HeroIndustrial,
    LineTextBlockExtendedText,
    CardsFiltragem,
    Footer,
    CardMotor,
    Fancybuttons,
    MediaRight,
    MediaLeft,
    Cta,
  },
  methods: {
    async loadMotores() {
      const engines = await ApiContent.get("motores.json");
      console.log(engines.data);
      //this.motores = engines.data
      this.motores = engines.data.map((motor) => {
        motor.modelo = motor.modelo;
        motor.imagem = motor.image;
        motor.torque = motor.torque_maximo;
        motor.potencia = motor.potencia_maxima;
        motor.emissoes = motor.emissoes;
        return motor;
      });
    },
    async loadContent() {
      const resp = await ApiContent.get("paginas/202.json");
      console.log(resp.data);
      this.heroTopTitulo = resp.data.heroTopTitulo;
      this.heroTopTexto = resp.data.heroTopTexto;
      this.heroTopLink = resp.data.heroTopLink;
      this.destaqueTitulo = resp.data.destaqueTitulo;
      this.destaqueTexto = resp.data.destaqueTexto;
      this.destaqueChamada = resp.data.destaqueChamada;
      this.textoBaseTitulo = resp.data.textoBaseTitulo;
      this.textoBaseTexto = resp.data.textoBaseTexto;
      this.heroBottomTitulo = resp.data.heroBottomTitulo;
      this.heroBottomTexto = resp.data.heroBottomTexto;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.bottomherobuttontext = resp.data.bottomherobuttontext;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.url = resp.data.url;
      this.topHeroImage = resp.data.topHeroImage;
      this.logo = resp.data.logo;
      this.bottomHeroImage = resp.data.bottomHeroImage;
      this.cards = resp.data.cards;
      this.destaques = resp.data.destaques;
      this.conteudo = resp.data.content;
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        content: resp.data.content,
        herocontent: resp.data.herocontent,
      };
    },
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      destaques: [],
      conteudo: [],
      bannermercado: [],
      motores: [],
    };
  },
};
</script>
<style>
.quadrado {
  border: 5px solid #ffcb11;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}

a {
  text-decoration: none;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}

.lineextended {
  margin-bottom: 1.2rem;
}

.motor-header {
  background-color: #fff;
}

.market-solution {
  margin-bottom: 1rem;
}

.market-solution h3 {
  font-weight: bold;
}

.market-solution-icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: #6c757d;
}

.icon-placeholder {
  width: 48px;
  height: 48px;
  background-color: #ddd;
  display: inline-block;
  margin-right: 10px;
  border-radius: 4px;
}

.solution-card {
  display: flex;
  align-items: center;
  border-left: 5px solid #ffcd00;
  /* Adjust color as needed */
  padding: 1rem;
  background-color: #f8f9fa;
  /* Light gray background */
}

.solution-title {
  font-size: 16px;
  /* Adjust size as needed */
  color: #333;
  /* Dark text color */
  margin: 0;
  align-self: center;
}

div.col.snip-a {
  margin-bottom: 16px;
}

.emissions-content {
  padding: 2rem;
  background-color: #f0f0f0;
}

.emissions-heading {
  color: #333;
  margin-bottom: 1rem;
}

.emissions-subheading {
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.emissions-text {
  margin-bottom: 2rem;
}

.co2icon {
  width: 252px;
  height: 217px;
  flex-shrink: 0;
}

.emissions-graphic {
  background-color: #d1e7dd;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #28a745;
  position: relative;
}

.emissions-graphic::before {
  content: "CO2";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.emissions-graphic::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #28a745;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.hero-title {
  color: var(--Base-Colors-White, #fff);
  font-family: Roboto;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 61.6px;
}

.hero-subtitle {
  color: var(--Base-Colors-White, #fff);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1400px;
  }
}

.modal.fade.show {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: flex !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  align-content: center;
  flex-wrap: wrap;
}

p {
  margin: 0px !important;
}

 div.col-12.col-md-6.media-content.h-100.d-flex.flex-column.justify-content-center div.content ul.text {
  padding-left: 0px !important;
 }

.hspacer4,
.hspacer6,
.hspacer8,
.hspacer10,
.hspacer12,
.hspacer14,
.hspacer16,
.hspacer18,
.hspacer20,
.hspacer22,
.hspacer24,
.hspacer26,
.hspacer28,
.hspacer32,
.hspacer34,
.hspacer36,
.hspacer40 {
  width: 100%;
  min-width: 100%;
  position: relative;
}

.spacer4 {
  height: 4px;
  min-height: 4px;
  max-height: 4px;
}
.hspacer6 {
  height: 6px;
  min-height: 6px;
  max-height: 6px;
}
.hspacer8 {
  height: 8px;
  min-height: 8px;
  max-height: 8px;
}

.hspacer10 {
  height: 10px;
  min-height: 10px;
  max-height: 10px;
}

.hspacer12 {
  height: 12px;
  min-height: 12px;
  max-height: 12px;
}

.hspacer14 {
  height: 14px;
  min-height: 14px;
  max-height: 14px;
}
.hspacer16 {
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}
.hspacer18 {
  height: 18px;
  min-height: 18px;
  max-height: 18px;
}
.hspacer18 {
  height: 18px;
  min-height: 18px;
  max-height: 18px;
}

.hspacer20 {
  height: 20px;
  min-height: 20px;
  max-height: 20px;
}

.hspacer22 {
  height: 22px;
  min-height: 22px;
  max-height: 22px;
}

.hspacer24 {
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}
.hspacer26 {
  height: 26px;
  min-height: 26px;
  max-height: 26px;
}
.hspacer28 {
  height: 28px;
  min-height: 28px;
  max-height: 28px;
}

.hspacer32 {
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.hspacer34 {
  height: 34px;
  min-height: 34px;
  max-height: 34px;
}

.hspacer36 {
  height: 36px;
  min-height: 36px;
  max-height: 36px;
}

.hspacer40 {
  height: 40px;
  min-height: 40px;
  max-height: 40px;
}

.w70 {
  width: 70%;
  min-width: 70%;
  max-width: 70%;
}

.w80 {
  width: 80%;
  min-width: 80%;
  max-width: 80%;
}

.w90 {
  width: 90%;
  min-width: 90%;
  max-width: 90%;
}

@media(max-width: 768px) {
  .w80 {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
  }
  .w70 {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
  }
  .w60 {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
  }
  .w90 {
    width: 90%;
    min-width: 90%;
    max-width: 90%;
  }
  p.w80 {
    line-height: 1.18 !important;
    }
  p.pt-4.pb-4 {
    font-size: 20px !important;
  
  }
  h1.text-left.font-weight-bold {
    line-height: 1.1;
}
.solution-title {
 align-self: center;
}
section.motores-grid div.row.row-cols-1.row-cols-md-2.row-cols-lg-4 {
  justify-content: center;
}
}
.noidentlist {
  margin-left: -17px;
  line-height: 22.4px;
}

@media (max-width: 1560px) {
  .container {
    width: 1320px !important;
    min-width: 1320px !important;
    max-width: 1320px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 755px) {
  .container {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    padding-right: 0px !important;
    padding-left: 15px !important;
  }
  .border-md-0 {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
  }

  .modal-body {
    height: 100vh;
  }

  .bg-mobnav {
    background-color: #000;
  }
}

@media (min-width: 758px) {
  .border-md-0 {
    border: none !important;
  }
}

.hbartitle {
  border-left: 5px solid #ffcd11;
  padding-left: 8px;
}

.w-100.card.card-body {
  height: 100vh;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

article.media {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.bg-yellow {
  background-color: #ffcd11 !important;
}

button.btn.button-yellow.snip-button.cta-button.mt-2.px-3 {
  max-width: 143px !important;
  min-width: 143px !important;
  text-align: center;
  display: flex;
  justify-content: center;
}

.bg-purple {
  background-color: #6f42c1;
}

.lh-100 {
  line-height: 1;
}

.lh-125 {
  line-height: 1.25;
}

.lh-150 {
  line-height: 1.5;
}

.motor-header {
  background-color: #fff;
}

.market-solution {
  margin-bottom: 1rem;
}

.market-solution h3 {
  font-weight: bold;
}

.market-solution-icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: #6c757d;
}

.cta-button {
  background-color: #ffcd00;
  border: none;
  padding: 0.5rem 1.5rem;
  font-weight: bold;
  color: #000;
  transition: background-color 0.3s;
  align-self: center;
}

.cta-button:hover {
  background-color: #e5b800;
}

.icon-placeholder {
  width: 48px;
  height: 48px;
  background-color: #ddd;
  display: inline-block;
  margin-right: 10px;
  border-radius: 4px;
}

.solution-card {
  display: flex;
  align-items: center;
  border-left: 5px solid #ffcd00;
  /* Adjust color as needed */
  padding: 1rem;
  background-color: #f8f9fa;
  /* Light gray background */
}

.solution-icon {
}

.solution-title {
  font-size: 16px;
  /* Adjust size as needed */
  color: #333;
  /* Dark text color */
  margin: 0;
}

a.col.snip-a {
  margin-bottom: 16px;
}

.emissions-content {
  padding: 2rem;
  background-color: #f0f0f0;
}

.emissions-heading {
  color: #333;
  margin-bottom: 1rem;
}

.emissions-subheading {
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.emissions-text {
  margin-bottom: 2rem;
}

.co2icon {
  width: 252px;
  height: 217px;
  flex-shrink: 0;
}

.emissions-graphic {
  background-color: #d1e7dd;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #28a745;
  position: relative;
}

.emissions-graphic::before {
  content: "CO2";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.emissions-graphic::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #28a745;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.product-card {
    border: 1px solid #d9d9d9;
    border-radius: 0px;
    padding: 1rem 1.2rem;
    margin: 5px 5px;
    max-width: 274px;
    height: 536px;
}

.product-image {
  margin-bottom: 0.1rem;
  width: 100%;
}
 div div div.w-100.py-0.mt-2.bg-yellow div.container h2.ml-0.pl-4.mt-2.font-weight-bold.text-left.snip-h2 {
  padding: 18px 0px;
 }

.product-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.product-specs,
.product-model {
  font-size: 16px;
  color: #252525;
  text-align: left;
}

.product-specs strong {
  display: block;
  color: #252525;
  margin-bottom: 8px;
  margin-top: 16px;
}

.hero-title {
  color: var(--Base-Colors-White, #fff);
  font-family: Roboto;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 61.6px;
}

.hero-subtitle {
  color: var(--Base-Colors-White, #fff);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

div.hero-img.active
  div.container.d-flex.align-items-center.text-left.h-100.p-0
  div.col-7.text-white.text-left {
  padding-top: 100px;
}
div.hero-img.active
  div.container.d-flex.align-items-center.text-left.h-100.p-0
  div.col-7.text-white.text-left
  div
  p.pt-4.pb-4
  div {
  font-size: 20px;
}
.button-yellow {
  max-width: 140px;
  max-height: 50px;
}

</style>
