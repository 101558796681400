import { render, staticRenderFns } from "./MaquinaDetalhe.vue?vue&type=template&id=a03bbe32&scoped=true"
import script from "./MaquinaDetalhe.vue?vue&type=script&lang=js"
export * from "./MaquinaDetalhe.vue?vue&type=script&lang=js"
import style0 from "./MaquinaDetalhe.vue?vue&type=style&index=0&id=a03bbe32&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a03bbe32",
  null
  
)

export default component.exports