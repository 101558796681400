<template>
  <div class="card-deck row row-cols-1 row-cols-md-3">
    <div class="mb-4" v-for="(treinamento, i) in data" :key="i">
      <div class="h-100 card border">
        <img
          class="card-img-top"
          :src="treinamento.avatar"
          alt="Sotreq revendedora Caterpillar"
        />
        <div class="card-body text-left">
          <h5 class="mb-3 card-title font-weight-bold">{{ treinamento.nome }}</h5>

          <p style="" class="mt-2 text card-text">
            {{ treinamento.detalhes }}
          </p>
        </div>
        <div class="bg-white card-footer">
          <a
            :href="
              '#/servicos/treinamentos-presenciais/treinamento/' +
              treinamento.id
            "
          >
            <button type="button" class="float-left px-5 btn btn-secondary">
              {{
                treinamento.buttontext ? treinamento.buttontext : "Saiba mais"
              }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardsTreinamentos',
  props: {
    data: {
      type: Array,
      required: true
    },
    onlytitle: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    titleless: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>

<style scoped>
/* The hero image */
/* .maquina{
  cursor: pointer;
} */

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

img {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 95%;
  max-width: 95%;
}
.maquina {
  cursor: pointer;
}
.maquina:hover p {
  color: #ffcb11;
}
.maquina:hover img {
  overflow: hidden;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
a {
  text-decoration: none;
  color: black;
}
.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img { max-height: 70vh; 
  height: calc(100vh - 80px) !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
</style>
