<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="row">
        <div class="col mt-4">
          <h1 class="ml-0 pl-0 mt-2 text-left font-weight-bold">
            {{ this.destaqueTitulo }}
          </h1>
          <h2 class="text-left underline-title">
            {{ this.destaqueTexto }}
          </h2>
          <div class="bar mb-1"></div>
        <h2 class="big-yellow-title pt-4">
            {{ this.destaqueChamada }}
        </h2>
         <h2 class="text-left underline-title pt-3">
            {{ this.textoBaseTexto }}
          </h2>
          <div class="bar mb-1"></div>
          <div class="py-5 render-content" v-html="this.content"></div>
          <h2 class="text-left underline-title pt-3">
            {{ this.textoBaseTitulo }}
          </h2>
          <div class="bar mb-1"></div>
          <p class="body-text pt-4 mt-2">
            {{ this.heroBottomTitulo }}
          </p>
          <p class="body-text-italic pt-4 mt-2">
            {{ this.heroBottomTexto }}
          </p>
      <div class="w-100 pt-2 d-flex justify-content-start">
      <button class="btn btn-dark mt-5">
      <a :href="this.heroBottomLink" class="notalink" target="_blank">
      Acesse o formulário
      </a>
      </button>
      </div>
      </div>

      </div>
     
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HeroMercados from "@/components/Mercados/HeroMercados.vue";
import Footer from "@/components/Footer.vue";
import ApiContent from "@/services/api.js";
import CardsTransmissao from "../components/CardsTransmissao.vue";

export default {
  name: "TecnicoAutorizado",
  mounted() {
    this.loadContent();
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    CardsTransmissao,
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get("paginas/181.json");
      console.log(resp.data);
      this.heroTopTitulo = resp.data.heroTopTitulo;
      this.heroTopTexto = resp.data.heroTopTexto;
      this.heroTopLink = resp.data.heroTopLink;
      this.content = resp.data.content;
      this.descricao = resp.data.descricao;
      this.destaqueTitulo = resp.data.destaqueTitulo;
      this.destaqueTexto = resp.data.destaqueTexto;
      this.destaqueChamada = resp.data.destaqueChamada;
      this.textoBaseTitulo = resp.data.textoBaseTitulo;
      this.textoBaseTexto = resp.data.textoBaseTexto;
      this.heroBottomTitulo = resp.data.heroBottomTitulo;
      this.heroBottomTexto = resp.data.heroBottomTexto;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.bottomherobuttontext = resp.data.bottomherobuttontext;
      this.heroBottomLink = resp.data.heroBottomLink;
      this.url = resp.data.url;
      this.topHeroImage = resp.data.topHeroImage;
      this.logo = resp.data.logo;
      this.bottomHeroImage = resp.data.bottomHeroImage;
      this.cards = resp.data.cards;
          this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      //this.cards.map(card => {
      //  card.link = '#/acessorio-inner/' + card.link
      // })
      };
    },
  },
  data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      content: '',
      descricao: '',
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
    };
  },
};
</script>
<style scoped>
.card {
  border: none;
}
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}

div.card:nth-child(6) > img:nth-child(1) {
  min-height: 450px;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.card-body {
  align-items: center;
  display: flex;
}
.card-img-overlay {
  position: relative !important;
}
@media (min-width: 648px) {
  .btn-block + .btn-block {
    margin-top: unset;
  }
}
</style>
