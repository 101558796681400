<template>
<div class="row row-cols-1 row-cols-md-2 row-cols-lg-4">
  <div class="col mb-4" v-for="(card, i) in data" :key="i">

  <div class="card bg-transparent border-0"  >
          <div class="card h-100 border-0">
            <img :src="card.image" class="card-img-top" style="max-height: 220px" />

            <div class="card-body">
                  <div class="bar mb-3  w-100"></div>
                 <h5 class="text-center">
               {{ card.titulo }}
              </h5>
              <p class="card-text">
</p>
            </div>
            <div class="card-footer bg-transparent border-0">
<p>                        {{ card.texto }}

              </p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconCardLine',
  props: {
    data: {
      type: Array,
      required: true
      // default: () => {
      //  return { image: "", titulo: "", subtitulo: "", text: "" };
      // },
    },
    inverted: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    buttonless: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>

<style scoped>
.bg-transparent {
  background-color: transparent !important;
}
.card {
   background-color: transparent !important;
}
</style>
