<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
       
    <div class="container">

      <div class="row">
        <div class="col mt-0 mt-lg-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 main-heading">
            {{ this.destaqueTitulo }}
          </h1>
               <p style="white-space: pre-line" class="mt-3  mb-0">
            {{ this.destaqueTexto }}
          </p>
        </div>
      </div>

  
    <DestaqueMineracao
      v-for="(item, index) in robits"
      :key="index"
      :image="item.image"
      :titulo="item.titulo"
      :subtitulo="item.subtitulo"
      :text="item.text"
      :hasBar="true"
        :inverted="index % 2 === 0"
      />
    </div>
    <Footer  :buttonless="false" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import CardSobrenos from '@/components/CardSobrenos.vue'
import Destaque from '@/components/Destaque.vue'
import HeroMercados from '@/components/HeroMineracaoPaginas.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Novidade from "@/components/Novidade.vue"
import DestaqueMineracao from '../../components/DestaqueMineracao.vue'


export default {
  name: 'TecnologiaSolucoesSotreqlink',
  mounted () {
    this.loadContent()
  },
  components: {
    Header,
    CardSobrenos,
    Destaque,
    HeroMercados,
    Footer,
    Novidade,
    DestaqueMineracao
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/201.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaques = resp.data.destaques
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
            this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem;
        return s;
      })
       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
       }
      this.robits = [
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZzhIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--12994bb59b3c21b51060dcc061684b7c4085e0e1/tophammer.png',
          titulo: 'Top Hammer',
          text: 'Cobre todas as aplicações em construção, pedreiras e mineradoras de superfície ou subterrâneas, desde a rocha mais macia até as condições rochosas mais difíceis, atendendo pedreiras e mineradores de pequeno, médio e grande porte.'
        },
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaEFIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--5b7f6f6cd61f7c196e8a856ff08fe791ea40a24c/downthehole.png',
          titulo: 'Down the hole',
          text: 'A linha Down the Hole abrange todas as operações relacionadas a esse método de perfuração. As principais categorias de produtos consistem em martelos DTH, Bits DTH e tubos DTH. São produtos de alta qualidade para todas as suas aplicações de perfuração de rochas, para a detonação ou perfuração de poços de água e poços de monitoramento.'
        },
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaEVIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ad1bbec75cb7ef99108407a21c1cc5a931c76563/geotec.png',
          titulo: 'Geotecnia',
          text: 'Uma linha concentrada em aplicações geotécnicas, particularmente ancoragem, microestacas e sustentação, estacas e perfuração de poços. As aplicações geotécnicas são relacionadas à perfuração feita antes ou como parte de um processo de construção.\n\nTambém oferecemos uma grande variedade de martelos DTH especializados, brocas e coroas, bem como sistemas de revestimento avançados para todas as necessidades.' 
        },
        {
          image: 'https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaElIIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3c3e7f7c0979b71487f54f15702e97797f57104d/estrategia.png',
          titulo: 'Estratégia',
          text: 'A estratégia da Robit se baseia no fornecimento de consumíveis de perfuração de alta performance e durabilidade, que através do suporte ao produto Sotreq, presente diariamente nas operações de seus clientes, resultam em um menor custo por metro perfurado, o que significa “perfurar mais gastando menos”, trazendo maior economia e produtividade para os clientes.\n\nSão bits, hastes, tubos, punhos, luvas e martelos de fundo, entre outros, aplicados em todos os modelos de equipamentos do mercado. Ferramentas reconhecidas e renomadas por sua alta durabilidade e produtividade, resultando em confiabilidade e eficácia. A sua gama de especialistas e presença em sete nações, juntamente com uma robusta rede de distribuição, habilita a venda dos produtos em mais de 100 países, projetando a Robit globalmente. Agora, todas essas vantagens se unem à Sotreq para oferecer ao mercado o que existe de melhor e mais tecnológico no segmento de perfuração de rochas.'
        }
]
    },
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaques: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
    }
  }
}
</script>
<style>

@media(max-width: 758px) {
  h1.font-weight-bold.display-4 {
    font-size: 30px !important;
  }
  h1.ml-0.pl-0.mt-2.main-heading {
    font-size: 32px !important;
  }
}
</style>
