import { render, staticRenderFns } from "./HeroIndustrial.vue?vue&type=template&id=56be021e&scoped=true"
import script from "./HeroIndustrial.vue?vue&type=script&lang=js"
export * from "./HeroIndustrial.vue?vue&type=script&lang=js"
import style0 from "./HeroIndustrial.vue?vue&type=style&index=0&id=56be021e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56be021e",
  null
  
)

export default component.exports