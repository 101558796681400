<template>
  <div class="home">
<!-- Messenger Plugin de bate-papo Code -->
   
    <Header />
    <HeroMercados :data="bannermercado"/>
    <div class="container">
      <div class="row mt-4">
        <div class="col text-left">
          <h2 class="font-weight-bold">{{ destaqueTitulo }}</h2>
          <p class="preline">{{ destaqueTexto }}</p>
          <div v-if="this.conteudo.length > 1">
        <div class="resetforcontent">
          <div v-html="this.conteudo"></div>
        </div>
      </div>
        </div>
      </div>
    
  <div class="container px-0 px-md-5">
          <div id="hubformum"></div>
</div>
    </div>
    <Footer :buttonless="true" />

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import LineTextBlock from '@/components/shared/LineTextBlock.vue'
import CardsFiltragem from '@/components/CardsFiltragem.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'

export default {
  name: 'ProjetosIncentivados',
  mounted () {
    this.loadContent();
    this.loadHubspotForm();
  },
  components: {
    Header,
    HeroMercados,
    LineTextBlock,
    CardsFiltragem,
    Footer
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/192.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards
      this.destaques = resp.data.destaques
      this.conteudo = resp.data.content
       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
};
    },
        loadHubspotForm() {
      // Load the HubSpot script
      let script = document.createElement('script');
      script.setAttribute('charset', 'utf-8');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', '//js.hsforms.net/forms/embed/v2.js');
      script.onload = this.initializeHubspotForm    
     document.head.appendChild(script);
    },
    initializeHubspotForm() {
      if (typeof hbspt !== 'undefined') {
        hbspt.forms.create({
          region: "na1",
          portalId: "20310230",
          formId: "e0b916bc-c2af-4e41-a659-c31e0e79b5a8",
          target: "#hubformum"
        });
      }
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      destaques: [],
      conteudo: [],
      bannermercado: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
.hbspt-form {
  display: block !important;
}
</style>
