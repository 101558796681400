          <template>
        
            <div class="product-card">
            <p class="product-model">
              Modelo</p>
              <h2 class="product-title font-weight-bold mt-1">{{ card.modelo }}</h2>
            <img class="product-image pt-1" :src="card.image" />
            <div class="product-specs">
              <strong>Potência Máxima</strong>
              {{ card.potencia_maxima }} 
              <strong>Torque Máximo</strong>
              {{ card.torque_maximo }}
              <strong>Emissões</strong>
              {{ card.emissoes }}
            </div>
          </div>

          </template>
    <script>
    export default {
        name: 'CardMotor',
        props: {
            data: {
            type: Array,
            required: true
            },
            onlytitle: {
            type: Boolean,
            default: () => {
                return false
            }
            },
            titleless: {
            type: Boolean,
            default: () => {
                return false
            }
            }
        }   
    }
    </script>
<style scoped>
.product-card {
    border: 1px solid #d9d9d9;
    border-radius: 0px;
    padding: 1rem 1.5rem;
    margin: 1rem;
    width: 318px;
    height: 536px;
}

.product-image {

    margin-bottom: 1rem;

}

.product-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: .5rem;
}

.product-specs, .product-model {
    font-size: 16px;
        color: #252525;
        text-align: left;
}

.product-specs strong {
    display: block;
    color: #252525;
    margin-bottom: 8px;
    margin-top: 16px;
   }

</style>      
