<template>
  <div class="mt-4 cardsmercado">
    <div class="mt-4  border-0">
<div class="card mb-3 text-left"  v-for="(card, i) in data" :key="i">
  <div class="row g-0">
    <div class="col-md-7">
      <img :src="card.imagem" class="img-fluid" alt="black friday sotreq">
    </div>
    <div class="col-md-5 align-self-center">
      <div class="card-body">
        <h1 class="card-title font-weight-bold">{{ card.titulo }}</h1>
        <p class="card-text">{{ card.texto }}</p>
      </div>
      <div class="card-footer bg-transparent border-0">
        <p class="card-text">{{ card.subtitulo }}</p>
      </div>
    </div>
  </div>
</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardHr',
  props: {
    data: {
      type: Array,
      required: true
    },
    onlytitle: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    titleless: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  }
}
</script>

<style scoped>
/* The hero image */
/* .maquina{
  cursor: pointer;
} */

button {
  border-radius: 0;
  border: 3px solid white;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

img {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.maquina {
  cursor: pointer;
}
.card:hover h4 {
  color: #ffcb11;
}
.maquina:hover img {
  overflow: hidden;
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
a {
  text-decoration: none;
  color: black;
}
.bar {
  margin-top: -20px;
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img { max-height: 70vh; 
  height: calc(100vh - 80px) !important;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
</style>
