import { render, staticRenderFns } from "./AcessorioInner.vue?vue&type=template&id=15867dc5&scoped=true"
import script from "./AcessorioInner.vue?vue&type=script&lang=js"
export * from "./AcessorioInner.vue?vue&type=script&lang=js"
import style0 from "./AcessorioInner.vue?vue&type=style&index=0&id=15867dc5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15867dc5",
  null
  
)

export default component.exports