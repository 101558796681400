<template>
    <div class="desatque mt-3 mb-1 mt-md-4 mt-md-4">
      <div class="d-block d-sm-block d-md-block d-lg-none">
<div class="row px-0 mx-0">
      <div class="col px-0 mx-0">
        <div class="card mb-3 border-0">
          <div
            :class="
              inverted
                ? 'row px-0 mx-0 d-flex flex-md-row-reverse'
                : 'row px-0 mx-0 d-flex'
            "
          >
          <div class="col-lg-6 d-flex flex-column justify-content-center py-0 px-0">
              <div class="text-left card-body d-flex flex-column justify-content-center pt-0 px-0">
                <div :class="'d-flex justify-content-between flex-column'">
                  <div :class="!this.inverted?'ml-md-4':' '">
                    <h2 v-if="titulo" class="text-left fw-700">{{ titulo }}</h2>
                    <div class="bar mb-0" style="height:6px" v-if="hasBar"></div>
                    <div class="fw-700 my-2" v-if="subtitulo">{{ subtitulo }}</div>
                    <p class="mb-1 mt-3" :style="{ whiteSpace: 'pre-wrap' }">
                      {{ text }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mx-0 px-0 d-flex align-items-center">
              <img :src="image" class="img-fluid" alt="Sotreq revendedora Caterpillar" />
            </div>
            
          </div>
        </div>
      </div>
    </div>

      </div>
      <div class="d-none d-sm-none d-md-none d-lg-block">
    <div class="row px-0 mx-0">
      <div class="col px-0 mx-0">
        <div class="card mb-3 border-0">
          <div
            :class="
              inverted
                ? 'row px-0 mx-0 d-flex flex-md-row-reverse'
                : 'row px-0 mx-0 d-flex'
            "
          >
            <div class="col-lg-6 mx-0 px-0 d-flex align-items-center">
              <img :src="image" class="img-fluid" alt="Sotreq revendedora Caterpillar" />
            </div>
            <div :class="
              inverted
                ? 'col-lg-6 d-flex flex-column justify-content-center py-1 pl-0  pr-0 pr-md-3'
                : 'col-lg-6 d-flex flex-column justify-content-center py-1  pl-0 pl-md-2'
            
            ">
              <div class="text-left card-body d-flex flex-column justify-content-center px-0" style="
  padding-top: 0px;">
                <div :class="'d-flex justify-content-between flex-column'">
                  <div :class="!this.inverted?'ml-md-4':' '">
                    <h2 v-if="titulo" class="text-left fw-700">{{ titulo }}</h2>
                    <div class="bar mb-1" style="height:6px" v-if="hasBar"></div>
                    <div class="fw-700 my-2" v-if="subtitulo">{{ subtitulo }}</div>
                    <p class="mb-3 mt-4" :style="{ whiteSpace: 'pre-wrap' }">
                      {{ text }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    titulo: String,
    text: String,
    subtitulo: String,
    inverted: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    hasBar: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
button {
  border-radius: none;
  border: 3px solid black;
  background-color: transparent;
  color: black;
}

.card {
  border: none;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
