<template>
  <div class="home">
    <Header />
        <div   class="herohome">
          <div   class="d-block d-sm-block d-md-block d-lg-none">
            <img   src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZzRHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a29929f4ebd503704adadd4fb4917321b4460fea/lubrificantes_cat.png" alt="HeroMercados" class="img-fluid">
            <div   class="container my-5 my-md-0 d-flex align-items-center text-left h-100">
              <div   class="text-black text-left">
                <h1   class="font-weight-bold display-4">
                Lubrificantes Cat®
                </h1>
                <div   class="bar"></div>
                   <h5 class="font-weight-bold">Menos Manutenção. Mais Dinheiro no seu bolso.</h5>
              </div>
            </div>
          </div>
          <div   class="d-none d-sm-none d-md-none d-lg-block">
 
            <div   class="hero-img active" style='background-image: url("https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZzRHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a29929f4ebd503704adadd4fb4917321b4460fea/lubrificantes_cat.png");'>
              <div   class="container d-flex align-items-center text-left h-100">
                <div   class="col-6 text-white text-left">
                  <h1   class="font-weight-bold display-4">
                  Lubrificantes Cat®
                  </h1>
                  <div   class="bar"></div>
                  <h5 class="font-weight-bold pt-5">Menos Manutenção. Mais Dinheiro no seu bolso.</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h2 class="ml-0 pl-0 mt-2 font-weight-bold">
            Não é pra ver a diferença. É pra sentir.
          </h2>
          <p style="white-space: pre-line" class="mt-3">
            Óleos parecem mesmo todos iguais. Não é possível enxergar a diferença entre eles. Mas espere só até comparar
            os resultados. Um sistema mais limpo, uma máquina com melhor desempenho e componentes que duram muito mais. É
            isso que os lubrificantes Cat podem fazer pela sua operação.
          </p>
        </div>
      </div>

      <div class="row my-5">
        <div class="col text-left">
          <h2 class="font-weight-bold">O desempenho da sua máquina começa na escolha do lubrificante</h2>
          <div class="bar mb-5"></div>
          <p class="preline">E essa escolha pode interferir, especialmente, na vida útil dos componentes da sua máquina.
            Os lubrificantes Cat excedem as especificações do setor e passam por testes exclusivos para garantir alto
            desempenho e consistência. O resultado é maior resistência à oxidação, com uma estrutura capaz de manter a
            viscosidade adequada e evitar o depósito de materiais sólidos.</p>
          <p class="font-weight-bold">
            Vantagens dos lubrificantes Cat
          </p>
          <ul>

            <li> Base premium e aditivos </li>
            <li>Aumenta intervalos de drenagem </li>
            <li>Mais desempenho para equipamentos Cat </li>
            <li>Reduz custos com manutenção</li>
          </ul>

          <h4 class="font-weight-bold text-center">
            Deseja tirar uma dúvida ou fazer uma cotação?

          </h4>

          <div class="d-flex justify-content-center">
            <a href="#button" type="button" class="btn btn-yellow botao">
              ENTRAR EM CONTATO
            </a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-7">
          <div class=" text-left">
            <h2 class="font-weight-bold">Lubrificantes Cat + Filtros Cat:<br /> o casamento perfeito</h2>
            <div class="bar mb-3"></div>
            <p class="preline">Quando os lubrificantes Cat são associados aos filtros do fabricante, o que se espera é uma
              redução significativa do
              custo total da operação do cliente.</p>
            <p class="font-weight-bold">
              Conheça nossa linha completa:
            </p>
            <ul>
              <li>Óleo de motor diesel (DEO™) </li>
              <li>Óleo de motor a gás natural (NGEO™) </li>
              <li>Óleo de transmissão & trem de acionamento (TDTO™/TDTO-TMS™) </li>
              <li>Óleo de transmissão final e de eixo (FDAO™) </li>
              <li>Óleo hidráulico (HYDO™ Advanced) </li>
              <li>Óleos especiais</li>

            </ul>
          </div>
        </div>
        <div class="col-md-5">
          <img
            src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ2tHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1b32faff05314ddd96f88f6f25b738eca904b66c/card1.png"
            class="img-fluid" alt="Sotreq Retiraq Locker" />
        </div>
      </div>
      <div class="container py-3">
        <h2 class="text-center font-weight-bold">
          Fluidos Cat®: a melhor escolha para as suas máquinas Cat
        </h2>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            src="https://youtube.com/embed/0E88prS5Q0Y"
          ></iframe>
        </div>
              <div class="container pt-4">
        <div class="row">
        <div class="col-md-7">
          <div class=" text-left">
            <h2 class="font-weight-bold">E as graxas Cat?</h2>
            <div class="bar mb-3"></div>
            <p class="preline">A principal função da graxa é lubrificar as partes móveis da máquina que não podem ser
              lubrificadas com óleo, peças expostas – Pino, bucha, engrenagem abertas, etc. Na Sotreq você encontra seis
              variedades projetadas para aplicações extremas, que protegem a máquina contra vazamentos, ferrugem, corrosão
              e diversos contaminantes. </p>
            <p class="font-weight-bold">
              Conheça nossa linha completa:
            </p>
            <ul>
              <li> Graxa de utilidades </li>
              <li> OGL (Open Gear Lubricant / Graxa para engrenagem aberta) </li>
              <li> Graxa de aplicação principal </li>
              <li> Graxa de aplicação extrema (1 e 2) </li>
              <li> Graxa de aplicação extrema (Deserto)</li>

            </ul>
          </div>
        </div>
        <div class="col-md-5">
          <img
            src="https://sitesotreq.manacadigital.com.br/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ29HIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2952b7055ad7f6b4611f8a19998d8e69869a8c65/card2.png"
            class="img-fluid" alt="Sotreq Retiraq Locker" />
        </div>
        <div class="col-12 my-4">
          <h4 class="font-weight-bold text-center">
            Deseja tirar uma dúvida ou fazer uma cotação?

          </h4>

          <div class="d-flex justify-content-center">
            <a href="#button" type="button" class="btn btn-yellow botao">
              ENTRAR EM CONTATO
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            Compre lubrificantes, graxas, e outros fluidos.
          </h1>
          <p style="white-space: pre-line" class="mt-3">
            Você também pode comprar em <a target="_blank" href="">Loja on-line de Peças</a> , de forma prática e segura e
            conte com a linha completa de produtos Cat.
          </p>
        </div>
      </div>
      <div class="row my-5">
        <div class="col text-left">
          <h2 class="font-weight-bold">Informações sobre garantia</h2>
          <div class="bar mb-5"></div>
          <p class="preline">Geralmente, a garantia para peças compradas em <a target="_blank" href=""> Loja on-line de
              Peças </a>2 é de 12 meses a partir da data da fatura, conforme definido na Garantia Limitada da Caterpillar.
            A Garantia das Peças abrange defeitos no material e de fabricação durante todo o período de Garantia uma vez
            instalada. Para obter todos os detalhes sobre a Garantia, entre em contato com a Sotreq.</p>
        </div>
      </div>
    </div>
          </div>
    <Compra bgcolor="#f5f5f5" />
    <h2 class="text-center font-weight-bold my-5">
      FAÇA A ESCOLHA CERTA E ENTRE <br /> EM CONTATO COM A SOTREQ
    </h2>
    <h5 class="text-center footer-closer" id="button">
      Preencha os formulário abaixo e entraremos em contato com você.
    </h5>
    </div>
    <Footer bgcolor="#f5f5f5" :buttonless="true" :hasform="true" gtmterm="pagina_lubrificantes" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Compra from '@/components/Compra.vue'

export default {
  name: 'Home',
  mounted() {
    this.loadContent()
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    Compra
  },
  methods: {
    async loadContent() {
      const resp = await ApiContent.get('paginas/51.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem
        return s
      })
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    }
  },
   data() {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}

.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(circle,
      rgba(2, 0, 36, 1) 0%,
      rgba(214, 155, 31, 1) 50%);
}

a {
  text-decoration: none;
}

button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}

a.btn.btn-yellow {
  background: #FFCD00 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
  padding: 11px 23px;
}

a.btn.btn-yellow:hover {
  background: #252525 0% 0% no-repeat padding-box;
  color: #fff !important;
}

div.card:nth-child(6) > img:nth-child(1) {
  min-height: 450px;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.card-body {
  align-items: center;
  display: flex;
}
.card-img-overlay {
  position: relative !important;
}


.bar {
  height: 5px;
  background-color: #ffcb11;
  width: 100px;
}
.traco {
  width: 100px;
  background-color: #ffcb11;
}
.traco.active {
  width: 100px;
  height: 10px;
}

.hero-img {
  height: 70vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 500px;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.herohome {
  margin-top: -80px;
  position: relative;
}
.hero-overlay {
  height: 100%;
  background-color: #000;
  position: absolute;
  width: 100%;
  opacity: 0.3;
}
.oneliner {
  white-space: nowrap !important;
}
.lh-sm {
  line-height: 62px !important;
}
@media (min-width: 648px) {
  .btn-block + .btn-block {
    margin-top: unset;
  }
}

</style>
