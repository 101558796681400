<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />

    <div class="container">
      <div class="mx-0 px-0 text-center text-md-left col-md">
        <div class="pr-0 mr-md-5 mt-5 mr-4">
          <h1 class="mr-md--5">{{ this.destaqueTitulo }}</h1>
          <p class="mr-md--5">{{ this.destaqueTexto }}</p>
        </div>
      </div>
      <div class="mt-4 card-deck row row-cols-1 row-cols-md-2">
        <div class="mb-4" v-for="(destaque, i) in destaques" :key="i">
          <Novidade :data="destaque" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import Dicionario from '@/services/dicionario.json'
import Novidade from '@/components/NovidadeMaquinas.vue'

export default {
  name: 'Home',
  mounted () {
    window.scrollTo(0, 0)
    this.loadContent()
  },
  components: {
    Header,
    Novidade,
    HeroMercados,
    Footer
  },
  methods: {
    async loadContent () {
      console.log(this.$route.params.str, 'rotaaaaaaaaaaaaaaaaaaaaaaa')
      const resp = await ApiContent.get(
        `paginas/${Dicionario[this.$route.params.str]}.json`
      )
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.created_at = resp.data.created_at
      this.updated_at = resp.data.updated_at
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards

      this.destaques = resp.data.destaques.map((s) => {
        s.titulo = s.titulo
        s.link = `#/categoria-acessorios/${s.link}`
        s.image = s.image
        s.buttontext = 'Saiba mais'
        return s
      })

       this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
};
    },
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: []
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
button {
  background-color: black;
  color: white;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
</style>
