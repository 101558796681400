<template>
  <div class="home">
    <Header />
    <HeroMercados :data="bannermercado" />
    <div class="container">
      <div class="row">
        <div class="col mt-4 text-left">
          <h1 class="ml-0 pl-0 mt-2 font-weight-bold">
            {{ this.destaqueTitulo }}
          </h1>
          <p style="white-space: pre-line" class="mt-3">
            {{ this.destaqueTexto }}
          </p>
        </div>
      </div>
      <div class="row">
      <div class="col-12 col-md-2">&nbsp;</div>
         <div class="col-12 col-md-8">
        <form class="mt-4" style="margin-bottom: 3vmax">
            <div class="form-group">
              <input
                v-model="NOME"
                placeholder="NOME *"
                type="text"
                class="inputnormal rounded-lg form-control"
              />
            </div>
            <div class="form-group">
              <input
                v-model="EMAIL"
                placeholder="E-MAIL *"
                type="text"
                class="inputnormal rounded-lg form-control"
              />
            </div>
            <div class="form-group">
              <input
                v-model="TELEFONE"
                placeholder="TELEFONE *"
                v-mask="['(##) ####-####', '(##) #####-####']"
                type="tel"
                class="inputnormal rounded-lg form-control"
              />
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <vSelect
                    class="style-chooser"
                    @input="handlecidades"
                    placeholder="ESTADO *"
                    :options="estados"
                    label="sigla"
                    v-model="estadochosen"
                  />
                </div>
                <div class="d-block d-sm-block d-md-block d-lg-none">
                  <div style="height: 4em">&nbsp;</div>
                </div>
                <div class="col-md-6 col-sm-12">
                  <vSelect
                    class="style-chooser"
                    placeholder="CIDADE *"
                    :options="cidades"
                    label="sigla"
                    v-model="cidadechosen"
                  >
                    <template #no-options="">
                      Escolha um Estado antes!
                    </template></vSelect
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <input
                v-model="EMPRESA"
                placeholder="EMPRESA"
                type="text"
                class="inputnormal rounded-lg form-control"
              />
            </div>
            <h4 class="text-left">Como você nos encontrou</h4>
            <div class="form-group">
              <v-select
                v-model="ORIGEM"
                :reduce="(option) => option.id"
                :options="[
                  { label: 'E-MAIL MARKETING', id: 'E-MAIL MARKETING' },
                  { label: 'FACEBOOK', id: 'FACEBOOK' },
                  { label: 'FEIRAS/EVENTOS', id: 'FEIRAS/EVENTOS' },
                  { label: 'GOOGLE', id: 'GOOGLE' },
                  { label: 'INDICAÇÃO', id:'INDICAÇÃO' },
                  { label: 'INSTAGRAM', id: 'INSTAGRAM' },
                    { label: 'LINKEDIN', id: 'LINKEDIN' },
                  { label: 'OUTROS', id: 'OUTROS' },
                ]"
                class="style-chooser"
                placeholder="ESCOLHA *"
              />
            </div>

<!--  <div class="form-group">
              <v-select
                v-model="ASSUNTO"
                :reduce="(option) => option.id"
                :options="[
                  { label: 'Dúvida/Informação', id: 'Dúvida/Informação' },
                  { label: 'Crítica/Reclamação', id: 'Crítica/Reclamação' },
                  { label: 'Elogio', id: 'Elogio' },
                  { label: 'Solicitação', id: 'Solicitação' },
                ]"
                class="style-chooser"
                placeholder="ASSUNTO *"
              />
            </div> -->
            <div class="form-group">
              <textarea
                v-model="MENSAGEM"
                style="color: black; border: 1px solid #9a9a9a"
                class="inputnormal form-control rounded-lg"
                placeholder="SUA MENSAGEM *"
                id="exampleFormControlTextarea1"
                rows="5"
              ></textarea>
            </div>
            <div class="form-actions">
              <div class="form-actions-inner">
                <div class="form-group text-left">CAMPOS OBRIGATÓRIOS*</div>
                <p class="font-weight-bold text-uppercase text-left">
                  preferência de contato por:
                </p>
                <div class="mb-4 row justify-content-start">
                  <div class="col-md-auto mt-2 mt-md-0">
                    <div class="d-flex flex-row">
                      <p class="mr-2 my-auto text-uppercase">Telefone</p>
                      <input v-model="tel" class="my-auto" type="checkbox" />
                    </div>
                  </div>
                  <div class="col-md-auto mt-2 mt-md-0">
                    <div class="d-flex flex-row">
                      <p class="mr-2 my-auto text-uppercase">Whatsapp</p>
                      <input v-model="whats" class="my-auto" type="checkbox" />
                    </div>
                  </div>
                  <div class="col-md-auto mt-2 mt-md-0">
                    <div class="d-flex flex-row">
                      <p class="mr-2 my-auto text-uppercase">E-mail</p>
                      <input v-model="emm" class="my-auto" type="checkbox" />
                    </div>
                  </div>
                </div>

                <div v-if="sended">
                  <div class="d-flex justify-contend-end">
                    <div class="alert alert-success" role="alert">Enviado!</div>
                  </div>
                </div>
                <div v-if="!valido">
                  <div class="d-flex">
                    <div class="alert alert-danger" role="alert">
                      Exitem informações obrigatórias não preenchidas
                    </div>
                  </div>
                </div>
                <button
                  @click="sendcontact"
                  type="button"
                  :disabled="sended"
                  class="mt-4 btnsend btn btn-secondary"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
          </div>
             <div class="col-12 col-md-2">&nbsp;</div>
          </div>
     </div>
    <Footer :buttonless="true" />
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
import ApiContato from '@/api/apiContato.ts'
import Header from '@/components/Header.vue'
import HeroMercados from '@/components/Mercados/HeroMercados.vue'
import Footer from '@/components/Footer.vue'
import ApiContent from '@/services/api.js'
import estadosCidades from '@/api/estadosCidades.json'
import estados from '@/api/estados.json'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { mask } from 'vue-the-mask'
Vue.component('v-select', vSelect)

export default {
  directives: { mask },
  name: 'Home',
  mounted () {
    this.loadContent()
    this.estados = estados
  },
  components: {
    Header,
    HeroMercados,
    Footer,
    vSelect
  },
  methods: {
    async loadContent () {
      const resp = await ApiContent.get('paginas/57.json')
      console.log(resp.data)
      this.heroTopTitulo = resp.data.heroTopTitulo
      this.heroTopTexto = resp.data.heroTopTexto
      this.heroTopLink = resp.data.heroTopLink
      this.destaqueTitulo = resp.data.destaqueTitulo
      this.destaqueTexto = resp.data.destaqueTexto
      this.destaqueChamada = resp.data.destaqueChamada
      this.textoBaseTitulo = resp.data.textoBaseTitulo
      this.textoBaseTexto = resp.data.textoBaseTexto
      this.heroBottomTitulo = resp.data.heroBottomTitulo
      this.heroBottomTexto = resp.data.heroBottomTexto
      this.heroBottomLink = resp.data.heroBottomLink
      this.bottomherobuttontext = resp.data.bottomherobuttontext
      this.heroBottomLink = resp.data.heroBottomLink
      this.url = resp.data.url
      this.topHeroImage = resp.data.topHeroImage
      this.logo = resp.data.logo
      this.bottomHeroImage = resp.data.bottomHeroImage
      this.cards = resp.data.cards.map((s) => {
        s.image = s.imagem
        return s
      })
      this.bannermercado = {
        titulo: resp.data.heroTopTitulo,
        image: resp.data.topHeroImage,
        subtitulo: resp.data.subtitulo,
        texto: resp.data.texto,
        link: resp.data.link,
        buttontext: resp.data.buttontext,
        herocontent: resp.data.herocontent
      }
    },
    async sendcontact () {
      this.$gtm.trackEvent({
        event: event || 'interaction',
        category: 'Lead',
        action: 'click',
        label: 'form submit st sotreq pecas usadas',
        value: 'form submit st sotreq pecas usadas',
        noninteraction: false // Optional
      })
      let bool = true
      if (!this.estadochosen.nome) {
        console.log(this.estadochosen, 'estadochosen')

        bool = false
      }
      if (!this.cidadechosen[0]) {
        console.log(this.cidadechosen, 'cidadechosen')
        bool = false
      }
      if (this.MENSAGEM === '') {
        console.log(this.MENSAGEM, 'MENSAGEM')
        bool = false
      }
      if (this.NOME === '') {
        console.log(this.NOME, 'NOME')
        bool = false
      }
      if (this.EMAIL === '') {
        console.log(this.EMAIL, 'EMAIL')
        bool = false
      }

      if (this.TELEFONE === '') {
        console.log(this.TELEFONE, 'TELEFONE')
        bool = false
      }
      if (this.ORIGEM === '') {
        console.log(this.ORIGEM, 'ORIGEM')
        bool = false
      }

      if (bool) {
        let str = ''
        if (this.emm) {
          str += 'E-mail '
        }
        if (this.tel) {
          str += ' Telefone '
        }
        if (this.whats) {
          str += ' WhatsApp '
        }
        const obj = {
          estado: this.estadochosen.nome,
          cidade: this.cidadechosen,
          nome: this.NOME,
          email: this.EMAIL,
          empresa: this.EMPRESA,
          mensagem: this.MENSAGEM,
          preferenciacontato: str,
          telefone: this.TELEFONE,
          assunto: 'Lead Site Sotreq - Peças Usadas',
	  origem: this.ORIGEM
        }
        console.log(obj)
        this.valido = true
        const resp = await ApiContato.post('contatos.json', { ...obj })
        console.log(resp)
        if (resp.status === 201) {
          this.sended = true
          console.log(resp.body)
          this.$router.push({ name: 'FormularioPecasUsadasEnviado' })
        } else {
          this.sended = false
        }
      } else {
        this.valido = false
      }
    },
    handlecidades (e) {
      if (e) {
        this.cidadechosen = []
        this.cidades = estadosCidades.estados[e.id].cidades
        console.log(this.cidades)
      } else {
        this.cidadechosen = []
        this.cidades = []
      }
    }
  },
  data () {
    return {
      heroTopTitulo: [],
      heroTopTexto: [],
      heroTopLink: [],
      destaqueTitulo: [],
      destaqueTexto: [],
      destaqueChamada: [],
      textoBaseTitulo: [],
      textoBaseTexto: [],
      heroBottomTitulo: [],
      heroBottomTexto: [],
      heroBottomLink: [],
      created_at: [],
      updated_at: [],
      url: [],
      topHeroImage: [],
      logo: [],
      bottomHeroImage: [],
      cards: [],
      estados: [],
      cidades: [],
      estadochosen: [],
      cidadechosen: [],
      MENSAGEM: '',
      valido: true,
      whats: 0,
      tel: 0,
      verified: false,
      emm: 0,
      NOME: '',
      sended: false,
      EMAIL: '',
      TELEFONE: '',
      EMPRESA: '',
      ASSUNTO: ''
    }
  }
}
</script>
<style scoped>
.quadrado {
  border: 5px solid #ffcb11;
}
.equipamento_nvo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(214, 155, 31, 1) 50%
  );
}
a {
  text-decoration: none;
}
button {
  border: 2px solid white;
  background-color: black;
  color: white;
}

button:hover {
  border: 2px solid black;
  background-color: white;
  color: black;
}
.btnsend {
  text-transform: uppercase;
  background-color: #f7b220;
  border: #f7b220;
  color: black;
  padding: 0.9em 2em;
  border-radius: 0.6em;
}

.btnsend:hover {
  color: #f7b220;
  background-color: #000;
}
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}

.upimg {
  margin-top: -5vmax;
}
.wbold {
  font-weight: bolder;
  color: white;
}

.wnormal {
  font-weight: normal;
  color: white;
}
.pdf-button {
  background-color: #f7b220;
  color: #000000;
  text-decoration: none !important;
}
.pdf-button:hover {
  background-color: #9a9a9a;
}

.yellowdiv {
  height: 31.5vmax;
  margin-right: -4vmax;
  width: 6vmax;
  background-color: #f7b221;
}
.filled {
  background-color: #ab0718;
}
.imagem {
  height: 30vmax;
}
.inputnormal {
  font-size: 16px;
  color: black;
  border: 1px solid #9a9a9a;
}

@media only screen and (min-width: 20px) {

  .innertext {
    font: 1.4em;
    font-weight: 400;
  }

}

/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {

  .innertext {
    font: 1.4em;
    font-weight: 400;
  }

  .mob-contato-hero {
    width: unset;
    height: unset;
  }
}

/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {

  .innertext {
    font: 1.4em;
    font-weight: 400;
  }
  /* .bar {
    margin-bottom: 2vmax;
    margin-top: -0vmax;
    height: 1.2em;
    width: 70%;
    background-color: #ab0718;
  } */

}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {
  .icons i {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 2vmax;
  }
  /* .icons span {
    font-size: 2em;
  } */
  .innertext {
    font: 1.4em;
    font-weight: 400;
  }
}

.form-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
</style>
