const top = [
  {
    sim: [
        `MACAÉ - RIO DE JANEIRO
        `,
        `
  Av. Mota Coqueiro, 187 Quadra C - Lote 1-A Loteamento Cabiúnas - Cabiúnas
  `,
        `
  27915-012
  `,
        `
  (022) 2105-7901
  `
    ]
  },
  {
    sim: [
        `
    ARAÇATUBA - SÃO PAULO
    `,
        `
  Av. Saudade, 2634 Iporã
  `,
        `
  16021-520
  `,
        `
  (018) 3631-2202
  `
    ]
  },
  {
    sim: [
        `
  
  
  SANTOS - SÃO PAULO
  `,
        `
  Rua Campos Mello 468 Encruzilhada 
  `,
        `
  11015-012
  `,
        `
  (013) 3222-4900
  `
    ]
  },
  {
    sim: [
        `
    RIO DE JANEIRO - RIO DE JANEIRO
    `,
        `
  Av. Brasil, 7.200 - Bonsucesso
  `,
        `
  21030-001
  `,
        `
  (21) 3865-7722
  `
    ]
  },
  {
    sim: [
        `
    SERRA - ESPÍRITO SANTO
    `,
        `
  Av. Central, 96, Civit II - Lote 6, Quadra VII
  `,
        `
  29161-205
  `,
        `
  (27) 3398-1100
  `
    ]
  },
  {
    sim: [
        `
    GOIÂNIA - GOIÁS
    `,
        `
  Av. Meia Ponte, 3.080
  `,
        `
  74985-115
  `,
        `
  (62) 3265-6000
  `
    ]
  },
  {
    sim: [
        `
    UBERLÂNDIA - MINAS GERAIS
    `,
        `
  Av. Paulo Roberto Cunha Santos, 1331
  `,
        `
  38402-266
  `,
        `
  (34) 3236-6300
  `
    ]
  },
  {
    sim: [
        `
    BRASÍLIA - DISTRITO FEDERAL 
    `,
        `
  Av. Sia Sul, Guara - Trecho 04, Lote 370, Loja 02
  `,
        `
  71200-970          
  `,
        `
  (61) 3203-5800
  `
    ]
  },
  {
    sim: [
        `
    BELÉM - PARÁ
    `,
        `
  Avenida Almirante Barroso, 3864 - Souza                    
  `,
        `
  66630-505
  `,
        `
  (91) 3211-9500
  `
    ]
  },
  {
    sim: [
        `
    SERRA 2 - ESPÍRITO SANTO
    `,
        `
  Rod Br 101, Norte - S/N, Km 279 Parte - Tims
  `,
        `
  29161-382
  `,
        `
  (27) 3398-1100
  `
    ]
  },
  {
    sim: [
        `
    GOVERNADOR VALADARES - MINAS GERAIS
    `,
        `
  Rua Lateral, 298 - Azteca
  `,
        `
  350422-200
  `,
        `
  (33) 3202-7300
  `
    ]
  },
  {
    sim: [
        `
    PALMAS - TOCANTINS
    `,
        `
  Av. Acsu - So 60 Conj.01 S/N, Lote 16 - Centro
  `,
        `
  77016-524
  `,
        `
  (63) 3234-1790
  `
    ]
  },
  {
    sim: [
        `
    CONTAGEM - MINAS GERAIS
    `,
        `
  Via Gastão Camargos, 850 - Perobas
  `,
        `
  32371-630
  `,
        `
  (31) 3359-6000
  `
    ]
  },
  {
    sim: [
        `
    ARAÇATUBA - SÃO PAULO
    `,
        `
  Av. Anhanguera, 3125 - Jardim Do Prado
  `,
        `
  16025-460
  `,
        `
  (18) 2102-7900
  `
    ]
  },
  {
    sim: [
        `
    SÃO PAULO - SÃO PAULO
    `,
        `
  Rua Santo Eurilo, 296, Parte A - Jaguaré
  `,
        `
  5345-040
  `,
        `
  (19) 3718-5000
  `
    ]
  },
  {
    sim: [
        `
    RIBEIRÃO PRETO - SÃO PAULO
    `,
        `
  Rua Caraguatatuba, 3600 - Joquei Club
  `,
        `
  14078-030
  `,
        `
  (16) 2133-2525
  `
    ]
  },
  {
    sim: [
        `
    SANTOS - SÃO PAULO
    `,
        `
  Avenida Conselheiro Rodrigues Alves , 206 - Macuco
  `,
        `
  11015-200
  `,
        `
  (13) 3222-4900
  `
    ]
  },
  {
    sim: [
        `
    SUMARÉ - SÃO PAULO
    `,
        `
  Rodovia Anhanguera, S/N, Km 111,5 - Jardim Manchester
  `,
        `
  13178-447
  `,
        `
  (19) 3864-6400
  `
    ]
  },
  {
    sim: [
        `
    SÃO JOSÉ DOS CAMPOS - SÃO PAULO
    `,
        `
  Rua Araguaia, 130 - Vila São Bento
  `,
        `
  12231-380
  `,
        `
  (12) 3921-6800
  `
    ]
  },
  {
    sim: [
        `
    BAURU - SÃO PAULO
    `,
        `
  Rua Dionisio De Aguiar, 156, Lote 1-2-11 - Jardim Contorno
  `,
        `
  17047-150
  `,
        `
  (14) 3203-3717
  `
    ]
  },
  {
    sim: [
        `
    SÃO JOSÉ DO RIO PRETO - SÃO PAULO
    `,
        `
  Av. Percy Gandini, 1785 - Vila Toninho
  `,
        `
  15077-000
  `,
        `
  (17) 2138-8500
  `
    ]
  },
  {
    sim: [
        `
    CAMPO GRANDE - MATO GROSSO DO SUL
    `,
        `
  Rodovia Br 163, 5592 - Jardim Monumento
  `,
        `
  79064-000
  `,
        `
  (67) 3303-1818
  `
    ]
  },
  {
    sim: [
        `
    CUIABÁ - MATO GROSSO
    `,
        `
  Av. Fernando Correa Da Costa, 7218 - Vista Alegre
  `,
        `
  78090-000
  `,
        `
  (65) 2121-1400
  `
    ]
  },
  {
    sim: [
        `
    MANAUS - AMAZONAS
    `,
        `
  Av. Max Teixeira, 95 - Flores
  `,
        `
  69058-415
  `,
        `
  (92) 3183-7600
  `
    ]
  },
  {
    sim: [
        `
    PORTO VELHO - RONDÔNIA
    `,
        `
  Rodovia Br 364, S/N, Km 3,5 - Lote 993 - Aeroclube
  `,
        `
  76806-659
  `,
        `
  (69) 2181-8400
  `
    ]
  },
  {
    sim: [
        `
    RIO BRANCO - ACRE 
    `,
        `
  Via Chico Mendes, 884 - Triângulo Velho
  `,
        `
  69906-210
  `,
        `
  (68) 3227-7595
  `
    ]
  },
  {
    sim: [
        `
    IMPERATRIZ - MARANHÃO`,
        `
  R. Coronel Mario Andreaza, 700 - Três Poderes
  `,
        `
  65903-210
  `,
        `(99) 3221-8700
  `
    ]
  },
  {
    sim: [
        `
    JABOATÃO DOS GUARARAPES - PERNANBUCO
    `,
        `Rod Br 101, 1590 - Comportas
  `,
        `54345-160
  `,
        `(81) 2123-1300
  `
    ]
  },
  {
    sim: [
        `
    MACEIÓ - ALAGOAS`,

        `Av. Doutor Durval De Goes Monteiro, 222 - Canaã
  `,
        `57080-000
  `,
        `(82) 2123-8900
  `
    ]
  },
  {
    sim: [
        `
    JOÃO PESSOA - PARAÍBA
    `,
        `R. Professor Candido De Sa Andrade, 2393 - Oitizeiro
  `,
        '58088-150',

        `(83) 3044-1100
  `
    ]
  },
  {
    sim: [
        `
    PARNAMIRIM - RIO GRANDE DO NORTE`,

        'Rod Br 101, S/N, Km 09 - Emaus',

        `59149-090
  `,
        `(84) 3647-2600
  `
    ]
  },
  {
    sim: [
        `
    FORTALEZA - CEARÁ`,

        'Av. Visconde Do Rio Branco, 6000 - Aerolândia',

        '60830-000',

        `(85) 4011-3400
  `
    ]
  },
  {
    sim: [
        `
    TERESINA - PIAUÍ`,
        `
  Av. Prefeito Wall Ferraz, 16.245 - Esplanada`,

        '64039-485',

        `(86) 3194-6100
  `
    ]
  },
  {
    sim: [
        `
    SALVADOR - BAHIA`,

        'Av. Barros Reis, Sn, Km 0, Prédio A - Arraial Do Retiro',

        '41204-025',

        `(71) 2107-7500
  `
    ]
  },
  {
    sim: [
        `
    JAGUARARI - BAHIA`,
        'Faz Caraiba, Sn, Sala M Almoxarifado Central - Sede',
        '48967-000',
        `(74) 3532-8437
  `
    ]
  },
  {
    sim: [
        `
  HOLDING - RIO DE JANEIRO`,
        'Av. Ayrton Senna, 2200, Bl 1- 1and - Barra Da Tijuca',
        '22775-003',
        `(21) 2107-2100
  `
    ]
  }
]

export default top
