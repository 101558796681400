<template>
 <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-mobnav">
  <a class="navbar-brand mr-auto mr-lg-0" href="#"><img src="https://sotreq.com.br/img/logosotreq.2d97c53d.svg" width="120" height="30"/></a>
     <a data-toggle="modal"
                        data-target="#exampleModal"><i class="fa fa-search fa-2x mob-search"></i></a>
  <button class="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas">
   <i class="fa fa-bars fa-lg text-black"></i>
  </button>

  <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
    


            <DesktopHeaderBusca />

            <!-- Inicio primeiro nivel -->
            <div class="accordion" id="collapsemOb">
              <!-- inicio solucoes para voce -->

              <div class="mob-card">
                <div class="mob-card-header" id="headingOne">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left collapsed cool-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <span class="colFirstLevel">Soluções para você</span>
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#collapsemOb"
                >
                  <div class="mob-card-body mob-menu-card-body">
                    <div class="mob-card rounded-0 border-0 card-menu-servicos">
                      <div class="accordion" id="collapsemObs1">
                        <!-- inicio mercados -->
                        <div class="mob-card">
                          <div class="mob-card-header" id="headingOnes1">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed cool-link"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes1"
                                aria-expanded="true"
                                aria-controls="collapseOnes1"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Mercados</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>

                          <MobileHeaderMercados />
                        </div>
                        <!-- fim mercados -->

                        <!-- inicio produtos -->
                        <div class="mob-card">
                          <div class="mob-card-header" id="headingOnes2">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed cool-link"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes2"
                                aria-expanded="true"
                                aria-controls="collapseOnes2"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Produtos</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <MobileHeaderProdutos />
                        </div>
                        <!-- fim produtos -->

                        <!-- inicio pecas -->
                        <div class="mob-card">
                          <div class="mob-card-header" id="headingOnes3">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed cool-link"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes3"
                                aria-expanded="true"
                                aria-controls="collapseOnes3"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Peças</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <MobileHeaderPecas />
                        </div>
                        <!-- fim pecas -->

                        <!-- inicio servicos -->
                        <div class="mob-card">
                          <div class="mob-card-header" id="headingOnes4">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed cool-link"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes4"
                                aria-expanded="true"
                                aria-controls="collapseOnes4"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Serviços</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <MobileHeaderServicos />
                        </div>
                        <!-- fim servicos -->

                        <!-- inicio tecnologia -->
                        <div class="mob-card">
                          <div class="mob-card-header" id="headingOnes5">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed cool-link"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes5"
                                aria-expanded="true"
                                aria-controls="collapseOnes5"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Tecnologia</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <MobileHeaderTecnologia />
                        </div>
                        <!-- fim tecnologia -->

                        <!-- inicio financiamento  -->
                        <div class="mob-card">
                          <div class="mob-card-header" id="headingOnes6">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed cool-link"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseOnes6"
                                aria-expanded="true"
                                aria-controls="collapseOnes6"
                              >
                                <span
                                  class="animate__fadeInLeftBig animate__bounce animate__delay-5s"
                                  >Financiamento</span
                                >
                                <i class="fa fa-angle-right"></i>
                              </button>
                            </h2>
                          </div>
                          <MobileHeaderFinanciamento />
                        </div>

                        <!-- fim financiamento -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Fim promocoes para voce -->

            <!-- inicio promocoes -->
            <div class="mob-card">
              <div
                class="mob-card-header animate__fadeInLeftBig animate__bounce animate__delay-2s"
                id="headingTwo"
              >
                <h2 class="mb-0">
                  <a href="/#/promocoes">
                    <button
                      class="btn btn-link btn-block text-left collapsed cool-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span class="colFirstLevel"> Promoções</span>
                    </button>
                  </a>
                </h2>
              </div>
            </div>
            <!-- fim promocoes -->

            <!-- inicio sobre a sotreq -->
            <div class="mob-card">
              <div class="mob-card-header" id="headingThree">
                <h2 class="mb-0">
                  <a href="/#/sobre-nos">
                    <button
                      class="btn btn-link btn-block text-left collapsed cool-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span class="colFirstLevel"> Sobre a Sotreq</span>
                    </button>
                  </a>
                </h2>
              </div>
            </div>
            <!-- fim sobre a sotreq -->
           
            <!-- inicio fale conosco -->
            <div class="mob-card">
              <div
                class="mob-card-header animate__fadeInLeftBig animate__bounce animate__delay-2s"
                id="headingTwo"
              >
                <h2 class="mb-0">
                  <a href="/#/carreiras">
                    <button
                      class="btn btn-link btn-block text-left collapsed cool-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span class="colFirstLevel"> Trabalhe Conosco</span>
                    </button>
                  </a>
                </h2>
              </div>
            </div>
            <!-- fim contato -->
           
            <!-- inicio fale conosco -->
            <div class="mob-card">
              <div
                class="mob-card-header animate__fadeInLeftBig animate__bounce animate__delay-2s"
                id="headingTwo"
              >
                <h2 class="mb-0">
                  <a href="/#/faleconosco">
                    <button
                      class="btn btn-link btn-block text-left collapsed cool-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span class="colFirstLevel"> Fale Conosco</span>
                    </button>
                  </a>
                </h2>
              </div>
            </div>
            <!-- fim contato -->
   </div>
  </nav>
</template>
<script type="text/javascript">
import MobileHeaderMercados from "@/components/MobileHeaderMercados.vue";
import MobileHeaderProdutos from "@/components/MobileHeaderProdutos.vue";
import MobileHeaderPecas from "@/components/MobileHeaderPecas.vue";
import MobileHeaderServicos from "@/components/MobileHeaderServicos.vue";
import MobileHeaderTecnologia from "@/components/MobileHeaderTecnologia.vue";
import MobileHeaderFinanciamento from "@/components/MobileHeaderFinanciamento.vue";
import DesktopHeaderBusca from "@/components/DesktopHeaderBusca.vue";

export default {
  name: "MobileHeader",
  mounted() {
    this.openMobNav();
  },
  components: {
    MobileHeaderMercados,
    MobileHeaderProdutos,
    MobileHeaderPecas,
    MobileHeaderServicos,
    MobileHeaderTecnologia,
    MobileHeaderFinanciamento,
    DesktopHeaderBusca,
  },
  methods: {
    openMobNav() {
        $(function () {
  'use strict'

  $('[data-toggle="offcanvas"]').on('click', function () {
    $('.offcanvas-collapse').toggleClass('open')
  })
})
    }
  },
};
</script>

  <style scoped>
input #searchModule {
  color: #000000 !important;
}

.link-black {
  color: #000000;
}

button,
input {
  margin: 0;
}

button,
input {
  background-color: rgba(0, 0, 0, 0);
  color: #000000;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

[type="submit"],
button {
  -webkit-appearance: button;
}

[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[tabindex],
a,
button,
input,
label {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.right {
  float: right;
}

.visibility-hidden {
  opacity: 0;
  position: absolute;
  left: -9999999px;
  top: -9999999px;
  visibility: hidden;
  width: 0;
  display: block;
  height: 0;
}

.searchBar {
  display: none;
}

.searchBar--overlay {
  background: rgba(51, 51, 51, 0.3);
  bottom: 0;
  position: absolute;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.searchBar.open {
  display: block;
}

.searchBar--wrapper {
  background: #f1f2f2;
  position: absolute;
  padding-top: 21px;
  width: 100%;
  top: 49px;
  z-index: 2001;
  left: auto;
}

.searchBar .arrow {
  border-bottom: 15px solid #f1f2f2;
  border-left: 15px solid rgba(0, 0, 0, 0);
  border-right: 15px solid rgba(0, 0, 0, 0);
  content: "";
  height: 0;
  margin-left: -6px;
  position: absolute;
  top: -9px;
  right: 15px;
  width: 0;
}

.searchBar .arrow:before {
  border-bottom: 15px solid #f1f2f2;
  border-left: 15px solid rgba(0, 0, 0, 0);
  border-right: 15px solid rgba(0, 0, 0, 0);
  content: "";
  height: 0;
  top: 0;
  left: -9px;
  margin-left: -6px;
  position: absolute;
  width: 0;
}

.searchModule .container {
  border-bottom: 0;
}

@media (min-width: 48rem) and (max-width: 63.9375rem) {
  .searchModule--wrapper {
    padding: 0 15px;
  }
}

@media (min-width: 64rem) {
  .searchModule--wrapper {
    padding: 0 22px;
  }
}

.searchModule--input {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-bottom: 1px solid #939598;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-bottom: 40px;
  padding-bottom: 7px;
  padding-top: 30px;
}

.searchModule--input,
.searchModule--input-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.searchModule--input-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 17px;
}

.searchModule--input-icon .icon-search:before {
  font-size: 1.375rem;
}

.searchModule--input-txt {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.searchModule--input-cta {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-left: 20px;
  padding-top: 20px;
}

.searchModule--input-cta .cta {
  font-size: 1rem;
  display: table;
  white-space: nowrap;
  border: 0;
  padding: 0;
}

.searchModule--input-cta .cta.disabled {
  color: #ffc22b;
  cursor: default;
}

.searchModule--input-cta .cta.disabled .icon:before {
  color: #ffc22b;
}

.searchModule--search {
  font-size: calc(0.64706rem + 3.67647vw);
  line-height: calc(0.70588rem + 5.14706vw);
  letter-spacing: 0.0625rem;
  width: 100%;
  border: 0;
  padding: 0;
}

.searchModule--popular {
  padding-bottom: 40px;
}

.searchModule--popular-items {
  display: block;
}

.searchModule--popular-item {
  margin-right: 0;
  margin-bottom: 0;
}

.searchModule--popular-item:not(:last-child) {
  margin-bottom: 20px;
}

.searchModule--popular h4 {
  margin-bottom: 20px;
}

.searchModule--autocomplete {
  width: 100%;
  background: #f1f2f2;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 100;
  padding-top: 30px;
  padding-bottom: 40px;
}

.searchModule input:-ms-input-placeholder {
  color: #b1b3b6;
}

.searchModule input:placeholder {
  color: #b1b3b6;
  opacity: 1;
}

.searchModule input {
  outline: none;
}

.searchModule input:focus {
  outline: none;
}

.searchModule input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.searchModule input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

@media (min-width: 0) and (max-width: 47.9375rem) {
  .container {
    padding: 0 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (min-width: 0) and (max-width: 47.9375rem) {
  .searchBar--container {
    padding: 0;
  }
  .searchBar--close .icon-close-light {
    font-size: 0.9375rem;
  }
}

@media (min-width: 48rem) {
  .searchBar .arrow {
    right: 25px;
    top: -15px;
  }
  .searchBar--close {
    right: 30px;
  }
  .searchModule .container {
    border-bottom: 1px solid #d1d3d4;
  }
}

@media (min-width: 48rem) and (min-width: 48rem) and (max-width: 63.9375rem) {
  .searchModule--wrapper {
    padding: 0 15px;
  }
}

@media (min-width: 48rem) and (min-width: 64rem) {
  .searchModule--wrapper {
    padding: 0 22px;
  }
}

@media (min-width: 48rem) {
  .searchModule--input {
    padding-bottom: 10px;
    margin-bottom: 65px;
    padding-top: 70px;
  }
  .searchModule--input-icon {
    padding-right: 23px;
    padding-top: 7px;
  }
  .searchModule--input-cta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 17px;
  }
}

@media (min-width: 48rem) {
  .searchModule--popular {
    padding-bottom: 70px;
  }
}

@media (min-width: 48rem) and (max-width: 63.9375rem) {
  .searchBar--wrapper {
    top: 69px;
  }
  .searchBar--close {
    top: 29px;
  }
}

@media (min-width: 64rem) {
  .searchBar--wrapper {
    top: 89px;
  }
  .searchBar .arrow {
    right: 35px;
  }
  .searchBar--close {
    top: 37px;
    right: 40px;
  }
  .searchModule--input-icon {
    padding-right: 27px;
    padding-top: 8px;
  }
  .searchModule--popular-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .searchModule--popular-item:not(:last-child) {
    margin-right: 40px;
  }
}

@media screen and (min-width: 64rem) {
  .searchModule--search {
    line-height: 4rem;
    font-size: 3rem;
  }
  .searchModule--popular-item a {
    line-height: 2rem;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 30rem) {
  .searchModule--search {
    line-height: 2.25rem;
    font-size: 1.75rem;
  }
  .searchModule--popular-item a {
    line-height: 1.75rem;
    font-size: 1rem;
  }
}

@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .searchModule--search {
    line-height: 0;
  }
}

body {
  background-image: url(https://picsum.photos/id/967/1280/1080);
  background-size: cover;
  background-repeat: no-repeat;
}

.cool-link {
  display: inline-block;
  color: #ffc22b;
  text-decoration: none;
}

.cool-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #ffc22b;
  transition: width 0.3s;
}

li.show > .cool-link::after {
  width: 100%;
}

.cool-link:hover::after {
  width: 100%;
  /*transition: width .3s;*/
}

.navbar-sotreq {
  height: 80px;
  z-index: 99;
}

.header-logo {
  width: 166px;
}

.dropdown-toggle.active-dropdown::after {
  transform: rotate(-90deg);
}

.dropdown-menu {
  margin: 0px;
}

.dropdown-menu.show {
  display: flex;
  /*top: unset !important;*/
  left: 0 !important;
  width: 100%;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-radius: 0px !important;
}

a.dropdown-item.dropdown-toggle {
  /*padding: .5rem 1rem;*/
}

.img-menu-box {
  padding-right: 2em;
  border-right: 1px solid #d6d6d6;
}

a.dropdown-item.dropdown-toggle[aria-expanded="true"] {
  /*padding: .5rem 1rem;*/
  color: #ffc22b !important;
  /*border-bottom: 2px solid #ffc22b;*/
}

#navbarNavDropdown > ul > li.nav-item.dropdown.show > ul > div > li.show > a {
  /*padding: .5rem 1rem;*/
  color: #ffc22b !important;
  /*border-bottom: 2px solid #ffc22b;*/
}

.dropdown-item {
  color: #ffffff;
  padding: 0.5rem 1rem;
}

.dropdown-item:hover {
  color: #ffc22b !important;
  /*border-bottom: 2px solid #ffc22b;*/
}

.dropdown-item-in {
  color: #000000;
  padding: 0.5rem 1rem;
}

.dropdown-item-in:hover {
  color: #ffc22b !important;
  /*border-bottom: 2px solid #ffc22b;*/
}

.dropdown,
di .dropleft,
.dropright,
.dropup {
  position: revert !important;
}

.navbar-expand-md .navbar-collapse {
  justify-content: center;
}

.navbar.navbar-expand-md.navbar-dark.navbar-sotreq {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5202970095593544) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.navbar.navbar-expand-md.navbar-dark.navbar-sotreq.over {
  background: #ffffff !important;
  color: #000 !important;
}

.navbar-sotreq.over > .container > #navbarNavDropdown > ul > li > a {
  color: #000000;
}

.navbar-sotreq.over
  > .container
  > #navbarNavDropdown
  > ul
  > li.nav-item.dropdown.show
  > ul
  > div
  > li.show
  > ul
  > div
  > div
  > div
  > div
  > div
  > ul
  > li
  > a {
  color: #000000;
}

.navbar-sotreq.over
  > .container
  > #navbarNavDropdown
  > ul
  > li.nav-item.dropdown.show
  > ul
  > div
  > li.show
  > ul
  > div
  > div
  > div
  > div
  > div.pl-4.mx-0
  > ul
  > li
  > a {
  color: #000000;
}

.navbar-sotreq.over
  > .container
  > #navbarNavDropdown
  > ul
  > li.nav-item.dropdown.show
  > ul
  > div
  > li
  > a {
  color: #000000;
}

.navbar-sotreq.front-link {
  color: #ffffff;
}

.internal-link {
  color: #000000;
}

.navbar.navbar-expand-md.navbar-dark.navbar-sotreq:hover {
  background: #ffffff !important;
  color: #000000;
}

.navbar-sotreq:hover > .container > #navbarNavDropdown > ul > li > a {
  color: #000000;
}

.navbar-sotreq:hover
  > .container
  > #navbarNavDropdown
  > ul
  > li
  > a.nav-link:hover {
  color: #ffc22b !important;
  /*border-bottom: 2px solid #ffc22b;*/
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffc22b !important;
  background-color: unset;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #ffc22b;
  background-color: unset;
}

#navbarNavDropdown > ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

li.nav-item.dropdown.show > ul.dropdown-menu {
  border: 1px solid #d6d6d6;
  border-radius: 0px;
  padding-bottom: 1em;
  padding-top: 1em;
  /*margin: 1rem 0 0;*/
}

li.show > ul.dropdown-menu.show {
  border-top: 1px solid #d6d6d6;
  border-bottom: none;
  /*margin: 1rem 0 0;*/
}

a.internal-link > h6 {
  margin-bottom: 1.5em;
}

h6.internal-link {
  margin-bottom: 1.5em;
}

.upcaret {
  width: 3em;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

a.internal-link.text-muted > h6 {
  padding-bottom: 1rem;
  margin-bottom: 0rem;
}

.dropdown-toggle::after {
  display: block;
  margin-left: unset;
  vertical-align: unset;
  content: "";
  border-top: unset;
  border-right: unset;
  border-bottom: unset;
  border-left: unset;
}

.lupa-busca {
  color: #ffc22b !important;
}

.termos-buscados {
  color: #ffc22b !important;
  text-transform: uppercase;
}
.w-sidebar {
  width: 200px;
  max-width: 200px;
}

.vh-100 {
  min-height: 100vh;
}

.row.collapse {
  margin-left: -200px;
  left: 0;
  transition: margin-left 0.15s linear;
}

.row.collapse.show {
  margin-left: 0 !important;
}

.row.collapsing {
  margin-left: -200px;
  left: -0.05%;
  transition: all 0.15s linear;
}

.menu-spacer {
  position: absolute;
}

@media (max-width: 1000px) {
  .copy-text {
    font-size: 14px;
  }
  .blue-button {
    width: 100%;
    justify-content: center;
  }
  .download-button {
    max-width: unset;
  }
  .hero-logo {
    width: 65%;
  }
  .map-box {
    min-height: 10em;
    min-width: 22em;
  }
  .row.collapse,
  .row.collapsing {
    margin-left: 0 !important;
    left: 0 !important;
    overflow: visible;
  }

  .row > .sidebar.collapse {
    display: flex !important;
    margin-left: -100% !important;
    transition: all 0.3s linear;
    position: fixed;
    z-index: 1050;
    max-width: 0;
    min-width: 0;
    flex-basis: auto;
  }

  .row > .sidebar.collapse.show {
    margin-left: 0 !important;
    width: 100%;
    max-width: 100%;
    min-width: initial;
  }

  .row > .sidebar.collapsing {
    display: flex !important;
    margin-left: -10% !important;
    transition: all 0.3s linear !important;
    position: fixed;
    z-index: 1050;
    min-width: initial;
  }
  .hamburger {
    position: fixed;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    background-color: #000;
  }
  .mob-brand {
    height: 56px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .main-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .fa-bars {
    align-self: center;
    font-size: 2.333em !important;
    color: #fff !important;
  }
  .header-logo {
    width: 150px;
  }
  .mob-search {
    color: #fff;
    margin-right: 1em;
  }
  .w-sidebar {
    margin-top: 2em;
  }
  .lblue-bg {
    background-color: #ececec;
  }

  .mob-card {
    background-color: #ececec;
    color: #fff;
  }
  .mob-card-header {
    background-color: #ececec;
  }
  .btn-outline-warning {
    color: #ffc107 !important;
  }
  .btn-outline-warning:hover {
    color: #fffblz per !important;
  }
  .mob-internal-link {
    color: #000;
  }
  .btn.btn-link.btn-block.text-left.collapsed {
    color: #000000;
    font-size: 24px;
  }
  .btn.not(:disabled):not(.disabled) {
    color: #4a4a4a;
    background-color: #000c;
    font-size: 24px;
  }
  .btn.btn-link.btn-block.text-left.collapsed:focus {
    box-shadow: none;
    text-decoration: none;
  }
  .btn.btn-link.btn-block.text-left.collapsed:hover {
    box-shadow: none;
    text-decoration: none;
  }
  btn.focus,
  .btn:focus {
    outline: 0;
    box-shadow: none;
    text-decoration: none;
  }
  .btn.btn-link.btn-block.text-left {
    color: #4a4a4a;
    font-size: 24px;
  }

  .btn.btn-link.btn-block.text-left:hover {
    color: #4a4a4a;
    text-decoration: none;
    font-size: 24px;
  }
  .mob-menu-card-body {
    text-align: left;
  }
  .mob-menu-list {
    list-style: none;
    padding-inline-start: 15px !important;
  }
  .row.vh-100.no-gutters.d-flex.h-100.position-absolute.collapse.show {
    top: 0;
  }
}

@media (max-width: 538px) {
  .internal-link h6 {
    color: #ffffff;
  }
  li.nav-item {
    margin-bottom: 1em;
  }
}
.mainNav-collapsible .mobileNavOpen .mainNav--itemTitle {
  opacity: 1;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: opacity 0.5s,
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.5s, -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.5s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
  display: block;
  position: relative;
}
.mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  position: relative;
  display: inline-block;
  font-size: 0.45rem;
  margin-left: 5px;
}
.mainNav-collapsible .mainNav--parent .icon-dropdown-arrow:before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  -o-transition: transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  position: relative;
  display: inline-block;
  font-size: 0.45rem;
  margin-left: 5px;
}
i.fa.fa-angle-right {
  margin-left: 1em;
}
#collapsemObs1 {
  padding-left: 0.7em;
}

.btn.btn-link.btn-block.text-left.cool-link:not(.collapsed)
  > i.fa.fa-angle-right {
  transform: rotate(90deg);
}
.lblue-bg {
  overflow: scroll;
}

.prehorline {
    border-left: 5px solid #FFCD11;
    height: 22px;
    max-height: 22px;
    display: flex;
    margin-right: 10px;
}
.findinput {
    border: 1px solid #000;
    width: 100%;
    border-radius: 0px;
    padding: 12px 12px 12px 48px;
    box-sizing: border-box;
    position: relative;
    font-size: 16px;
    line-height: 1.5;
    flex: 1;
    background-color: unset;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Im0yMSAyMS00LTQiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: 12px;
}
.p1rem {
    padding: 0rem;
}
#paihits {
    display: none;
}
#paihitsmob {
    display: none;
}
.hover {
    background: #fff !important;
    color: #000;
}
.navbar-sotreq.hover>.container>#navbarNavDropdown>ul>li>a {
    color: #000;
}
@media(max-width: 755px) {
  .border-md-0 {
     border: 1px solid rgba(0, 0, 0, .125) !important;
                                }
  .modal-body {
    height: 100vh
  }
  .bg-mobnav {
    background-color: #000;
  }

                            }
@media(min-width: 758px) {
    .border-md-0 {
            border: none !important;
        }
}

    .hbartitle {
    border-left: 5px solid #FFCD11;
    padding-left: 8px;
}

.w-100.card.card-body {
  height: 100vh;
}


@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #ececec;
    transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.bg-purple { background-color: #6f42c1; }

.lh-100 { line-height: 1; }
.lh-125 { line-height: 1.25; }
.lh-150 { line-height: 1.5; }

</style>
