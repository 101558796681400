<template>
   <div>
                  <p class="mt-2">Inscreva-se para receber novidades</p>
                  <input
                    type="text"
                    class="textinput form-control"
                    placeholder="E-mail"
                    aria-label="E-mail"
                    aria-describedby="addon-wrapping"
                  />
                  <a href="https://sotreqlink.sotreq.com.br/Login">
                    <button
                      type="button"
                      class="btn btn-outline-warning sotreqlink-btn mt-3"
                      id="activator"
                    >
                      Enviar
                    </button>
                  </a>
                </div>
</template>
<script type="text/javascript">
export default {
  name: 'FooterElo'
}
</script>

  <style scoped>
</style>
